import React, { useEffect, useState, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"
import { isEmpty, map } from "lodash"
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//redux
import { useSelector, useDispatch } from "react-redux"
import {
  deleteRequest,
  getMDMCountries,
  getMDMProfession,
  getRequest,
  getRequestStats,
  getUsers,
  resetCountryMessages,
} from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"
import MiniCardss from "pages/Dashboard/mini-card"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import DemandeServiceVip from "./DemandeServiceVip"
import moment from "moment"
import ServiceVipView from "./ServiceVipView"

const ServiceVip = props => {
  const {
    resqestData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading
  } = useSelector(state => state.requests)
  const {
    requestStatsData,
  } = useSelector(state => state.requests)
  const { users } = useSelector(state => state.users);
  const { professionData } = useSelector((state) => state.professions);
  //meta title
  document.title = "Adresstreet"
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    adminId: "",
    status: "",
    professionId: "",
    page: 1,
    limit: 100,
  })
  const [modal, setModal] = useState(false)
  const [modalView, setModalView] = useState(false)
  const [serviceList, setServiceList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [service, setService] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")
  const history = useHistory()
  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Totale des demandes"),
      iconClass: "bx-check-circle",
      text: 0,
    },
    {
      title: props.t("Demandes aujourd'hui"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
    {
      title: props.t("Total Mois Courant"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
    {
      title: props.t("Total Année Courante"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
  ])
  useEffect(() => {
      setMiniCards([
      {
        title: props.t("Totale des demandes"),
        iconClass: "bx-check-circle",
        text: requestStatsData.total,
      },
      {
        title: props.t("Demandes aujourd'hui"),
        iconClass: "dripicons-user-group",
        text:requestStatsData.today,
      },
      {
        title: props.t("Total Mois Courant"),
        iconClass: "dripicons-user-group",
        text: requestStatsData.month,
      },
      {
        title: props.t("Total Année Courante"),
        iconClass: "dripicons-user-group",
        text: requestStatsData.year,
      },
    ])
  
  }, [requestStatsData])
 
  const handleserviceClick = arg => {
    const service = arg

    setService({
      ...service,
    })

    setIsEdit(true)
   
    toggle()
  }
  const viewservice = arg => {
    const serviceView = arg
    
    setService({
      ...serviceView,
    })

    setIsView(true)
    toggleView()
  }

  // Column
  const columns = useMemo(
    () => [
     
      {
        Header: props.t("Date"),
        accessor: "date",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm")}
            </>
          );
        },
      },
      {
        Header: props.t("Chercheur"),
        accessor: "seeker",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              {((cellProps?.seeker?.firstname != undefined && cellProps?.seeker?.lastname != undefined) ? (cellProps?.seeker?.firstname + " " + cellProps?.seeker?.lastname) : props.t("Non fourni"))}
            </>
          );
        },
      },
      {
        Header: props.t("Profession"),
        accessor: "Profession",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              {/* {console.log("cellprops",cellProps)} */}
              {cellProps.profession?.name || props.t("Non fourni")}
            </>
          );
        },
      },
      {
        Header: props.t("Adresse"),
        accessor: "adresse",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
             
              {(cellProps.governorate?.name + ", " + cellProps.delegation?.name + ", " + cellProps.locality?.name) || props.t("Non fourni")}
            </>
          );
        },
      },
      {
        Header: props.t("Statut"),
        accessor: "status",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <ArabicName {...cellProps} />
        // },
      },
      {
        Header: props.t("Réalisée par"),
        accessor: "admin",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
             
              {(cellProps.admin &&
                cellProps.admin?.firstname +
                " " +
                cellProps.admin?.lastname) ||
                props.t("------")}
            </>
          );
        },
      },


      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
             
             {(cellProps?.row?.original?.status == "Réalisée"  || cellProps?.row?.original?.status == "réalisée") && <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const ViewData = cellProps.row.original
                  viewservice(ViewData)
                }}
              >
                <i className="bx bx-bullseye mt-1 font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  voir
                </UncontrolledTooltip>
              </Link>
              }
          
             {(cellProps?.row?.original?.status == "Réalisée" || cellProps?.row?.original?.status == "réalisée" || cellProps?.row?.original?.status == "ouverte" || cellProps?.row?.original?.status == "encours" || cellProps?.row?.original?.status == "Ouverte") && <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const editData = cellProps.row.original
                  handleserviceClick(editData)
                }}
              >
               <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  edit
                </UncontrolledTooltip>
              </Link>
              }
            

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const DeleteData = cellProps.row.original
                  onClickDelete(DeleteData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setService(null)
    } else {
      setModal(true)
    }
  }
  const toggleView = () => {
    if (modalView) {
      setModalView(false)
      setService(null)
    } else {
      setModalView(true)
    }
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = service => {
    setService(service)
    setDeleteModal(true)
  }

  const handleDeleteService = () => {
   
    if (service.id) {
      dispatch(deleteRequest({ id: service.id }));
      setDeleteModal(false)
      dispatch(getMDMCountries())
    }
  }

  useEffect(() => {

    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("created successfully !")
      dispatch(resetCountryMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Successfully !")
      dispatch(resetCountryMessages())
    }
  }, [success])

  useEffect(() => {
    {
      dispatch(getRequest(filter))
      dispatch(getRequestStats())
      dispatch(getUsers(filter))
      dispatch(getMDMProfession(filter))

    }
  }, [dispatch, filter])



  useEffect(() => {
    setServiceList(resqestData)
  }, [resqestData])

  useEffect(() => {
    if (!isEmpty(resqestData)) {
      setServiceList(resqestData)
    }
  }, [resqestData])

  const handleServiceClicks = () => {
    setServiceList("")
    setIsEdit(false)
    toggle()
  }
  const handleDateFromTo = date => {
    if (!date) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: "",
          to: "",
        }
      })
    }
    else if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }
  const setPageNumber = (activePage) => { setFilter(prevFilter => { return { ...prevFilter, page: activePage } }) }
  const handleLimit = (limit) => { setFilter(prevFilter => { return { ...prevFilter, limit: limit } }) }
  const handleProfession = (profession) => { setFilter(prevFilter => { return { ...prevFilter, professionId: profession.value } }) }
  const handleStatus = (statut) => { setFilter(prevFilter => { return { ...prevFilter, status: statut.value } }) }
  const handleUser = (user) => { setFilter(prevFilter => { return { ...prevFilter, adminId: user.value } }) }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteService}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("CRM")} breadcrumbItem={props.t("Liste des demandes service vip")} />

          <Row>
            <Col xl="12">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCardss
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={resqestData}
                    isGlobalFilter={true}
                    isExcelService={true}
                    setProfession={handleProfession}
                    setStatusRequest={handleStatus}
                    profession={professionData}
                    setUser={handleUser}
                    ProfessionFilter={true}
                    isUsersFilter={true}
                    filterDate={true}
                    setDateFilter={handleDateFromTo}
                    resetOptionRequest={true}
                    StatusRequestFilter={true}
                    users={users}
                    handleOrderClicks={handleServiceClicks}
                    handelAddClick={handleServiceClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                  <DemandeServiceVip modal={modal} toggle={toggle} isEdit={isEdit}  Service={service} />
                  <ServiceVipView modal={modalView} toggle={toggleView} isView={isView} Service={service} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ServiceVip)
