import React, { useEffect,useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
 
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  Spinner,
} from "reactstrap"


//redux
import {  useDispatch, useSelector } from "react-redux"

import { upload } from "helpers/api_helper"
import {
  createMDMGovernorate,
  getMDMCountries,
  updateMDMGovernorate,
  
} from "store/actions"

import { withTranslation } from "react-i18next"


const GovernorateModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, governorate, isEdit } = props
  const [loading, setLoading] = useState(false)
  const { countriesData } = useSelector(state => state.countries)

  useEffect(() => {
    {
      dispatch(getMDMCountries())
    }
  }, [dispatch])

    // console.log(loading)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (governorate && governorate.name) || "",
      type: (governorate && governorate.type) || "governorate",
      status: (governorate && governorate.status) || "active",
      parentId: (governorate && governorate.parentId) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Your Name")),
      parentId: Yup.string().required(props.t("Please Enter Your country")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateGovernorate = {
          id: governorate.id,
          name: values.name,
          parentId: parseInt(values.parentId),
        }
        // update
        //console.log("updategovernorate", updateGovernorate)
        dispatch(updateMDMGovernorate(updateGovernorate))
        validation.resetForm()
      } else {
        const newGovernorate = {
          name: values["name"],
          type: values["type"],
          status: values["status"],
          parentId: parseInt(values["parentId"]),
        }
        //console.log("New Governorates", newGovernorate)
        //save new governorate
        dispatch(createMDMGovernorate(newGovernorate))
        validation.resetForm()
      }
      toggle()
    },
  })


  


  return (

<Modal isOpen={modal} toggle={toggle}>
<ModalHeader toggle={toggle} tag="h4">
  {!!isEdit ? props.t("Edit Governorate") : props.t("Add Governorate")}
</ModalHeader>
<ModalBody>
  <Form
    onSubmit={e => {
      e.preventDefault()
      validation.handleSubmit()
      return false
    }}
  >
    <Row form>
      <Col className="col-12">


        <div className="mb-3">
          <Label className="form-label">{props.t("Name")}</Label>
          <Input
            name="name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name &&
              validation.errors.name
                ? true
                : false
            }
          />
          {validation.touched.name &&
          validation.errors.name ? (
            <FormFeedback type="invalid">
              {validation.errors.name}
            </FormFeedback>
          ) : null}
        </div>
      
        <div className="mb-3">
          <Label className="form-label">{props.t("Country")}</Label>
          <Input
            type="select"
            name="parentId"
            className="form-select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.parentId || []}
            invalid={
              validation.touched.parentId &&
              validation.errors.parentId
                ? true
                : false
            }
          >
            <option key="empty" disabled></option>
            {countriesData.map(item => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Input>
          {validation.touched.parentId &&
          validation.errors.parentId ? (
            <FormFeedback type="invalid">
              {validation.errors.parentId}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="text-end">
          <button
            type="submit"
            className="btn btn-success save-customer"
          >
            {props.t("Save")}
          </button>
        </div>
      </Col>
    </Row>
  </Form>
</ModalBody>
</Modal>)
}
export default withTranslation()(GovernorateModal)
