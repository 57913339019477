import {
  PROFESSIONNAL_MESSAGES_RESET,
  CREATE_PROFESSIONNAL_FAILED,
  CREATE_PROFESSIONNAL_REQUEST,
  CREATE_PROFESSIONNAL_SUCCESS,
  DELETE_PROFESSIONNAL_FAILED,
  DELETE_PROFESSIONNAL_REQUEST,
  DELETE_PROFESSIONNAL_SUCCESS,
  GET_PROFESSIONNAL_BY_NAME_FAILED,
  GET_PROFESSIONNAL_BY_NAME_REQUEST,
  GET_PROFESSIONNAL_BY_NAME_SUCCESS,
  GET_PROFESSIONNAL_FAILED,
  GET_PROFESSIONNAL_REQUEST,
  GET_PROFESSIONNAL_SUCCESS,
  GET_PROFESSIONNAL_STATS_FAILED,
  GET_PROFESSIONNAL_STATS_REQUEST,
  GET_PROFESSIONNAL_STATS_SUCCESS,
  UPDATE_PROFESSIONNAL_FAILED,
  UPDATE_PROFESSIONNAL_REQUEST,
  RESET_PROFESSIONNAL_MESSAGES,
  RESET_PROFESSIONNAL_MESSAGES_SUCCESS,
  UPDATE_PROFESSIONNAL_SUCCESS,
} from "./actionTypes.js";

export const getProfessionnalByName = (name) => ({
  type: GET_PROFESSIONNAL_BY_NAME_REQUEST,
  payload: name,
});

export const getProfessionnalByNameSuccess = (payload) => ({
  type: GET_PROFESSIONNAL_BY_NAME_SUCCESS,
  payload,
});

export const getProfessionnalByNameFailed = (payload) => ({
  type: GET_PROFESSIONNAL_BY_NAME_FAILED,
  payload,
});

export const getProfessionnal = (filter) => ({
  type: GET_PROFESSIONNAL_REQUEST,
  payload: filter,
});

export const getProfessionnalSuccess = (professionnal) => ({
  type: GET_PROFESSIONNAL_SUCCESS,
  payload: professionnal,
});

export const getProfessionnalFailed = (payload) => ({
  type: GET_PROFESSIONNAL_FAILED,
  payload,
});
export const getProfessionnalStats = (filter) => ({
  type: GET_PROFESSIONNAL_STATS_REQUEST,
  payload: filter,
});

export const getProfessionnalStatsSuccess = (professionnal) => ({
  type: GET_PROFESSIONNAL_STATS_SUCCESS,
  payload: professionnal,
});

export const getProfessionnalStatsFailed = (payload) => ({
  type: GET_PROFESSIONNAL_STATS_FAILED,
  payload,
});

export const createProfessionnal = (professionnal) => ({
  type: CREATE_PROFESSIONNAL_REQUEST,
  payload: professionnal,
});

export const createProfessionnalSuccess = (professionnal) => ({
  type: CREATE_PROFESSIONNAL_SUCCESS,
  payload: professionnal,
});

export const createProfessionnalFailed = (payload) => ({
  type: CREATE_PROFESSIONNAL_FAILED,
  payload,
});

export const deleteProfessionnal = (id) => ({
  type: DELETE_PROFESSIONNAL_REQUEST,
  payload: id,
});

export const deleteProfessionnalSuccess = (id) => ({
  type: DELETE_PROFESSIONNAL_SUCCESS,
  payload: id,
});

export const deleteProfessionnalFailed = (id) => ({
  type: DELETE_PROFESSIONNAL_FAILED,
  payload: id,
});

export const updateProfessionnal = (professionnal) => ({
  type: UPDATE_PROFESSIONNAL_REQUEST,
  payload: professionnal,
});

export const updateProfessionnalSuccess = (professionnal) => ({
  type: UPDATE_PROFESSIONNAL_SUCCESS,
  payload: professionnal,
});

export const updateProfessionnalFailed = (payload) => ({
  type: UPDATE_PROFESSIONNAL_FAILED,
  payload,
});

export const resetProfessionnalMessages = () => ({
  type: PROFESSIONNAL_MESSAGES_RESET,
});

export const resetProfessionnalMessagesSuccess = () => ({
  type: RESET_PROFESSIONNAL_MESSAGES_SUCCESS,
});
