/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"
/* DASHBOARD */
export const GET_DASHBOARD = "GET_DASHBOARD"
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS"
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_ADMIN_PROFILE = "GET_ADMIN_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

/** Reset Messages */
export const RESET_USERS_MESSAGES = "RESET_USERS_MESSAGES"
export const RESET_USERS_MESSAGES_SUCCESS = "RESET_USERS_MESSAGES_SUCCESS"
