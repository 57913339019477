import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createRequestFailed,
  createRequestSuccess,
  deleteRequestFailed,
  deleteRequestSuccess,
  getRequestFailed,
  getRequestStatsFailed,
  getRequestStatsSuccess,
  getRequestSuccess,
  updateRequestFailed,
  updateRequestSuccess,
} from "./actions"
import {
  REQUEST_MESSAGES_RESET,
  CREATE_REQUEST_REQUEST,
  DELETE_REQUEST_REQUEST,
  GET_REQUEST_BY_NAME_REQUEST,
  GET_REQUEST_REQUEST,
  RESET_REQUEST_MESSAGES,
  UPDATE_REQUEST_REQUEST,
  GET_REQUEST_STATS_REQUEST
} from "./actionTypes";



import {  delRequest, getRequest, getRequestStats, postRequest, putRequest } from "helpers/api_helper";



function* getRequestSaga({payload}) {
  try {
    
    const response = yield getRequest(payload || {page: 1, limit: 1000});

    yield put(getRequestSuccess(response))
  } catch (e) {
    yield put(getRequestFailed(e))
  }
}


function* getRequestStatsSaga() {
  try {
    
    const response = yield getRequestStats();
     //console.log("response",response)
    yield put(getRequestStatsSuccess(response))
  } catch (e) {
    yield put(getRequestStatsFailed(e))
  }
}

function* getRequestBynameSaga() {

}

function* getRequestFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
    // console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getRequestFailed(e))
  }
}

function* createRequestSaga({ payload: Request }) {
  try {
    const data = yield postRequest(Request);

    const response = yield getRequest({limit: 20, page: 1});

    yield put(getRequestSuccess(response))

  } catch (e) {
    yield put(createRequestFailed(e))
  }
}

function* deleteRequestSaga( payload ) {
  try {
    const result = yield delRequest(payload.payload.id)
    const data = yield getRequest({ current: 1, limit: 1000 })
    yield put(getRequestSuccess(data))
  } catch (e) {
    yield put(deleteRequestFailed(e))
  }
}

function* updateRequestSaga({ payload: Request }) {
  try {
    // separer l id & objet Request
    const { id, ...updatedRequest } = Request

    const data = yield putRequest(id, updatedRequest);
    const response = yield getRequest({limit: 20, page: 1});
    // console.log("response",response)
    yield put(getRequestSuccess(response))
  } catch (e) {
    yield put(updateRequestFailed(e))
  }
}

function* resetRequestMessagesSaga() {
  yield put(resetRequestMessagesSuccess())
}




export default function* requestSaga() {
  yield all([
    takeEvery(GET_REQUEST_REQUEST, getRequestSaga),
    takeEvery(GET_REQUEST_STATS_REQUEST, getRequestStatsSaga),
    takeEvery(GET_REQUEST_BY_NAME_REQUEST, getRequestBynameSaga),
    takeEvery(CREATE_REQUEST_REQUEST, createRequestSaga),
    takeEvery(DELETE_REQUEST_REQUEST, deleteRequestSaga),
    takeEvery(UPDATE_REQUEST_REQUEST, updateRequestSaga),
    takeEvery(RESET_REQUEST_MESSAGES, resetRequestMessagesSaga),
  ])
}
