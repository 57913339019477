import {
    REQUEST_MESSAGES_RESET,
    RESET_REQUEST_MESSAGES_SUCCESS,
    CREATE_REQUEST_FAILED,
    CREATE_REQUEST_REQUEST,
    CREATE_REQUEST_SUCCESS,
    DELETE_REQUEST_FAILED,
    DELETE_REQUEST_REQUEST,
    DELETE_REQUEST_SUCCESS,
    GET_REQUEST_BY_NAME_FAILED,
    GET_REQUEST_BY_NAME_REQUEST,
    GET_REQUEST_BY_NAME_SUCCESS,
    GET_REQUEST_FAILED,
    GET_REQUEST_REQUEST,
    GET_REQUEST_SUCCESS,
    UPDATE_REQUEST_FAILED,
    UPDATE_REQUEST_REQUEST,
    UPDATE_REQUEST_SUCCESS,
    GET_REQUEST_STATS_SUCCESS,
    GET_REQUEST_STATS_REQUEST,
    GET_REQUEST_STATS_FAILED,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    resqestData: [],
    requestStatsData: {},
    success: "",
    error: "",
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_REQUEST_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_REQUEST_SUCCESS:
        const {data, ...filters} = action.payload
       
        return {
          ...state,
          resqestData: data,
          ...filters,
          loading: false,
          success: "GET",
        }
      case GET_REQUEST_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
        case GET_REQUEST_STATS_REQUEST:
          return {
            ...state,
            success: "",
            error: "",
            loading: true,
          }
        case GET_REQUEST_STATS_SUCCESS:
        return {
          ...state,
          requestStatsData: action.payload,
          loading: false,
          success: "GET",
       
        }
        case GET_REQUEST_STATS_FAILED:
          return {
            ...state,
            error: action.payload.message,
            loading: false,
          }
      case GET_REQUEST_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_REQUEST_BY_NAME_SUCCESS:
        return {
          ...state,
          resqestData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_REQUEST_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_REQUEST_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_REQUEST_SUCCESS:
        return {
          ...state,
          resqestData: [...state.resqestData, action.payload],
          success: "CREATE",
          loading: false,
        }
      case CREATE_REQUEST_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_REQUEST_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_REQUEST_SUCCESS:
        return {
          ...state,
          resqestData: state.resqestData.filter(
            request => request.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_REQUEST_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_REQUEST_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case UPDATE_REQUEST_SUCCESS:
        return {
          ...state,
          resqestData: state.resqestData.map(request =>
            request.id.toString() === action.payload.id.toString()
              ? { request, ...action.payload }
              : request
          ),
          success: "UPDATE",
          loading: false,
        }
      case UPDATE_REQUEST_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case REQUEST_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_REQUEST_MESSAGES_SUCCESS:
          return {
            ...state,
            error: null,
            success: null
          }
      default:
        return state
    }
  }
  
  export default reducer
  
