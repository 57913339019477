
import {
    DEPARTMENT_MESSAGES_RESET,
    CREATE_DEPARTMENT_FAILED,
    CREATE_DEPARTMENT_REQUEST,
    CREATE_DEPARTMENT_SUCCESS,
    DELETE_DEPARTMENT_FAILED,
    DELETE_DEPARTMENT_REQUEST,
    DELETE_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_BY_NAME_FAILED,
    GET_DEPARTMENT_BY_NAME_REQUEST,
    GET_DEPARTMENT_BY_NAME_SUCCESS,
    GET_DEPARTMENT_FAILED,
    GET_DEPARTMENT_REQUEST,
    GET_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAILED,
    UPDATE_DEPARTMENT_REQUEST,
    RESET_DEPARTMENT_MESSAGES,
    RESET_DEPARTMENT_MESSAGES_SUCCESS,
    UPDATE_DEPARTMENT_SUCCESS
} from "./actionTypes.js"

export const getDepartmentByName = name => ({
    type: GET_DEPARTMENT_BY_NAME_REQUEST,
    payload: name
})


export const getDepartmentByNameSuccess = payload => ({
    type: GET_DEPARTMENT_BY_NAME_SUCCESS,
    payload
})


export const getDepartmentByNameFailed = payload => ({
    type: GET_DEPARTMENT_BY_NAME_FAILED,
    payload
})




export const getMDMDepartment = (filter) => ({
    type: GET_DEPARTMENT_REQUEST,
    payload:filter
})



export const getMDMDepartmentSuccess = department => ({
    type: GET_DEPARTMENT_SUCCESS,
    payload: department
})

export const getMDMDepartmentFailed = payload => ({
    type: GET_DEPARTMENT_FAILED,
    payload
})

export const createMDMDepartment = department => ({
    type: CREATE_DEPARTMENT_REQUEST,
    payload: department
})

export const createMDMDepartmentSuccess = department => ({
    type: CREATE_DEPARTMENT_SUCCESS,
    payload: department
})

export const createMDMDepartmentFailed = payload => ({
    type: CREATE_DEPARTMENT_FAILED,
    payload
})

export const deleteMDMDepartment= id => ({
    type: DELETE_DEPARTMENT_REQUEST,
    payload: id
})

export const deleteMDMDepartmentSuccess = id => ({
    type: DELETE_DEPARTMENT_SUCCESS,
    payload: id
})

export const deleteMDMDepartmentFailed = id => ({
    type: DELETE_DEPARTMENT_FAILED,
    payload: id
})

export const updateMDMDepartment = (department )=> ({
    type: UPDATE_DEPARTMENT_REQUEST,
    payload: department
})

export const updateMDMDepartmentSuccess = (department ) => ({
    type: UPDATE_DEPARTMENT_SUCCESS,
    payload: department
})

export const updateMDMDepartmentFailed = payload => ({
    type: UPDATE_DEPARTMENT_FAILED,
    payload
})

export const resetMDMDepartmentMessages = () => ({
    type: DEPARTMENT_MESSAGES_RESET
}
)


export const resetDepartmentMessages = () => ({
    type: RESET_DEPARTMENT_MESSAGES,
  })
  
  export const resetDepartmentMessagesSuccess = () => ({
    type: RESET_DEPARTMENT_MESSAGES_SUCCESS,
  })

