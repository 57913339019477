import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
} from "reactstrap";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import { postResetPassword } from "helpers/api_helper";

const ResetPassword = () => {

    //meta title
    document.title = "Kasb";
    const [apiMessage, setApiMessage] = useState({
        success: false,
        error: false
    })
    const params = useParams()
    const id = params?.id
    const token = params?.token
    const history = useHistory()
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your password"),
            confirmPassword: Yup.string()
                .required('Password confirmation is required')
                .when('password', {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
                }),
        }),
        onSubmit: (values) => {
            postResetPassword(token, { newPassword: values.password })
                .then(res => {
                    setApiMessage({ error: false, success: true })
                })
                .catch(err => {
                    setApiMessage({ error: true, success: false })
                    console.log(err)
                })
        }
    });

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary"> Reset Password</h5>
                                            </div>
                                        </Col>
                                        <Col xs={5} className="align-self-end">
                                            <img
                                                src={profile}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="dashboard">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="p-2">

                                        {apiMessage.success && <div className="alert alert-success text-center mb-4" role="alert">
                                            Your password has been updated successfully ! 
                                        </div>}
                                        {apiMessage.error && <div className="alert alert-danger text-center mb-4" role="alert">
                                            An error has occured! Please try again. 
                                        </div>}
                                        <Form className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">New Password</Label>
                                                <Input
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Enter new password"
                                                    type="password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.password || ""}
                                                    invalid={
                                                        validation.touched.password && validation.errors.password ? true : false
                                                    }
                                                />
                                                {validation.touched.password && validation.errors.password ? (
                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Confirm Password</Label>
                                                <Input
                                                    name="confirmPassword"
                                                    className="form-control"
                                                    placeholder="Enter confirm password"
                                                    type="password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.confirmPassword || ""}
                                                    invalid={
                                                        validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                    }
                                                />
                                                {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                    <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="text-end">
                                                <button
                                                    className="btn btn-primary w-md "
                                                    type="submit"
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    © {new Date().getFullYear()} Adresstreet.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ResetPassword;
