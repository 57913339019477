//LOGIN
export const POST_LOGIN = "auth/admins/signin";
export const VERIFY_ME = "/auth/admins/me";
export const RESET_PASSWORD = "/auth/reset-password/";
//ADMINS
export const POST_REGISTER = "/auth/admins";
export const GET_ADMINS = "/auth/admins";
export const GET_ADMIN = "/auth/admins/";
export const PUT_UPDATE = "/auth/admins/";
export const GET_USER = "/users/";


//dasboard **
export const GET_DASHBOARD = "/users/dashboard";

//ROLES
export const GET_ROLES = "/roles";
export const PUT_ROLE = "/roles/";
export const GET_PERMISSIONS = "/permissions";

//permission
export const GET_ROLESS = "/roles";
export const GET_ROLE = "/roles/";
export const PUT_ROLES= "/roles/";
export const POST_ROLES = "/roles";
export const DELETE_ROLES = "/roles/";

//METADATA_CITY
export const GET_METADATA_CITY = "/metadata/type/city";
export const PUT_METADATA_CITY = "/metadata/";
export const POST_METADATA_CITY = "/metadata/";
//METADATA_CONTRY
export const GET_METADATA_COUNTRY = "/metadata/type/country";
export const PUT_METADATA_COUNTRY = "/metadata/";
export const POST_METADATA_COUNTRY = "/metadata/";
//METADATA_DEPARTMENT
export const GET_METADATA_DEPARTMENT = "/metadata/type/department";
export const PUT_METADATA_DEPARTMENT = "/metadata/";
export const DEL_METADATA_DEPARTMENT = "/metadata/";
export const POST_METADATA_DEPARTMENT = "/metadata/";
//METADATA_DEPARTMENT
export const GET_METADATA_BANNERS = "/metadata/type/banner";
export const PUT_METADATA_BANNERS = "/metadata/";
export const POST_METADATA_BANNERS = "/metadata/";
//METADATA_DELEGATION
export const GET_METADATA_DELEGATION = "/metadata/type/delegation";
export const PUT_METADATA_DELEGATION= "/metadata/";
export const POST_METADATA_DELEGATION = "/metadata/";
//METADATA_
export const GET_METADATA_LOCALITY = "/metadata/type/locality";
export const PUT_METADATA_LOCALITY= "/metadata/";
export const POST_METADATA_LOCALITY = "/metadata/";
//METADATA_
export const GET_METADATA_PROFESSION = "/metadata/type/profession";
export const PUT_METADATA_PROFESSION= "/metadata/";
export const DEL_METADATA_PROFESSION= "/metadata/";
export const POST_METADATA_PROFESSION = "/metadata/";
//METADATA_GOVERNORATE
export const GET_METADATA_GOVERNORATE = "/metadata/type/governorate";
export const PUT_METADATA_GOVERNORATE = "/metadata/";
export const POST_METADATA_GOVERNORATE= "/metadata/";


//ADS_
export const GET_ADS = "/ads/";
export const PUT_ADS= "/ads/";
export const POST_ADS = "/ads/";
export const DEL_ADS = "/ads/";


//professionnal
export const GET_PROFESSIONNAL_STATS = "/users/stats/type/professionals";
export const GET_PROFESSIONNAL = "/users/type/professionals";
export const PUT_PROFESSIONNAL = "/users/";
export const POST_PROFESSIONNAL = "/auth/signup";
//SEEKERS
export const GET_SEEKER_STATS = "/users/stats/type/seekers";
export const GET_SEEKER = "/users/type/seekers";
export const PUT_SEEKER = "/users/";
export const POST_SEEKER = "/auth/signup";

//CRM/evaluation
export const GET_EVALUATION = "/evaluations/";
export const GET_EVALUATIONBYID = "/evaluations/";
export const PUT_EVALUATION = "/evaluations/";
export const POST_EVALUATION = "/evaluations/";
export const DEL_EVALUATION = "/evaluations/";
//CRM/request
export const GET_REQUEST_STATS = "/requests/stats";
export const GET_REQUEST = "/requests/";
export const PUT_REQUEST = "/requests/";
export const POST_REQUEST = "/requests/";
export const DEL_REQUEST = "/requests/";
//CRM/request
export const GET_SEARCH_STATS = "professionalSearchs/stats";
export const GET_SEARCH = "/professionalsearchs/";
export const PUT_SEARCH = "/professionalsearchs/";
export const POST_SEARCH_PROFESSIONALS = "/professionalsearchs/";


//FILES
export const UPLOAD_FILES = "/uploads"

//Configs
export const GET_CONFIGS = "/configs";
export const PUT_CONFIGS = "/configs/";
//Logs
export const GET_LOGS = "/logs";
export const GET_LOGS_BY_MODEL = "/logs/model/";
