import {
    SEEKERS_MESSAGES_RESET,
    RESET_SEEKERS_MESSAGES_SUCCESS,
    CREATE_SEEKERS_FAILED,
    CREATE_SEEKERS_REQUEST,
    CREATE_SEEKERS_SUCCESS,
    DELETE_SEEKERS_FAILED,
    DELETE_SEEKERS_REQUEST,
    DELETE_SEEKERS_SUCCESS,
    GET_SEEKERS_BY_NAME_FAILED,
    GET_SEEKERS_BY_NAME_REQUEST,
    GET_SEEKERS_BY_NAME_SUCCESS,
    GET_SEEKERS_FAILED,
    GET_SEEKERS_REQUEST,
    GET_SEEKERS_SUCCESS,
    UPDATE_SEEKERS_FAILED,
    UPDATE_SEEKERS_REQUEST,
    UPDATE_SEEKERS_SUCCESS,
    GET_SEEKERS_STATS_REQUEST,
    GET_SEEKERS_STATS_SUCCESS,
    GET_SEEKERS_STATS_FAILED,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    seekersData: [],
    seekerStatsData: {},
    success: "",
    error: "",
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_SEEKERS_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_SEEKERS_SUCCESS:
        const {data, ...filters} = action.payload
        return {
          ...state,
          seekersData: [...data],
          ...filters,
          loading: false,
          success: "GET",
        }
      case GET_SEEKERS_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_SEEKERS_STATS_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_SEEKERS_STATS_SUCCESS:
       
        return {
          ...state,
          seekerStatsData: action.payload,
          loading: false,
          success: "GET",
        }
      case GET_SEEKERS_STATS_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_SEEKERS_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_SEEKERS_BY_NAME_SUCCESS:
        return {
          ...state,
          seekersData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_SEEKERS_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_SEEKERS_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_SEEKERS_SUCCESS:
        return {
          ...state,
          seekersData: [...state.seekersData, action.payload],
          success: "CREATE",
          loading: false,
        }
      case CREATE_SEEKERS_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_SEEKERS_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_SEEKERS_SUCCESS:
        return {
          ...state,
          seekersData: state.seekersData.filter(
            seeker => seeker.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_SEEKERS_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_SEEKERS_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case UPDATE_SEEKERS_SUCCESS:
        return {
          ...state,
          seekersData: state.seekersData.map(seeker =>
            seeker.id.toString() === action.payload.id.toString()
              ? { seeker, ...action.payload }
              : seeker
          ),
          success: "UPDATE",
          loading: false,
        }
      case UPDATE_SEEKERS_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case SEEKERS_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_SEEKERS_MESSAGES_SUCCESS:
          return {
            ...state,
            error: null,
            success: null
          }
      default:
        return state
    }
  }
  
  export default reducer
  
