import {
   
  
    GET_SEARCH_FAILED,
    GET_SEARCH_REQUEST,
    GET_SEARCH_STATS_FAILED,
    GET_SEARCH_STATS_REQUEST,
    GET_SEARCH_STATS_SUCCESS,
    GET_SEARCH_SUCCESS,
    UPDATE_SEARCH_FAILED,
    UPDATE_SEARCH_REQUEST,
    UPDATE_SEARCH_SUCCESS,
    CREATE_SEARCH_FAILED,
    CREATE_SEARCH_REQUEST,
    CREATE_SEARCH_SUCCESS,
   
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    searchData: [],
    searchStatsData: {},
    success: "",
    error: "",
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_SEARCH_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_SEARCH_SUCCESS:
        const {data,success, ...filters} = action.payload
        return {
          ...state,
          searchData: [...data],
          ...filters,
          loading: false,
          success: success,
        }
      case GET_SEARCH_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_SEARCH_STATS_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_SEARCH_STATS_SUCCESS:
      
        return {
          ...state,
          searchStatsData: action.payload,
          loading: false,
          success: "GET",
        }
      case GET_SEARCH_STATS_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
        case UPDATE_SEARCH_REQUEST:
          return {
            ...state,
            loading: true,
            success: "",
            error: "",
          }
        case UPDATE_SEARCH_SUCCESS:
          return {
            ...state,
            searchData: state.searchData.map(search =>
              search.id.toString() === action.payload.id.toString()
                ? { search, ...action.payload }
                : search
            ),
            success: success,
            loading: false,
          }
        case UPDATE_SEARCH_FAILED:
          return {
            ...state,
            error: "FAILED UPDATE",
            loading: false,
          }
          case CREATE_SEARCH_REQUEST:
            return {
              ...state,
              loading: true,
            };
          case CREATE_SEARCH_SUCCESS:
            return {
              ...state,
              professiannalData: [...state.searchData, action.payload],
              success: "CREATE",
              loading: false,
            };
          case CREATE_SEARCH_FAILED:
            return {
              ...state,
              error: "FAILED CREATE",
              loading: false,
            };
      default:
        return state
    }
  }
  
  export default reducer
  
