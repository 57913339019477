import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"


//redux
import { useDispatch } from "react-redux"


import {
  createMDMCountry,
  updateMDMCountry,
} from "store/actions"
import { withTranslation } from "react-i18next"

const CountryModal = props => {
    const { modal, toggle, country, isEdit } = props
  const dispatch = useDispatch()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (country && country.name) || "",
      status: (country && country.status) || "active",
      type: (country && country.type) || "country",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Your Name")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateCountry = {
          id: country ? country.id : 0,
          name: values.name,
        }
        // update
        // console.log("updatecity", updateCountry)
        dispatch(updateMDMCountry(updateCountry))
        validation.resetForm()
      } else {
        const newCountry = {
          name: values["name"],
          type: values["type"],
          status: values["status"],
        }
        // save new country
        dispatch(createMDMCountry(newCountry))
        validation.resetForm()
      }
      toggle()
    },
  })
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit Country") : props.t("Add Country")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
             
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-customer">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(CountryModal)