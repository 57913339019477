import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  Spinner,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import {
  createMDMBanners,
  getMDMGovernorates,
  updateMDMBanners,
} from "store/actions";
import { upload } from "helpers/api_helper";

const BannerModal = (props) => {
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  });

  const dispatch = useDispatch();
  const { modal, toggle, Banners, isEdit } = props;
  const [loading, setLoading] = useState(false)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (Banners && Banners.name) || "",
      type: (Banners && Banners.type) || "banner",
      status: (Banners && Banners.status) || "active",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Your Name")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateBanners = {
          id: Banners.id,
          imageUrl: values.imageUrl,
          name: values.name,
        };
        // update
        // console.log("updateBanners",updateBanners)
        dispatch(updateMDMBanners(updateBanners))
        validation.resetForm();
      } else {
        const Newbanners = {
          imageUrl: values["imageUrl"],
          name: values["name"],
          type: values["type"],
          status: values["status"],
        };
        // save new banners
        console.log("new banners",Newbanners)
        dispatch(createMDMBanners(Newbanners))
        validation.resetForm();
      }
      toggle();
    },
  });



  const handelImage = (event) => {
    setLoading(true);
    let formData = new FormData();
    formData.set("file", event.target.files[0]);
    upload(formData)
      .then(({ path }) => {
        setLoading(false);
        validation.setFieldValue("imageUrl", path);
      })
      .catch((e) => {
        setLoading(false);
        // console.log(e.response)
      });
  };



  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Modifier une bannière") : props.t("Ajouter une bannière")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Row>
              <Col className="col-12">
                <Col className="col-9">
                <div className="mb-3">
                    <Label className="form-label">{props.t("Nom")}</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                
                  <div className="mb-5">
                <Label className="form-label">Photo</Label>
                <div className="row">
                  <div className="col-12">
                    <div className="position-relative">
                      <Input
                        className="position-absolute"
                        // id="inputGroupFile04"
                        // aria-describedby="inputGroupFileAddon04"
                        // aria-label="Upload"
                        name="imageUrl"
                        type="file"
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={handelImage}
                        onBlur={validation.handleBlur}
                        //value={validation.values.logo || ""}
                        invalid={
                          validation.touched.logo && validation.errors.logo
                            ? true
                            : false
                        }
                      />
                      {loading && (
                        <Spinner className="mt-2 float-end me-3" size="sm" />
                      )}
                      {/* <i
                          className="far fa-times-circle"
                          id="inputGroupFileAddon04" type="button" onClick={onRemove}
                        ></i>
                        <i className="dripicons-document-delete"/> */}
                    </div>
                  </div>
                </div>
                {validation.touched.logo && validation.errors.logo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                ) : null}
              </div>
                 
                </Col>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-danger save-customer  me-2">
                  {props.t("Save")}
                </button>
                {/* <button type="submit" className="btn btn-success save-customer">
                  {props.t("Publier")}
                </button> */}
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(BannerModal);
