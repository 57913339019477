import {
    SEEKERS_MESSAGES_RESET,
    CREATE_SEEKERS_FAILED,
    CREATE_SEEKERS_REQUEST,
    CREATE_SEEKERS_SUCCESS,
    DELETE_SEEKERS_FAILED,
    DELETE_SEEKERS_REQUEST,
    DELETE_SEEKERS_SUCCESS,
    GET_SEEKERS_BY_NAME_FAILED,
    GET_SEEKERS_BY_NAME_REQUEST,
    GET_SEEKERS_BY_NAME_SUCCESS,
    GET_SEEKERS_FAILED,
    GET_SEEKERS_REQUEST,
    GET_SEEKERS_SUCCESS,
    GET_SEEKERS_STATS_FAILED,
    GET_SEEKERS_STATS_REQUEST,
    GET_SEEKERS_STATS_SUCCESS,
    UPDATE_SEEKERS_FAILED,
    UPDATE_SEEKERS_REQUEST,
    RESET_SEEKERS_MESSAGES,
    RESET_SEEKERS_MESSAGES_SUCCESS,
    UPDATE_SEEKERS_SUCCESS,
  } from "./actionTypes.js";
  
  export const getSeekersByName = (name) => ({
    type: GET_SEEKERS_BY_NAME_REQUEST,
    payload: name,
  });
  
  export const getSeekersByNameSuccess = (payload) => ({
    type: GET_SEEKERS_BY_NAME_SUCCESS,
    payload,
  });
  
  export const getSeekersByNameFailed = (payload) => ({
    type: GET_SEEKERS_BY_NAME_FAILED,
    payload,
  });
  
  export const getSeekers = (filter) => ({
    type: GET_SEEKERS_REQUEST,
    payload: filter,
  });
  
  export const getSeekersSuccess = (seekers) => ({
    type: GET_SEEKERS_SUCCESS,
    payload: seekers,
  });
  
  export const getSeekersFailed = (payload) => ({
    type: GET_SEEKERS_FAILED,
    payload,
  });
  
  export const getSeekersStats = (filter) => ({
    type: GET_SEEKERS_STATS_REQUEST,
    payload: filter,
  });
  
  export const getSeekersStatsSuccess = (seekers) => ({
    type: GET_SEEKERS_STATS_SUCCESS,
    payload: seekers,
  });
  
  export const getSeekersStatsFailed = (payload) => ({
    type: GET_SEEKERS_STATS_FAILED,
    payload,
  });
  
  export const createSeekers = (Seeker) => ({
    type: CREATE_SEEKERS_REQUEST,
    payload: Seeker,
  });
  
  export const createSeekersSuccess = (Seeker) => ({
    type: CREATE_SEEKERS_SUCCESS,
    payload: Seeker,
  });
  
  export const createSeekersFailed = (payload) => ({
    type: CREATE_SEEKERS_FAILED,
    payload,
  });
  
  export const deleteSeekers = (id) => ({
    type: DELETE_SEEKERS_REQUEST,
    payload: id,
  });
  
  export const deleteSeekersSuccess = (id) => ({
    type: DELETE_SEEKERS_SUCCESS,
    payload: id,
  });
  
  export const deleteSeekersFailed = (id) => ({
    type: DELETE_SEEKERS_FAILED,
    payload: id,
  });
  
  export const updateSeekers = (Seeker) => ({
    type: UPDATE_SEEKERS_REQUEST,
    payload: Seeker,
  });
  
  export const updateSeekersSuccess = (Seeker) => ({
    type: UPDATE_SEEKERS_SUCCESS,
    payload: Seeker,
  });
  
  export const updateSeekersFailed = (payload) => ({
    type: UPDATE_SEEKERS_FAILED,
    payload,
  });
  
  export const resetSeekersMessages = () => ({
    type: SEEKERS_MESSAGES_RESET,
  });
  
  export const resetSeekersMessagesSuccess = () => ({
    type: RESET_SEEKERS_MESSAGES_SUCCESS,
  });
  