import React, { useEffect, useState, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"
import { isEmpty, map } from "lodash"
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"


//redux
import { useSelector, useDispatch } from "react-redux"


import {
  deleteEvaluation,
  getEvaluation,
  getProfessionnal,
  getUsers,
  resetCountryMessages,
} from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import moment from "moment"
import EvaluationModalEdit from "./EvaluationModalEdit"

const Evaluation = props => {
  const {
    evaluationData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading
  } = useSelector(state => state.evaluations)
  const {professiannalData} = useSelector((state) => state.professionnals);


  //meta title
  document.title = "Adresstreet"
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    adminId:"",
    status:"", 
    cr1:"",
    cr2:"",
    cr3:"",
    cr4:"",
    cr5:"",
    professionalId:"",
    page: 1,
    limit: 100,
  })
  const [modal, setModal] = useState(false)
  const [evaluationList, setEvaluationList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [evaluation, setEvaluation] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")
  const { users } = useSelector(state => state.users);
  const history = useHistory()

  const handleevaluationClick = arg => {
    const evaluation = arg

    setEvaluation({
     ...evaluation,
    })

    setIsEdit(true)
    toggle()
  }


  // Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Id"),
        accessor: "id",
        disableFilters: true,
      },
      {
        Header: props.t("Search Id"),
        accessor: "professionalSearchId",
        disableFilters: true,
     
      },
      {
        Header: props.t("Date"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm")}
            </>
          )
        },
      },
    
      {
        Header: props.t("Professionnel"),
        accessor: "professional",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <div
              className="external-event"
            >
              {cellProps.professional?.firstname + " " + cellProps.professional?.lastname ||
                props.t("Non fourni")}
            </div>
          );
        },
      },
      {
        Header: props.t("Chercheur"),
        accessor: "seeker",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <div
              className="external-event"
            >
              {cellProps.seeker?.firstname + " " + cellProps.seeker?.lastname ||
                props.t("Non fourni")}
            </div>
          );
        },
      },
      {
        Header: props.t("Cr1"),
        accessor: "cr1",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <ArabicName {...cellProps} />
        // },
      },
      {
        Header: props.t("cr2"),
        accessor: "cr2",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <ArabicName {...cellProps} />
        // },
      },
      {
        Header: props.t("cr3"),
        accessor: "cr3",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <ArabicName {...cellProps} />
        // },
      },
      {
        Header: props.t("cr4"),
        accessor: "cr4",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <ArabicName {...cellProps} />
        // },
      },
      {
        Header: props.t("cr5"),
        accessor: "cr5",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <ArabicName {...cellProps} />
        // },
      },
     
      {
        Header: props.t("Statut"),
        accessor: "status",
        disableFilters: true,
        accessor: cellProps => {
           return <>
           {props.t(cellProps.status)}
          </>
        },
      },
      {
        Header: props.t("Réalisée par"),
        accessor: "adminId",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
            {/* {console.log("cellprops",cellProps)} */}
              {(cellProps.admin &&
                cellProps.admin?.firstname +
                  " " +
                  cellProps.admin?.lastname) ||
                props.t("Non fourni")}
            </>
          );
        },
      },
     
    
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* {checkPermission(userRoles, ["MasterData", "Country", "Update"]) && */}
                <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const countryData = cellProps.row.original
                    handleevaluationClick(countryData)
                  }}
                >
                  <i className="bx bx-bullseye mt-1 font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const countryData = cellProps.row.original
                    onClickDelete(countryData)
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setEvaluation(null)
    } else {
      setModal(true)
    }
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = evaluation => {
    setEvaluation(evaluation)
    setDeleteModal(true)
  }

  const handleDeleteEvaluation = () => {
    if (evaluation.id) {
      dispatch(deleteEvaluation({ id: evaluation.id }))
      setDeleteModal(false)
      dispatch(getEvaluation())
    }
  }

  useEffect(() => {

    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("created successfully !")
      dispatch(resetCountryMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Successfully !")
      dispatch(resetCountryMessages())
    }
  }, [success])

  useEffect(() => {
    {
      dispatch(getEvaluation(filter))
      dispatch(getUsers(filter))
      dispatch(getProfessionnal(filter))

    }
  }, [dispatch, filter])

  useEffect(() => {
    setEvaluationList(evaluationData)
  }, [evaluationData])

  useEffect(() => {
    if (!isEmpty(evaluationData)) {
      setEvaluationList(evaluationData)
    }
  }, [evaluationData])

  const handleEvaluationClicks = () => {
    setEvaluationList("")
    setIsEdit(false)
    toggle()
  }

  const setPageNumber = (activePage) => { setFilter(prevFilter => { return { ...prevFilter, page: activePage } }) }
 
  const handleLimit = (limit) => { setFilter(prevFilter => { return { ...prevFilter, limit: limit } }) }
 
  const handleDateFromTo = date => {
    if (!date) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: "",
          to: "",
        }
      })
    }
    else if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }

  const handleUser = (user) => { setFilter(prevFilter => { return { ...prevFilter, adminId: user.value } }) }
  const handleProfessional = (professional) => { setFilter(prevFilter => { return { ...prevFilter, professionalId: professional.value } }) }
  const handleCritere1 = (critere) => { setFilter(prevFilter => { return { ...prevFilter, cr1: critere.value } }) }
  const handleCritere2 = (critere) => { setFilter(prevFilter => { return { ...prevFilter, cr2: critere.value } }) }
  const handleCritere3 = (critere) => { setFilter(prevFilter => { return { ...prevFilter, cr3: critere.value } }) }
  const handleCritere4 = (critere) => { setFilter(prevFilter => { return { ...prevFilter, cr4: critere.value } }) }
  const handleCritere5 = (critere) => { setFilter(prevFilter => { return { ...prevFilter, cr5: critere.value } }) }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvaluation}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("CRM")} breadcrumbItem={props.t("Liste des évaluations")} />

      
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer 
                    columns={columns}
                    data={evaluationData}
                    isGlobalFilter={true}
                    // isExcelOption={true}
                    isAddProfessionOptions={true}   
                    ProfessionnelFilter={true}
                    professionnals={professiannalData}
                    setProfessionnal={handleProfessional}
                    isUsersFilter={true}
                    users={users}
                    setUser={handleUser}
                    EvaluationCrFilter={true}
                    EvaluationCr1Filter={true}
                    EvaluationCr2Filter={true}
                    EvaluationCr3Filter={true}
                    EvaluationCr4Filter={true}
                    EvaluationCr5Filter={true}
                    setCr1={handleCritere1}
                    setCr2={handleCritere2}
                    setCr3={handleCritere3}
                    setCr4={handleCritere4}
                    setCr5={handleCritere5}
                    filterDate={true}
                    setDateFilter={handleDateFromTo}
                    resetOptionEvaluation={true}
                    handleOrderClicks={handleEvaluationClicks}
                    handelAddClick={handleEvaluationClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
               <EvaluationModalEdit modal={modal} toggle={toggle} isEdit={isEdit} userSearch={evaluation} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Evaluation)
