import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"
import Roles from "./auth/roles/reducer"

import cities from "./mdm/city/reducer"
import countries from "./mdm/country/reducer"
import departments from "./mdm/department/reducer"
import banners from "./mdm/banners/reducer"
import delegations from "./mdm/delegation/reducer"
import localities from "./mdm/locality/reducer"
import professions from "./mdm/profession/reducer"
import governorates from "./mdm/governorate/reducer"
import professionnals from "./profession/reducer"
import seekers from "./seekers/reducer"
import settings from "./mdm/setting/reducer"
//contacts
import users from "./users/reducer"
//ads
import ads from "./Ads/reducer"
//CRM
import evaluations from "./CRM/evaluation/reducer"
import requests from "./CRM/request/reducer"
import searchs from "./CRM/search/reducer"



const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Roles,
  cities,
  countries,
  departments,
  banners,
  delegations,
  localities,
  professions,
  professionnals,
  seekers,
  evaluations,
  requests,
  searchs,
  settings,
  governorates,
  Profile,
  users,
  ads,
})

export default rootReducer
