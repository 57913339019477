import React from "react"
import { Redirect } from "react-router-dom"

// Profile
// import UserProfile from "../pages/Authentication/user-profile"

//profession
import professionnal from "../pages/Professionnal/Professionnal"
import ProfessionModal2 from "../pages/Professionnal/professionnal-modal2"
import professionsProfile from "../pages/Professionnal/ProfessionnalProfile/professionnal-profile"

//seekers
import seekers from "../pages/Seekers/Seekers"

//CRM
import Search from "../pages/CRM/ResultCRM/SearchResults"
import Evaluation from "../pages/CRM/Evaluation/Evaluation"
import Service from "../pages/CRM/Service/ServiceVip"

//Publicity
import Publicity from "../pages/AdsManagment/Publicity"

//MDM
import Countries from "../pages/MDM/Countries/Country"
import Cities from "../pages/MDM/Cities/Cities"
import departments from "../pages/MDM/Departments/Department"
import delegations from "../pages/MDM/Delegations/delegation"
import localities from "../pages/MDM/localites/localite"
import Profession from "../pages/MDM/Professions/Professions"
import banners from "../pages/MDM/Banners/Banners"
import governates from "../pages/MDM/Governates/governates"
import settings from "../pages/MDM/Setting/Setting"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ResetPasswordEmail from "../pages/Authentication/ResetPasswordEmail"
import ResetPassword from "pages/Authentication/ResetPassword"


//Users
import UsersGrid from "../pages/Users/users-grid"
import UsersList from "../pages/Users/UserList/users-list"
import UsersProfile from "../pages/Users/UsersProfile/user-profile"


// Dashboard
import Dashboard from "../pages/Dashboard/index"
import userProfile from "pages/Authentication/user-profile"

const authProtectedRoutes = [

  { path: "/dashboard", component: Dashboard },

  //profession
  { path: "/professionnals-list", component: professionnal },
  { path: "/profession-modal2", component: ProfessionModal2 },
  { path: "/profession-profile/:id", component: professionsProfile },
  
  //seekers
  { path: "/seekers-list", component: seekers },
  
  //publicity
  { path: "/publicity-list", component: Publicity },
  
  
  //CRM
  { path: "/searchResults-list", component: Search },
  { path: "/evaluation-list", component: Evaluation },
  { path: "/servicesVip-list", component: Service },
  

  
  //MDM
  { path: "/countries-list", component: Countries },
  { path: "/cities-list", component: Cities },
  { path: "/departements-list", component: departments },
  { path: "/delegation-list", component: delegations },
  { path: "/locality-list", component: localities },
  { path: "/profession-list", component: Profession },
  { path: "/banners-list", component: banners },
  { path: "/governorates-list", component: governates },
  { path: "/setting", component: settings },

  //profile
  { path: "/profile", component: userProfile },

  // Users
  { path: "/users-grid", component: UsersGrid },
  { path: "/users-list", component: UsersList },
  { path: "/user-profile/:id", component: UsersProfile },

  //Roles
  // { path: "/roles-list", component: RolesList },
  // { path: "/roles", component: RolesAddEdit },
  // { path: "/roles/:id", component: RolesAddEdit },



  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ResetPasswordEmail },
  { path: "/reset-password/:token/:id", component: ResetPassword },
  { path: "/register", component: Register },

]

export { authProtectedRoutes, publicRoutes }
