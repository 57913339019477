import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createMDMDelegationFailed,
  createMDMDelegationSuccess,
  deleteMDMDelegationFailed,
  deleteMDMDelegationSuccess,
  getMDMDelegationFailed,
  getMDMDelegationSuccess,
  updateMDMDelegationFailed,
  updateMDMDelegationSuccess,
} from "./actions"
import {
  DELEGATION_MESSAGES_RESET,
  CREATE_DELEGATION_REQUEST,
  DELETE_DELEGATION_REQUEST,
  GET_DELEGATION_BY_NAME_REQUEST,
  GET_DELEGATION_REQUEST,
  RESET_DELEGATION_MESSAGES,
  UPDATE_DELEGATION_REQUEST
} from "./actionTypes";



import { getDelegation, postDelegation, putDelegation } from "helpers/api_helper";



function* getDelegationSaga({payload}) {
  try {
    const response = yield getDelegation(payload || {page: 1, limit: 10000});
    yield put(getMDMDelegationSuccess(response))
  } catch (e) {
    yield put(getMDMDelegationFailed(e))
  }
}

function* getDelegationBynameSaga() {

}

function* getDelegationFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
    // console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getMDMDelegationFailed(e))
  }
}

function* createDelegationSaga({ payload: delegation }) {
  try {
    const data = yield postDelegation(delegation);
 
    const response = yield getDelegation({limit: 20, page: 1});
  
    yield put(getMDMDelegationSuccess(response))

  } catch (e) {
    yield put(createMDMDelegationFailed(e))
  }
}

function* deleteDelegationSaga({ payload: id }) {
  try {
    // const result = yield new Repository().deleteCategory(payload)
    // const data = yield new Repository().getCategories({ current: 1, limit: 20 })
    yield put(deleteMDMDelegationSuccess(id))
  } catch (e) {
    yield put(deleteMDMDelegationFailed(e))
  }
}

function* updateDelegationSaga({ payload: delegation }) {
  try {
    // separer l id & objet Department
    const { id, ...updatedDelegation } = delegation

    const data = yield putDelegation(id, updatedDelegation);
    const response = yield getDelegation({limit: 20, page: 1});
    yield put(getMDMDelegationSuccess(response))
  } catch (e) {
    yield put(updateMDMDelegationFailed(e))
  }
}

function* resetDelegationMessagesSaga() {
  yield put(resetDelegationMessagesSuccess())
}




export default function* delegationSaga() {
  yield all([
    takeEvery(GET_DELEGATION_REQUEST, getDelegationSaga),
    takeEvery(GET_DELEGATION_BY_NAME_REQUEST, getDelegationBynameSaga),
    takeEvery(CREATE_DELEGATION_REQUEST, createDelegationSaga),
    takeEvery(DELETE_DELEGATION_REQUEST, deleteDelegationSaga),
    takeEvery(UPDATE_DELEGATION_REQUEST, updateDelegationSaga),
    takeEvery(RESET_DELEGATION_MESSAGES, resetDelegationMessagesSaga),
  ])
}
