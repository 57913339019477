import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createMDMDepartmentFailed,
  createMDMDepartmentSuccess,
  deleteMDMDepartmentFailed,
  deleteMDMDepartmentSuccess,
  getMDMDepartmentFailed,
  getMDMDepartmentSuccess,
  updateMDMDepartmentFailed,
  updateMDMDepartmentSuccess,
} from "./actions"
import {
  DEPARTMENT_MESSAGES_RESET,
  CREATE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_BY_NAME_REQUEST,
  GET_DEPARTMENT_REQUEST,
  RESET_DEPARTMENT_MESSAGES,
  UPDATE_DEPARTMENT_REQUEST
} from "./actionTypes";



import { delDepartment, getDepartment, postDepartment, putDepartment } from "helpers/api_helper";



function* getDepartmentSaga({payload}) {
  try {
    const response = yield getDepartment(payload || {page: 1, limit: 1000});
    yield put(getMDMDepartmentSuccess(response))
  } catch (e) {
    yield put(getMDMDepartmentFailed(e))
  }
}

function* getDepartmentBynameSaga() {

}

function* getDepartmentFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
    // console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getMDMDepartmentFailed(e))
  }
}

function* createDepartmentSaga({ payload: department }) {
  try {
    const data = yield postDepartment(department);

    const response = yield getDepartment({limit: 20, page: 1});

    yield put(getMDMDepartmentSuccess(response))

  } catch (e) {
    yield put(createMDMDepartmentFailed(e))
  }
}

function* deleteDepartmentSaga( payload ) {
  try {
    const result = yield delDepartment(payload.payload.id)
    const data = yield getDepartment({ current: 1, limit: 20 })
    yield put(getMDMDepartmentSuccess(data))
   
  } catch (e) {
    yield put(deleteMDMDepartmentFailed(e))
  }
}

function* updateDepartmentSaga({ payload: department }) {
  try {
    // separer l id & objet Department
    const { id, ...updatedDepartment } = department

    const data = yield putDepartment(id, updatedDepartment);
    const response = yield getDepartment({limit: 20, page: 1});
    yield put(getMDMDepartmentSuccess(response))
  } catch (e) {
    yield put(updateMDMDepartmentFailed(e))
  }
}

function* resetDepartmentMessagesSaga() {
  yield put(resetDepartmentMessagesSuccess())
}




export default function* departmentSaga() {
  yield all([
    takeEvery(GET_DEPARTMENT_REQUEST, getDepartmentSaga),
    takeEvery(GET_DEPARTMENT_BY_NAME_REQUEST, getDepartmentBynameSaga),
    takeEvery(CREATE_DEPARTMENT_REQUEST, createDepartmentSaga),
    takeEvery(DELETE_DEPARTMENT_REQUEST, deleteDepartmentSaga),
    takeEvery(UPDATE_DEPARTMENT_REQUEST, updateDepartmentSaga),
    takeEvery(RESET_DEPARTMENT_MESSAGES, resetDepartmentMessagesSaga),
  ])
}
