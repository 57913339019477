import PropTypes from 'prop-types'
import React from "react"
import { Card, CardBody, Col } from "reactstrap"

const MiniCardss = props => {
  const { title, text,text1,text2,text3, iconClass,color } = props
  return (
    <React.Fragment>
      <Col xl="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                 <h5 className="text-muted fw-medium mb-2">{title}</h5>
             
                <h6 className="mb-0">{text}</h6>
                <h6 className="mb-0">{text1}</h6>
                <h6 className="mb-0">{text2}</h6>
                <h6 className="mb-0">{text3}</h6>
          
              </div>

            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

MiniCardss.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}

export default MiniCardss
