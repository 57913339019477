
import {
    BANNERS_MESSAGES_RESET,
    CREATE_BANNERS_FAILED,
    CREATE_BANNERS_REQUEST,
    CREATE_BANNERS_SUCCESS,
    DELETE_BANNERS_FAILED,
    DELETE_BANNERS_REQUEST,
    DELETE_BANNERS_SUCCESS,
    GET_BANNERS_BY_NAME_FAILED,
    GET_BANNERS_BY_NAME_REQUEST,
    GET_BANNERS_BY_NAME_SUCCESS,
    GET_BANNERS_FAILED,
    GET_BANNERS_REQUEST,
    GET_BANNERS_SUCCESS,
    UPDATE_BANNERS_FAILED,
    UPDATE_BANNERS_REQUEST,
    RESET_BANNERS_MESSAGES,
    RESET_BANNERS_MESSAGES_SUCCESS,
    UPDATE_BANNERS_SUCCESS
} from "./actionTypes.js"

export const getBannersByName = name => ({
    type: GET_BANNERS_BY_NAME_REQUEST,
    payload: name
})


export const getBannersByNameSuccess = payload => ({
    type: GET_BANNERS_BY_NAME_SUCCESS,
    payload
})


export const getBannersByNameFailed = payload => ({
    type: GET_BANNERS_BY_NAME_FAILED,
    payload
})




export const getMDMBanners = (filter) => ({
    type: GET_BANNERS_REQUEST,
    payload:filter
})



export const getMDMBannersSuccess = banners => ({
    type: GET_BANNERS_SUCCESS,
    payload: banners
})

export const getMDMBannersFailed = payload => ({
    type: GET_BANNERS_FAILED,
    payload
})

export const createMDMBanners = banners => ({
    type: CREATE_BANNERS_REQUEST,
    payload: banners
})

export const createMDMBannersSuccess = banners => ({
    type: CREATE_BANNERS_SUCCESS,
    payload: banners
})

export const createMDMBannersFailed = payload => ({
    type: CREATE_BANNERS_FAILED,
    payload
})

export const deleteMDMBanners= id => ({
    type: DELETE_BANNERS_REQUEST,
    payload: id
})

export const deleteMDMBannersSuccess = id => ({
    type: DELETE_BANNERS_SUCCESS,
    payload: id
})

export const deleteMDMBannersFailed = id => ({
    type: DELETE_BANNERS_FAILED,
    payload: id
})

export const updateMDMBanners = (Banners)=> ({
    type: UPDATE_BANNERS_REQUEST,
    payload: Banners})

export const updateMDMBannersSuccess = (banners) => ({
    type: UPDATE_BANNERS_SUCCESS,
    payload: banners
})

export const updateMDMBannersFailed = payload => ({
    type: UPDATE_BANNERS_FAILED,
    payload
})

export const resetMDMBannersMessages = () => ({
    type: BANNERS_MESSAGES_RESET
}
)


export const resetBannersMessages = () => ({
    type: RESET_BANNERS_MESSAGES,
  })
  
  export const resetBannersMessagesSuccess = () => ({
    type: RESET_BANNERS_MESSAGES_SUCCESS,
  })

