import {
    EVALUATION_MESSAGES_RESET,
    RESET_EVALUATION_MESSAGES_SUCCESS,
    CREATE_EVALUATION_FAILED,
    CREATE_EVALUATION_REQUEST,
    CREATE_EVALUATION_SUCCESS,
    DELETE_EVALUATION_FAILED,
    DELETE_EVALUATION_REQUEST,
    DELETE_EVALUATION_SUCCESS,
    GET_EVALUATION_BY_NAME_FAILED,
    GET_EVALUATION_BY_NAME_REQUEST,
    GET_EVALUATION_BY_NAME_SUCCESS,
    GET_EVALUATION_FAILED,
    GET_EVALUATION_REQUEST,
    GET_EVALUATION_SUCCESS,
    UPDATE_EVALUATION_FAILED,
    UPDATE_EVALUATION_REQUEST,
    UPDATE_EVALUATION_SUCCESS,
    GET_EVALUATIONBYID_REQUEST,
    GET_EVALUATIONBYID_SUCCESS,
    GET_EVALUATIONBYID_FAILED,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    evaluationData: [],
    success: "",
    successEval:"",
    error: "",
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_EVALUATION_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_EVALUATION_SUCCESS:
        const {data, success, ...filters} = action.payload
      
        return {
          ...state,
          evaluationData: [...data.filter(evaluation => evaluation.status == "Réalisée")],
          ...filters,
          loading: false,
          success: success,
        }
      case GET_EVALUATION_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_EVALUATIONBYID_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_EVALUATIONBYID_SUCCESS:
      
        return {
          ...state,
          evaluationData: state.evaluationData.filter(evaluation => evaluation.id == action.payload),
          loading: false,
          success: "GET",
        }
      case GET_EVALUATIONBYID_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_EVALUATION_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_EVALUATION_BY_NAME_SUCCESS:
        return {
          ...state,
          evaluationData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_EVALUATION_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_EVALUATION_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case CREATE_EVALUATION_SUCCESS:
        return {
          ...state,
          evaluationData: [...state.evaluationData, action.payload],
          success: "",
          loading: false,
        }
      case CREATE_EVALUATION_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_EVALUATION_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_EVALUATION_SUCCESS:
        return {
          ...state,
          evaluationData: state.evaluationData.filter(
            evaluation => evaluation.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_EVALUATION_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_EVALUATION_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case UPDATE_EVALUATION_SUCCESS:
        return {
          ...state,
          evaluationData: state.evaluationData.map(evaluation =>
            evaluation.id.toString() === action.payload.id.toString()
              ? { evaluation, ...action.payload }
              : evaluation
          ),
          success: success,
          loading: false,
        }
      case UPDATE_EVALUATION_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case EVALUATION_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_EVALUATION_MESSAGES_SUCCESS:
          return {
            ...state,
            error: null,
            success: null
          }
      default:
        return state
    }
  }
  
  export default reducer
  
