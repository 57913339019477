
/**filter */
export const GET_REQUEST_BY_NAME_SUCCESS = "GET_REQUEST_BY_NAME_SUCCESS";
export const GET_REQUEST_BY_NAME_REQUEST = "GET_REQUEST_BY_NAME_REQUEST";
export const GET_REQUEST_BY_NAME_FAILED = "GET_REQUEST_BY_NAME_FAILED";

/**Get */
export const GET_REQUEST_STATS_SUCCESS = 'GET_REQUEST_STATS_SUCCESS';
export const GET_REQUEST_STATS_REQUEST = 'GET_REQUEST_STATS_REQUEST';
export const GET_REQUEST_STATS_FAILED = 'GET_REQUEST_STATS_FAILED';
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS';
export const GET_REQUEST_REQUEST = 'GET_REQUEST_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_REQUEST_FAILED = 'GET_REQUEST_FAILED';

/**Create */
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
export const CREATE_REQUEST_FAILED = 'CREATE_REQUEST_FAILED';
export const CREATE_REQUEST_REQUEST = 'CREATE_REQUEST_REQUEST';
/**Delete */
export const DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_FAILED = 'DELETE_REQUEST_FAILED';
export const DELETE_REQUEST_REQUEST = 'DELETE_REQUEST_REQUEST';
/**Update */
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_FAILED = 'UPDATE_REQUEST_FAILED';
export const UPDATE_REQUEST_REQUEST = 'UPDATE_REQUEST_REQUEST';

export const REQUEST_MESSAGES_RESET = 'REQUEST_MESSAGES_RESET';
export const REQUEST_MESSAGES_SUCCESS = 'REQUEST_MESSAGES_SUCCESS';
export const RESET_REQUEST_MESSAGES_SUCCESS = 'RESET_REQUEST_MESSAGES_SUCCESS';
export const RESET_REQUEST_MESSAGES = 'RESET_REQUEST_MESSAGES';

