


/**Get */
export const GET_SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS';
export const GET_SEARCH_REQUEST = 'GET_SEARCH_REQUEST';
export const GET_SEARCH_FAILED = 'GET_SEARCH_FAILED';


/**Get */
export const GET_SEARCH_STATS_SUCCESS = 'GET_SEARCH_STATS_SUCCESS';
export const GET_SEARCH_STATS_REQUEST = 'GET_SEARCH_STATS_REQUEST';
export const GET_SEARCH_STATS_FAILED = 'GET_SEARCH_STATS_FAILED';



/**UPDATE */
export const UPDATE_SEARCH_SUCCESS = 'UPDATE_SEARCH_SUCCESS';
export const UPDATE_SEARCH_REQUEST = 'UPDATE_SEARCH_REQUEST';
export const UPDATE_SEARCH_FAILED = 'UPDATE_SEARCH_FAILED';
/**CREATE */
export const CREATE_SEARCH_SUCCESS = 'CREATE_SEARCH_SUCCESS';
export const CREATE_SEARCH_REQUEST = 'CREATE_SEARCH_REQUEST';
export const CREATE_SEARCH_FAILED = 'CREATE_SEARCH_FAILED';



