import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createProfessionnalFailed,
  createProfessionnalSuccess,
  deleteProfessionnalFailed,
  deleteProfessionnalSuccess,
  getProfessionnalFailed,
  getProfessionnalStatsFailed,
  getProfessionnalStatsSuccess,
  getProfessionnalSuccess,
  updateProfessionnalFailed,
  updateProfessionnalSuccess,
} from "./actions"
import {
  PROFESSIONNAL_MESSAGES_RESET,
  CREATE_PROFESSIONNAL_REQUEST,
  DELETE_PROFESSIONNAL_REQUEST,
  GET_PROFESSIONNAL_BY_NAME_REQUEST,
  GET_PROFESSIONNAL_REQUEST,
  RESET_PROFESSIONNAL_MESSAGES,
  UPDATE_PROFESSIONNAL_REQUEST,
  GET_PROFESSIONNAL_STATS_REQUEST
} from "./actionTypes";



import { getProfessionnal, getProfessionnalStats, postProfessionnal, putProfessionnal } from "helpers/api_helper";



function* getProfessionnalSaga({payload}) {
  try {
    const response = yield getProfessionnal(payload || {page: 1, limit: 1000});
    yield put(getProfessionnalSuccess(response))
  } catch (e) {
    yield put(getProfessionnalFailed(e))
  }
}

function* getProfessionnalStatsSaga() {
  try {
    const response = yield getProfessionnalStats();
    yield put(getProfessionnalStatsSuccess(response))
  } catch (e) {
    yield put(getProfessionnalStatsFailed(e))
  }
}

function* getProfessionnalBynameSaga() {

}


function* createProfessionnalSaga({ payload: professionnal }) {
  try {
    const data = yield postProfessionnal(professionnal);
    // console.log("dataa",data)
    const response = yield getProfessionnal({limit: 20, page: 1});
    // console.log("response",response)
    yield put(getProfessionnalSuccess(response))

  } catch (e) {
    yield put(createProfessionnalFailed(e))
  }
}

function* deleteProfessionnalSaga( payload ) {
  try {
    const result = yield delProfessionnal(payload.payload.id)
    const data = yield getProfessionnal({ current: 1, limit: 20 })
    yield put(getProfessionnalSuccess(data))
  } catch (e) {
    yield put(deleteProfessionnalFailed(e))
  }
}

function* updateProfessionnalSaga({ payload: professionnal }) {
  try {
    // separer l id & objet Professionnal
    const { id, ...updatedProfessionnal } = professionnal

    const data = yield putProfessionnal(id, updatedProfessionnal);
    const response = yield getProfessionnal({limit: 20, page: 1});
    yield put(getProfessionnalSuccess(response))
  } catch (e) {
    yield put(updateProfessionnalFailed(e))
  }
}

function* resetProfessionnalMessagesSaga() {
  yield put(resetProfessionnalMessagesSuccess())
}




export default function* professionnalSaga() {
  yield all([
    takeEvery(GET_PROFESSIONNAL_REQUEST, getProfessionnalSaga),
    takeEvery(GET_PROFESSIONNAL_STATS_REQUEST, getProfessionnalStatsSaga),
    takeEvery(GET_PROFESSIONNAL_BY_NAME_REQUEST, getProfessionnalBynameSaga),
    takeEvery(CREATE_PROFESSIONNAL_REQUEST, createProfessionnalSaga),
    takeEvery(DELETE_PROFESSIONNAL_REQUEST, deleteProfessionnalSaga),
    takeEvery(UPDATE_PROFESSIONNAL_REQUEST, updateProfessionnalSaga),
    takeEvery(RESET_PROFESSIONNAL_MESSAGES, resetProfessionnalMessagesSaga),
  ])
}
