import React, { useEffect } from "react"
import PropTypes from "prop-types"

import "bootstrap/dist/css/bootstrap.min.css"

//redux

import { withTranslation } from "react-i18next"

import MMPTable from "./MMPTable"
import KCOMTable from "./KCOMTable"
import NetworkCom from "./NetworkCom"
import BcomTable from "./BcomTable"
import { useDispatch, useSelector } from "react-redux"
import { getConfigs } from "store/actions"
import { Card, CardBody } from "reactstrap" 
import Referral from "./Referral"
import RRCOM from "./RRCOM"
import MMS from "./MMS"


function Setting(props) {
  //meta title
  document.title = "Adresstreet"


  const dispatch = useDispatch()
  const { configs } = useSelector(state => state.settings)
  useEffect(() => {
    dispatch(getConfigs())
  }, [])



  //delete order



  return (
    <React.Fragment>
      <div style={{marginTop: "6rem"}} className="container-fluid">
        <Card>
          <CardBody>
            <MMPTable />
            <KCOMTable />
            <BcomTable />
            <NetworkCom />
            <RRCOM/>
            <MMS/>
            <Referral />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
Setting.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Setting)
