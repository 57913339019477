import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../../components/Common/DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../components/Common/TableContainer";

import {
  deleteMDMProfession,
  getMDMProfession,
  updateMDMDepartment,
  updateMDMProfession,
} from "store/actions";
import SuccessModal from "components/Common/SuccessModal";
import { withTranslation } from "react-i18next";
import ProfessionModal from "./ProfessionModal";
import moment from "moment";
import ConfirmModalMDMProfession from "components/Common/ConfirmModalMDMProfession";


const Profession = (props) => {
  const {
    professionData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading,
  } = useSelector((state) => state.professions);

  //meta title
  document.title = "Adresstreet";
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    professionId:"",
    page: 1,
    limit: 100,
  });
  const [modal, setModal] = useState(false);
  const [professionList, setProfessionList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [profession, setProfession] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [message, setMessage] = useState("");

  const handleprofessionClick = (arg) => {
    const profession = arg;

    setProfession({
      ...profession,
    });
    setIsEdit(true);
    toggle();
  };

  // Department Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Id"),
        accessor: "id",
        disableFilters: true,
      
      },
      
      {
        Header: props.t("Nom"),
        accessor: "name",
        disableFilters: true,
        
      },
      {
        Header: props.t("Département"),
        accessor: "delegation",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.parent?.name || "Non fourni"}</>
        },
      },

      {
        Header: props.t("Created At"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm")}
            </>
          )
        },
      },
      {
        Header: props.t("Action"),
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
             
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const countryData = cellProps.row.original;
                  // console.log("idProf",countryData)
                  handleprofessionClick(countryData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const countryData = cellProps.row.original;
                  onClickDelete(countryData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setProfession(null);
    } else {
      setModal(true);
    }
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const onClickDelete = (profession) => {
    setProfession(profession);
    setDeleteModal(true);
  };

  const handleDeleteProfession = () => {
   if (profession.id) {
      dispatch(updateMDMProfession({ id: profession.id, status: "archived"  }));
      // dispatch(updateMDMDepartment({ id: profession?.parentId, status: "archived" }))
      setDeleteModal(false);
      dispatch(getMDMProfession());
    }
  };

  useEffect(() => {
    
    if (success === "CREATE") {
      setSuccessModal(true);
      setMessage("localité created successfully !");
      dispatch(resetCountryMessages());
    } else if (success == "UPDATE") {
      setSuccessModal(true);
      setMessage("Successfully !");
      dispatch(resetCountryMessages());
    }else if (error == "ERROR_PROFESSION") {
      setErrorModal(true);
      
    }
  }, [success]);

  useEffect(() => {
    {
      dispatch(getMDMProfession(filter));
    }
  }, [dispatch, filter]);

  useEffect(() => {
    setProfessionList(professionData);
  }, [professionData]);

  useEffect(() => {
    if (!isEmpty(professionData)) {
      setProfessionList(professionData);
    }
  }, [professionData]);

  const handleProfessionClicks = () => {
    setProfessionList("");
    setIsEdit(false);
    toggle();
  };

  const setPageNumber = (activePage) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, page: activePage };
    });
  };
  const handleLimit = (limit) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, limit: limit };
    });
  };

  return (
    <React.Fragment>
      <ConfirmModalMDMProfession
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProfession}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("MDM")}
            breadcrumbItem={props.t("Listes des professions")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={professionData}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleOrderClicks={handleProfessionClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                  <ProfessionModal modal={modal} toggle={toggle} isEdit={isEdit} profession={profession} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Profession);
