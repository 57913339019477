
import {
    LOCALITY_MESSAGES_RESET,
    CREATE_LOCALITY_FAILED,
    CREATE_LOCALITY_REQUEST,
    CREATE_LOCALITY_SUCCESS,
    DELETE_LOCALITY_FAILED,
    DELETE_LOCALITY_REQUEST,
    DELETE_LOCALITY_SUCCESS,
    GET_LOCALITY_BY_NAME_FAILED,
    GET_LOCALITY_BY_NAME_REQUEST,
    GET_LOCALITY_BY_NAME_SUCCESS,
    GET_LOCALITY_FAILED,
    GET_LOCALITY_REQUEST,
    GET_LOCALITY_SUCCESS,
    UPDATE_LOCALITY_FAILED,
    UPDATE_LOCALITY_REQUEST,
    RESET_LOCALITY_MESSAGES,
    RESET_LOCALITY_MESSAGES_SUCCESS,
    UPDATE_LOCALITY_SUCCESS
} from "./actionTypes.js"

export const getLocalityByName = name => ({
    type: GET_LOCALITY_BY_NAME_REQUEST,
    payload: name
})


export const getLocalityByNameSuccess = payload => ({
    type: GET_LOCALITY_BY_NAME_SUCCESS,
    payload
})


export const getLocalityByNameFailed = payload => ({
    type: GET_LOCALITY_BY_NAME_FAILED,
    payload
})




export const getMDMLocality = (filter) => ({
    type: GET_LOCALITY_REQUEST,
    payload:filter
})



export const getMDMLocalitySuccess = locality => ({
    type: GET_LOCALITY_SUCCESS,
    payload: locality
})

export const getMDMLocalityFailed = payload => ({
    type: GET_LOCALITY_FAILED,
    payload
})

export const createMDMLocality = Locality => ({
    type: CREATE_LOCALITY_REQUEST,
    payload: Locality
})

export const createMDMLocalitySuccess = Locality => ({
    type: CREATE_LOCALITY_SUCCESS,
    payload: Locality
})

export const createMDMLocalityFailed = payload => ({
    type: CREATE_LOCALITY_FAILED,
    payload
})

export const deleteMDMLocality= id => ({
    type: DELETE_LOCALITY_REQUEST,
    payload: id
})

export const deleteMDMLocalitySuccess = id => ({
    type: DELETE_LOCALITY_SUCCESS,
    payload: id
})

export const deleteMDMLocalityFailed = id => ({
    type: DELETE_LOCALITY_FAILED,
    payload: id
})

export const updateMDMLocality = (Locality )=> ({
    type: UPDATE_LOCALITY_REQUEST,
    payload: Locality
})

export const updateMDMLocalitySuccess = (Locality ) => ({
    type: UPDATE_LOCALITY_SUCCESS,
    payload: Locality
})

export const updateMDMLocalityFailed = payload => ({
    type: UPDATE_LOCALITY_FAILED,
    payload
})

export const resetMDMLocalityMessages = () => ({
    type: LOCALITY_MESSAGES_RESET
}
)


export const resetLocalityMessages = () => ({
    type: RESET_LOCALITY_MESSAGES,
  })
  
  export const resetLocalityMessagesSuccess = () => ({
    type: RESET_LOCALITY_MESSAGES_SUCCESS,
  })

