import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { isEmpty, map } from "lodash";
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";
import SuccessModal from "components/Common/SuccessModal";
import { withTranslation } from "react-i18next";
import PublicityModal from "./PublicityModal";
import { deleteMDMAds, getMDMAds, getMDMDepartment, getProfessionnal, getUsers } from "store/actions";
import moment from "moment";

const Publicity = (props) => {
  const {
    adsData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading,
  } = useSelector((state) => state.ads);
  //meta title
  document.title = "Adresstreet";
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    addedById: "",
    departmentId: "",
    contentType: "",
    status: "",
    numberOfDay: "",
    end_date:"",
    from: "",
    to: "",
    type: "",
    page: 1,
    limit: 100,
  });
  const [modal, setModal] = useState(false);
  const [publicityList, setPublicityList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [publicity, setPublicity] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const {departmentData} = useSelector(state => state.departments)
  const { users } = useSelector(state => state.users);
  const history = useHistory();


  useEffect(() => {
    {
    
      dispatch(getUsers(filter))
    }
  }, [dispatch, filter])

 
  const handlepublicityClick = (arg) => {
    const publicity = arg;
    setPublicity({
      ...publicity,
    });

    setIsEdit(true);
    toggle();
  };


  const columns = useMemo(
    () => [
      {
        Header: props.t("Id"),
        accessor: "id",
        disableFilters: true,
      },
      {
        Header: props.t("Nom"),
        accessor: "name",
        disableFilters: true,
        
      },
      {
        Header: props.t("Date"),
        //accessor: "start_date",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              {(cellProps.start_date && cellProps.end_date) ? (moment(new Date(cellProps.start_date)).format('DD-MM-YYYY') + " / " + moment(new Date(cellProps.end_date)).format('DD-MM-YYYY')) : props.t('Non fourni')}

            </>
          );
        },
      },
      {
        Header: props.t("Nombre de jours restant"),
        //accessor: "end_date",
        disableFilters: true,
        accessor: (cellProps) => {
          
          return <>
            {new Date() - new Date(cellProps.end_date) < 0 ? moment(new Date(cellProps.end_date)).diff(moment(new Date()), "days") + " " + "Jours" : moment(new Date(cellProps.end_date)).fromNow()}
          </>
        },
      },
     
      {
        Header: props.t("Départements"),
        accessor: "departments",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              {(
                cellProps.departments?.map(el => ( el.name + " /" ))||
                props.t("Non fourni"))}
            </>
          );
        },
      },
      {
        Header: props.t("Type"),
        accessor: "type",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>

              {cellProps.type || props.t("Non fourni")}
            </>
          );
        },
      },
    
      {
        Header: props.t("Réalisée par"),
        accessor: "addedBy",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              
              {(cellProps.addedBy &&
                cellProps.addedBy?.firstname +
                " " +
                cellProps.addedBy?.lastname) ||
                props.t("Non fourni")}
            </>
          );
        },
      },
      {
        Header: props.t("Statut"),
        accessor: "status",
        disableFilters: true,
        accessor: cellProps => {
           return <>
           {props.t(cellProps.status)}
          </>
        },
      },
      {
        Header: props.t("Action"),
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const countryData = cellProps.row.original;
                  handlepublicityClick(countryData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const adsData = cellProps.row.original;
                  onClickDelete(adsData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setPublicity(null);
    } else {
      setModal(true);
    }
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (publicity) => {
    setPublicity(publicity);
    setDeleteModal(true);
  };

  const handleDeletePublicity = () => {
    if (publicity.id) {
      dispatch(deleteMDMAds({ id: publicity.id }));
      setDeleteModal(false);
      dispatch(getMDMAds());
    }
  };

  useEffect(() => {
    if (success === "CREATE") {
      setSuccessModal(true);
      setMessage("created successfully !");
      dispatch(resetCountryMessages());
    } else if (success == "UPDATE") {
      setSuccessModal(true);
      setMessage("Successfully !");
      dispatch(resetCountryMessages());
    }
  }, [success]);

  useEffect(() => {
    {
      dispatch(getMDMAds(filter));
      dispatch(getProfessionnal());
      dispatch(getMDMDepartment())
    }
  }, [dispatch, filter]);

  useEffect(() => {
    setPublicityList(adsData);
  }, [adsData]);

  useEffect(() => {
    if (!isEmpty(adsData)) {
      setPublicityList(adsData);
    }
  }, [adsData]);

  const handlePublicityClicks = () => {
    setPublicityList("");
    setIsEdit(false);
    toggle();
  };
// comment
  const handleDepartment = (department) => { setFilter(prevFilter => { return { ...prevFilter, departmentId: department.value } }) }
  const handleTypePublicity = (typePub) => { setFilter(prevFilter => { return { ...prevFilter, type: typePub.value } }) }
  const handleStatus = (statusPub) => { setFilter(prevFilter => { return { ...prevFilter, status: statusPub.value } }) }
  const handleUser = (user) => { setFilter(prevFilter => { return { ...prevFilter, addedById: user.value } }) }
  const handleEndDate = (numberOfDay) => { 
    if(numberOfDay)   
     {setFilter(prevFilter => { return {
     ...prevFilter, end_date: moment(new Date(), "Y-MM-DD").add(parseInt(numberOfDay) + 1, 'days').format("YYYY-MM-DD")  } }) }
    else  if (!numberOfDay)
    dispatch(getMDMAds())
    }

  const setPageNumber = (activePage) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, page: activePage };
    });
  };
  const handleLimit = (limit) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, limit: limit };
    });
  };
  const handleDateFromTo = date => {
    if (!date) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: "",
          to: "",
        }
      })
    }
    else if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }
 




  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePublicity}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Publicité")}
            breadcrumbItem={props.t("Liste des publicités")}
          />

       
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={adsData}
                    resetOption={true}
                    isGlobalFilter={true}
                    isExcelAds={true}
                    isAddProfessionOptions={true}
                    DepartmentFilter={true}
                    PublicitylFilter={true}
                    StatusPublicityFilter={true}
                    setStatusPublicity={handleStatus}
                    setTypePub={handleTypePublicity}
                    filterNumberOfDate={true}
                    isUsersFilter={true}
                    setUser={handleUser}
                    filterDate={true}
                    setDepartment={handleDepartment}
                    departements={departmentData}
                    setDateFilter={handleDateFromTo}
                    setEndDateFilter={handleEndDate}
                    isAddOptions={true}
                    handleOrderClicks={handlePublicityClicks}
                    handelAddClick={handlePublicityClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    users={users}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                  <PublicityModal
                    modal={modal}
                    toggle={toggle}
                    isEdit={isEdit}
                    Publicity={publicity}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Publicity);
