import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"

import {
  getMDMBanners,
  resetCountryMessages,
  updateMDMCountry,
} from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"
import BannersModal from "./BannersModal"
import moment from "moment"


const Banners = props => {
  const {
    bannersData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading
  } = useSelector(state => state.banners)
  

  //meta title
  document.title = "Adresstreet"
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  const [modal, setModal] = useState(false)
  const [bannerList, setBannersList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [banner, setBanner] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")



  const handlebannerClick = arg => {
    const banner = arg

    setBanner({
      ...banner,
    })

    setIsEdit(true)
    toggle()
  }

  // Department Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Id"),
        accessor: "id",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <EnName {...cellProps} />
        // },
      },
      // {
      //   Header: props.t("Photo"),
      //   accessor: "photo",
      //   disableFilters: true,
      //   // Cell: cellProps => {
      //   //   return <UserName {...cellProps} />
      //   // },
      // },
      {
        Header: props.t("Nom"),
        accessor: "name",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <ArabicName {...cellProps} />
        // },
      },
      {
        Header: props.t("crèè le"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm")}
            </>
          )
        },
      },
 
      {
        Header: props.t("mise à jour le"),
        accessor: "updatedAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm")}
            </>
          )
        },
      },
      // {
      //   Header: props.t("Publié le"),
      //   accessor: "updatedDate",
      //   disableFilters: true,
      //   // Cell: cellProps => {
      //   //   return <ArabicName {...cellProps} />
      //   // },
      // },
      {
        Header: props.t("Etat"),
        accessor: "status",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <ArabicName {...cellProps} />
        // },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* {checkPermission(userRoles, ["MasterData", "Country", "Update"]) && */}
                <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const bannersData = cellProps.row.original
                    handlebannerClick(bannersData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              {/* {checkPermission(userRoles, ["MasterData", "Country", "Delete"]) && */}
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const countryData = cellProps.row.original
                    onClickDelete(countryData)
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setLocality(null)
    } else {
      setModal(true)
    }
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = locality => {
    setLocality(locality)
    setDeleteModal(true)
  }

  const handleDeleteBanners = () => {
    if (country.id) {
      dispatch(updateMDMCountry({ id: country.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getMDMBanners())
    }
  }

  useEffect(() => {

    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("localité created successfully !")
      dispatch(resetCountryMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Successfully !")
      dispatch(resetCountryMessages())
    }
  }, [success])

  useEffect(() => {
    {
      dispatch(getMDMBanners(filter))
      // console.log("country",bannersData)

    }
  }, [dispatch, filter])

  useEffect(() => {
    setBannersList(bannersData)
  }, [bannersData])

  useEffect(() => {
    if (!isEmpty(bannersData)) {
        setBannersList(bannersData)
    }
  }, [bannersData])

  const handleBannersClicks = () => {
    setBannersList("")
    setIsEdit(false)
    toggle()
  }

  const setPageNumber = (activePage) => { setFilter(prevFilter => { return { ...prevFilter, page: activePage } }) }
  const handleLimit = (limit) => { setFilter(prevFilter => { return { ...prevFilter, limit: limit } }) }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBanners}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("MDM")} breadcrumbItem={props.t("Liste des bannières")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={bannersData}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleOrderClicks={handleBannersClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
               <BannersModal modal={modal} toggle={toggle} isEdit={isEdit} Banners={banner} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Banners)
