import React, { useEffect, Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import CreatableSelect from "react-select/creatable";
import ReactSelect from "react-select";
import { Table, Row, Col, Button, Input, Spinner } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { withTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { DateRangePicker, defaultStaticRanges,defaultInputRanges } from "react-date-range";

import { fr } from 'date-fns/locale';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import format from "date-fns/format";
import { addDays } from "date-fns";
import { setDefaultLocale } from "react-datepicker";
setDefaultLocale(fr)
import ReactToPrint from "react-to-print";
import { downloadExcel } from "react-export-table-to-excel";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";

//** */

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  isAddRetailerOptions,
  isAddPayIn,
  isPrintOption,
  isExcelService,
  isExcelResult,
  isExcelSeeker,
  isExcelAds,
  isExcelOption,
  isAddOptionsEdit,
  isAddUserList,
  filterDate,
  filterNumberOfDate,
  ContenuFilter,
  PublicitylFilter,
  isMoyenneEvaluationFilter,
  handleOrderClicks,
  handleUserClick,
  handelAddClick,
  isAddCustList,
  isAddRoleList,
  customPageSize,
  className,
  customPageSizeOptions,
  setPageNumber,
  nextPage,
  previousPage,
  currentPage,
  total,
  limit,
  t,
  loading,
  success,
  setFilter,
  partners,
  retailers,
  delegations,
  gouvernorats,
  localities,
  profession,
  professionnals,
  departements,
  seekers,
  users,
  setUser,
  isUsersFilter,
  setPartner,
  setRetailer,
  setProfession,
  setSeeker,
  setLocality,
  setDelegation,
  setGouvernorate,
  setProfessionnal,
  setDepartment,
  setAverge,
  setAvgCr,
  setAvgCr1,
  setAvgCr2,
  setAvgCr3,
  setAvgCr4,
  setAvgCr5,
  AvergeFilter,
  setCr,
  setCr1,
  setCr2,
  setCr3,
  setCr4,
  setCr5,
  isPartnersFilter,
  isRetailersFilter,
  isRetailerTransactionFilter,
  commissions,
  isCommissionFilter,
  ResetCommissionFilter,
  ActionReleaseReject,
  ActionPay,
  setCommission,
  setTypePub,
  setContentType,
  setStatus,
  setStatusResult,
  setStatusRequest,
  setStatusPublicity,
  setLimit,
  commissionTypeFilter,
  setDateFilter,
  setEndDateFilter,
  categoryFilter,
  ProfessionFilter,
  SeekerFilter,
  GouvernorateFilter,
  LocalityFilter,
  LocalitiesFilter,
  DelegationFilter,
  ProfessionnelFilter,
  DepartmentFilter,
  EvaluationAvgCrFilter,
  EvaluationAvgCr1Filter,
  EvaluationAvgCr2Filter,
  EvaluationAvgCr3Filter,
  EvaluationAvgCr4Filter,
  EvaluationAvgCr5Filter,
  EvaluationCrFilter,
  EvaluationCr1Filter,
  EvaluationCr2Filter,
  EvaluationCr3Filter,
  EvaluationCr4Filter,
  EvaluationCr5Filter,
  StatusFilter,
  StatusRequestFilter,
  StatusResultsFilter,
  StatusPublicityFilter,
  AdresseFilter,
  setCategory,
  setShopname,
  setHiddenColumn,
  categories,
  brandFilter,
  setBrand,
  brands,
  cityFilter,
  setCity,
  cities,
  resetOption,
  resetSearchOption,
  resetSeekersOption,
  resetOptionRequest,
  resetOptionEvaluation,
  resetOptionProfessionnal,
  resetOptionSeekers,
  resetTransactionIncomeOption,
  resetBPStatsOption,
  resetPartnerTransactionOption,
  resetCommissionInOption,
  resetRetailerStatsOption,
  handleCloseResults,
  closedResults
}) => {
  const [columnsData, setColumnsData] = useState(columns || []);
  const [filteredData, setFilteredData] = useState(data || [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columnsData,
      data: filteredData,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  const history = useHistory();
  const userRef = useRef();
  
  const [retailerValue, setRetailerValue] = useState("");
  const [partnerValue, setPartnerValue] = useState("");
  const [userValue, setUserValue] = useState("");
  const [professionValue, setProfessionValue] = useState("");
  const [seekerValue, setSeekerValue] = useState("");
  const [gouvernorateValue, setGouvernorateValue] = useState("");
  const [delegationValue, setDelegationValue] = useState("");
  const [localityValue, setLocalityValue] = useState("");
  const [professionnalValue, setProfessionnalValue] = useState("");
  const [departmentValue, setDepartmentValue] = useState("");

  const commissionRef = useRef();
  const NumberDayAdsRef = useRef();
  const [categoryValue, setCategoryValue] = useState("");
  const [shopnameValue, setShopnameValue] = useState("");
  const [typePubValue, setTypePubValue] = useState("");
  const [contentTypeValue, setContentTypeValue] = useState("");
  const [numberDayValue, setNumberDayValue] = useState(null);
  const [avgCrValue, setAvgCrValue] = useState("");
  const [avgCr1Value, setAvgCr1Value] = useState("");
  const [avgCr2Value, setAvgCr2Value] = useState("");
  const [avgCr3Value, setAvgCr3Value] = useState("");
  const [avgCr4Value, setAvgCr4Value] = useState("");
  const [avgCr5Value, setAvgCr5Value] = useState("");
  const [avgValue, setAvgValue] = useState("");
  const [CrValue, setCrValue] = useState("");
  const [Cr1Value, setCr1Value] = useState("");
  const [Cr2Value, setCr2Value] = useState("");
  const [Cr3Value, setCr3Value] = useState("");
  const [Cr4Value, setCr4Value] = useState("");
  const [Cr5Value, setCr5Value] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [statusRequestValue, setStatusRequestValue] = useState("");
  const [statusResultsValue, setStatusResultsValue] = useState("");
  const [statusPublicityValue, setStatusPublicityValue] = useState("");
  const [brandValue, setBrandValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [name, setName] = useState("");
  const componentRef = useRef();
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE");


  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setLimit(event.target.value);
  };

  const onChangePageSize = (page) => {
    setPageNumber(page);
    //const page = event.target.value ? Number(event.target.value) - 1 : 0;
    // gotoPage(page);
  };


 
  const debounced = useDebouncedCallback(
    // function
    (value) => {
      if (!value) setFilteredData(data)
      else {
        const filteredArray = data.filter(el => el.firstname?.includes(value) || el.lastname?.includes(value) || el.email?.includes(value) || el.name?.includes(value) || el.professional?.firstname.includes(value) || el.professional?.lastname.includes(value) || el.name?.includes(value) || el.seeker?.firstname.includes(value) || el.seeker?.lastname.includes(value)
        )
        setFilteredData(filteredArray)
      }
    },
    // delay in ms
    500
  );
  const statusPub = useDebouncedCallback(
    // function
    (value) => {
      if (!value) setFilteredData(data)
      else {
        const filteredArray = data.filter(el => el.status?.includes(value) == "ouverte")
        setFilteredData(filteredArray)
      }
    },
    // delay in ms
    500
  );

  const statusAdFilterHandler = (item) => {
    setFilteredData(data.filter(el => el.status === item.value))
  }

  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [initialRange, setInitialRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);


  const staticRangesLabels = {
    "Today": "Aujourd'hui",
    "Yesterday": "Hier",
    "This Week": "Cette semaine",
    "Last Week": "La semaine dernière",
    "This Month": "Ce mois",
    "Last Month": "Le mois dernier"
  };
  const inputRangesLabels = {
    "days up to today": "Jours jusqu'à aujourd'hui",
    "days starting today": "Jours à partir d'aujourd'hui"
  };
  
  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
  }
  
  const frStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
  const frInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));
  

  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);
  useEffect(() => {

    setFilteredData(data)
  }, [data]);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };
  // Hide dropdown on outside click //
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false)
    }
  };
  const handleDownloadExcelProfessionnal = () => {
    const header = [
      "id",
      "Nom et Prénom",
      "Télephone",
      "adresse",
      "profession",
      "evaluation",
      "Ajouté par",
      "Date d'inscription",
      "Statut",
    ];
    let tableBody = professionnals.map((professionnal) => {
      return {
        id: professionnal.id,
        fullName: professionnal.firstname + " " + professionnal.lastname,
        Télephone: professionnal.phone || "-----",

        adresse: professionnal?.governorate?.name + " " + professionnal?.delegation?.name + " " + professionnal?.locality?.name,

        profession: professionnal?.profession?.name || "-----",
        evaluation: professionnal.average || "-----",
        Ajouté:
          (professionnal.addedBy &&
            professionnal.addedBy?.firstname +
            " " +
            professionnal.addedBy?.lastname) ||
          "-----",
        createdAt:
          moment(professionnal.createdAt).format("YYYY-MM-DD HH:mm") || "-----",
        status: professionnal.status || "-----",
      };
    });
    downloadExcel({
      fileName: "Professionnels",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: tableBody || [],
      },
    });
  };
  const handleDownloadExcelAds = () => {
    const header = [
      "Nom",
      "date",
      "Départements",
      "Type",
      "Ajouté par",
      "Statut",
    ];
    let tableBody = data.map((partner) => {
      return {
        Nom: partner.name,

        createdAt:
          (partner.start_date && partner.end_date) ? (moment(new Date(partner.start_date)).format('DD-MM-YYYY') + " / " + moment(new Date(partner.end_date)).format('DD-MM-YYYY')) : props.t('Not provided'),
          departments:
          (partner.departments?.map(el => (el.name  )).join("/") ) ,
        type:
          (partner.type) || "-----",
        Ajouté:
          (partner.addedBy &&
            partner.addedBy?.firstname +
            " " +
            partner.addedBy?.lastname) ||
          "-----",
        status: partner.status || "-----",
      };
    });

    downloadExcel({
      fileName: "Publicités",
      sheet: "export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: tableBody || [],
      },
    });
  };
  const handleDownloadExcelSeeker = () => {
    const header = [
      "Nom et Prénom",
      "Télephone",
      "Adresse",
      "Date d'inscription",
      "statut",

    ];
    let tableBody = data.map((seeker) => {
      return {
        Nom: seeker.firstname + " " + seeker.lastname,
        phone: seeker.phone,
        adresse: seeker?.governorate?.name + " " + seeker?.delegation?.name + " " + seeker?.locality?.name,
        createdAt:
          moment(seeker.createdAt).format("YYYY-MM-DD HH:mm") || "-----",
        status: seeker.status || "-----",
      };
    });

    downloadExcel({
      fileName: "Chercheurs",
      sheet: "export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: tableBody || [],
      },
    });
  };
  const handleDownloadExcelService = () => {
    const header = [
      "date",
      "Chercheur",
      "profession",
      "Adresse",
      "statut",
      "Réalisée par",
    ];
    let tableBody = data.map((service) => {
      return {
        createdAt:
          moment(service.createdAt).format("YYYY-MM-DD HH:mm") || "-----",
        seeker: service.seeker?.firstname + " " + service.seeker?.lastname,
        profession: service.profession?.name,
        adresse: service?.governorate?.name + " " + service?.delegation?.name + " " + service?.locality?.name,
        status: service.status || "-----",
        Ajouté:
          (service.admin &&
            service.admin?.firstname +
            " " +
            service.admin?.lastname) ||
          "-----",
      };
    });

    downloadExcel({
      fileName: "Demandes service Vip",
      sheet: "export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: tableBody || [],
      },
    });
  };
  const handleDownloadExcelResult = () => {
    const header = [
      "id",
      "Chercheur",
      "Professionel",
      "profession",
      "adresse",
      "statut",
    ];
    let tableBody = data.map((result) => {
      return {
        id: result.id,
        chercheur: result.seeker?.firstname + " " + result.seeker?.lastname,
        Professionel: result.professional?.firstname + " " + result.professional?.lastname,
        profession: result.professional?.profession?.name || "-----",
        adresse: result.professional?.governorate?.name + " " + result.professional?.delegation?.name + " " + result.professional?.locality?.name,
        status: result.status || "-----",
      };
    });

    downloadExcel({
      fileName: "Résultats de recherches",
      sheet: "export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: tableBody || [],
      },
    });
  };

  return (
    <Fragment>
      <Row className="mb-2">
        {isGlobalFilter && (
          <Col xl="2">
            <div className="search-box me-2 mb-2 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <Input
                    onChange={(e) => {
                      // setValue(e.target.value);
                      // setName(e.target.value)
                      // onChange(e.target.value);
                      debounced(e.target.value);
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control mb-2 me-2"
                    placeholder={`${data.length} ${t("Search")}...`}
                  //value={value || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon  mb-2 me-2"></i>
              </div>
            </div>
          </Col>
        )}
        {ActionPay && (
          <Col sm="1">
            <div>
              <Button
                type="button"
                color="success"
                className="btn  mb-2 me-2"
                onClick={changeStatusPaid}
              >
                {t("Pay")}
              </Button>
            </div>
          </Col>
        )}
        {categoryFilter && (
          <Col xl="2">
            <CreatableSelect
              className="mb-2 me-2"
              value={categoryValue}
              onChange={(value) => {
                setCategoryValue(value);
                setCategory(value);
              }}
              placeholder={t("select category...")}
              options={categories.map((category) => {
                return {
                  value: category.id,
                  label:
                    (currentLanguage == "ar" && category.nameAr) ||
                    (currentLanguage == "en" && category.nameEn) ||
                    (currentLanguage == "fr" && category.nameFr),
                };
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}
        {brandFilter && (
          <Col xl="2">
            <CreatableSelect
              className="mb-2 me-2"
              value={brandValue}
              onChange={(value) => {
                setBrandValue(value);
                setBrand(value);
              }}
              placeholder={t("select brand...")}
              options={brands.map((brand) => {
                return {
                  value: brand.id,
                  label:
                    (currentLanguage == "ar" && brand.nameAr) ||
                    (currentLanguage == "en" && brand.nameEn) ||
                    (currentLanguage == "fr" && brand.nameFr),
                };
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}
        {cityFilter && (
          <Col xl="2">
            <CreatableSelect
              className="mb-2 me-2"
              value={cityValue}
              onChange={(value) => {
                setCityValue(value);
                setCity(value);
              }}
              placeholder={t("select city...")}
              options={cities.map((city) => {
                return {
                  value: city.id,
                  label:
                    (currentLanguage == "ar" && city.nameAr) ||
                    (currentLanguage == "en" && city.nameEn) ||
                    (currentLanguage == "fr" && city.nameFr),
                };
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}

        {filterDate && (
          <>
            <Col xl="3">
              <input
                value={`${moment(range[0].startDate).format("DD-MM-YYYY")}  ->  ${moment(
                  range[0].endDate
                ).format("DD-MM-YYYY")}`}
                readOnly
                className="form-control external-event mb-2 me-2"
                onClick={() => setOpen(!open)}
              />
              <div ref={refOne}>
              {open  && (
                <DateRangePicker
                  onChange={(item) => {
                    setRange([item.selection]);
                    setDateFilter(item.selection);}
                    
                }
                // staticRanges={[
                //   ...defaultStaticRanges,
                //   {
                //     label: "last year",
                //     range: () => ({
                //       startDate: startOfYear(addYears(new Date(), -1)),
                //       endDate: endOfYear(addYears(new Date(), -1))
                //     }),
                //     isSelected(range) {
                //       const definedRange = this.range();
                //       return (
                //         isSameDay(range.startDate, definedRange.startDate) &&
                //         isSameDay(range.endDate, definedRange.endDate)
                //       );
                //     }
                //   },
                //   {
                //     label: "this year",
                //     range: () => ({
                //       startDate: startOfYear(new Date()),
                //       endDate: endOfDay(new Date())
                //     }),
                //     isSelected(range) {
                //       const definedRange = this.range();
                //       return (
                //         isSameDay(range.startDate, definedRange.startDate) &&
                //         isSameDay(range.endDate, definedRange.endDate)
                //       );
                //     }
                //   }
                // ]}
                staticRanges={frStaticRanges}
                inputRanges={frInputRanges}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  scroll={{ enabled: true }}
                  ranges={range}
                  months={2}
                  direction="horizontal"
                  className="calendarElement mt-3 ReactModal__Overlay position-relative shadow rdrInputRanges"
                  showSelectionPreview={true}
                  rangeColors={['#46b29d', '#f53855']}
                  locale={fr}
                />
              )}
              </div>
            </Col>
          </>
        )}
        {LocalitiesFilter && (
          <Col xl="2">
            <CreatableSelect
              className="mb-2 me-2"
              value={localityValue}
              onChange={(value) => {
                setLocalityValue(value);
                setLocality(value);
              }}
              placeholder={t("Localité")}
              options={localities?.map((locality) => {
                return {
                  value: locality.id,
                  label: locality.name,
                };
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}

        {ActionPay && (
          <Col sm="1">
            <div>
              <Button
                type="button"
                color="success"
                className="btn  mb-2 me-2"
                onClick={changeStatusPaid}
              >
                {t("Pay")}
              </Button>
            </div>
          </Col>
        )}
        {commissionTypeFilter && (
          <Col xl="2">
            <select
              className="form-select mb-3"
              ref={commissionRef}
              onChange={(e) => {
                setCommission(e.target.value);
                //commissionRef.current.value = "Select commission type"
              }}
            >
              <option key="empty" style={{ display: "none" }}>
                {t("Select commission type")}
              </option>
              {["buyerCom", "kasbCom", "levelCommission", "MLCOM"].map(
                (commission) => (
                  <option key={commission} value={commission}>
                    {commission}
                  </option>
                )
              )}
            </select>
          </Col>
        )}
        <Row>
          {isUsersFilter && (
            <Col xl="2">
              <ReactSelect
                className="mb-2 me-2"
                value={userValue}
                onChange={(value) => {
                  setUserValue(value);
                  setUser(value);
                }}
                placeholder={t("Select user")}
                options={users?.map((user) => {
                  return {
                    value: user.id,
                    label: user.firstname + " " + user.lastname,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}

          {isPartnersFilter && (
            <Col xl="2">
              <ReactSelect
                className="mb-2 me-2"
                value={partnerValue}
                onChange={(value) => {
                  setPartnerValue(value);
                  setPartner(value);
                }}
                placeholder={t("select partner...")}
                options={partners.map((partner) => {
                  return {
                    value: partner.id,
                    label: partner.firstname + " " + partner.lastname,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {isRetailerTransactionFilter && (
            <Col xl="2">
              <ReactSelect
                className="mb-2 me-2"
                value={retailerValue}
                onChange={(value) => {
                  setRetailerValue(value);
                  setRetailer(value);
                }}
                placeholder={t("select retailer...")}
                options={retailers?.map((retailer) => {
                  return {
                    value: retailer.id,
                    label: retailer.firstname + " " + retailer.lastname,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}

          {isRetailersFilter && (
            <Col xl="2">
              <ReactSelect
                className="mb-2 me-2"
                value={retailerValue}
                onChange={(value) => {
                  setRetailerValue(value);
                  setRetailer(value);
                }}
                placeholder={t("select retailer...")}
                options={retailers
                  .filter((retailer) => retailer.shopName)
                  ?.map((retailer) => {
                    return {
                      value: retailer.id,
                      label: retailer.firstname + " " + retailer.lastname,
                    };
                  })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {ProfessionnelFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={professionnalValue}
                onChange={(value) => {
                  setProfessionnalValue(value);
                  setProfessionnal(value);
                }}
                placeholder={t("Professionnel")}
                options={professionnals?.map((professionnal) => {
                  return {
                    value: professionnal.id,
                    label:
                      professionnal.firstname + " " + professionnal.lastname,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {DepartmentFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={departmentValue}
                onChange={(value) => {
                  setDepartmentValue(value);
                  setDepartment(value);
                }}
                placeholder={t("Département")}
                options={departements?.map((department) => {
                  return {
                    value: department.id,
                    label:
                      department.name,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {ProfessionFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={professionValue}
                onChange={(value) => {
                  setProfessionValue(value);
                  setProfession(value);
                }}
                placeholder={t("Select profession")}
                options={profession?.map((profession) => {
                  return {
                    value: profession.id,
                    label: profession.name,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {SeekerFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={seekerValue}
                onChange={(value) => {
                  setSeekerValue(value);
                  setSeeker(value);
                }}
                placeholder={t("Chercheurs")}
                options={seekers?.map((seeker) => {
                  return {
                    value: seeker.id,
                    label: seeker.firstname + " " + seeker.lastname,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}

          {GouvernorateFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={gouvernorateValue}
                onChange={(value) => {
                  setGouvernorateValue(value);
                  setGouvernorate(value);
                  setDelegation({ value: "", label: "" });
                  setDelegationValue("");
                  setLocality({ value: "", label: "" });
                  setLocalityValue("");
                }}
                placeholder={t("gouvernorat")}
                options={gouvernorats?.map((gouvernorate) => {
                  return {
                    value: gouvernorate.id,
                    label: gouvernorate.name,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {DelegationFilter && gouvernorateValue && (

            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={delegationValue}
                onChange={(value) => {
                  setDelegationValue(value);
                  setDelegation(value);
                  setLocality({ value: "", label: "" });
                  setLocalityValue("");
                }}
                placeholder={t("délégation")}
                options={
                  delegations.filter((item) => item.parentId == gouvernorateValue.value)

                    ?.map((delegation) => {
                      return {
                        value: delegation.id,
                        label: delegation.name,
                      };
                    })}
                classNamePrefix="select2-selection"
              />
            </Col>

          )
          }

          {LocalityFilter && delegationValue && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={localityValue}
                onChange={(value) => {
                  setLocalityValue(value);
                  setLocality(value);
                }}
                placeholder={t("Localité")}
                options={localities.filter((item) => item.parentId == delegationValue.value)?.map((locality) => {
                  return {
                    value: locality.id,
                    label: locality.name,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {resetOptionSeekers && (
            <Col xl="1">
              <div>
                <Button
                  type="button"
                  color="secondary"
                  className="btn  mb-2 me-2"
                  onClick={() => {
                    setGouvernorate({ value: "", label: "" });
                    setGouvernorateValue("");
                    setDelegation({ value: "", label: "" });
                    setDelegationValue("");
                    setRange(initialRange)
                    setDateFilter(null)
                    setLocality({ value: "", label: "" });
                    setLocalityValue("");
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </Col>
          )}
          {resetSeekersOption && (
            <Col xl="1">
              <div>
                <Button
                  type="button"
                  color="secondary"
                  className="btn  mb-2 me-2"
                  onClick={() => {
                    setGouvernorate({ value: "", label: "" });
                    setGouvernorateValue("");
                    setDelegation({ value: "", label: "" });
                    setDelegationValue("");
                    setLocality({ value: "", label: "" });
                    setLocalityValue("");
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </Col>
          )}
          {StatusRequestFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={statusRequestValue}
                onChange={(value) => {
                  setStatusRequestValue(value);
                  setStatusRequest(value);
                }}
                placeholder={t("Statut")}
                options={[
                  { label: "Réalisée", value: "Réalisée" },
                  { label: "Ouverte", value: "ouverte" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}

          {resetOptionRequest && (
            <Col xl="1">
              <div>
                <Button
                  type="button"
                  color="secondary"
                  className="btn  mb-2 me-2"
                  onClick={() => {

                    setUser({ value: "", label: "" });
                    setUserValue("");
                    setProfession({ value: "", label: "" });
                    setProfessionValue("");
                    setStatusRequest({ value: "", label: "" });
                    setStatusRequestValue("");
                    setRange(initialRange)
                    setDateFilter(null)

                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </Col>
          )}

          {EvaluationCrFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={CrValue}
                onChange={(value) => {
                  setCrValue(value);
                  setCr(value);
                }}
                placeholder={t("critères")}
                options={[
                  { label: "Tarification", value: "critère1" },
                  { label: "Efficacité", value: "critère2" },
                  { label: "Ponctualité", value: "critère3" },
                  { label: "Qualité de travail", value: "critère4" },
                  { label: "Rapidité", value: "critère5" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationCr1Filter && CrValue.value == "critère1" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={Cr1Value}
                onChange={(value) => {
                  setCr1Value(value);
                  setCr1(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" }
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationCr2Filter && CrValue.value == "critère2" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={Cr2Value}
                onChange={(value) => {
                  setCr2Value(value);
                  setCr2(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" }
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationCr3Filter && CrValue.value == "critère3" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={Cr3Value}
                onChange={(value) => {
                  setCr3Value(value);
                  setCr3(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" }
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationCr4Filter && CrValue.value == "critère4" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={Cr4Value}
                onChange={(value) => {
                  setCr4Value(value);
                  setCr4(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" }
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationCr5Filter && CrValue.value == "critère5" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={Cr5Value}
                onChange={(value) => {
                  setCr5Value(value);
                  setCr5(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {resetOptionEvaluation && (
            <Col xl="1">
              <div>
                <Button
                  type="button"
                  color="secondary"
                  className="btn  mb-2 me-2"
                  onClick={() => {
                    setRange(initialRange)
                    setDateFilter(null)
                    setUser({ value: "", label: "" });
                    setUserValue("");
                    setProfessionnal({ value: "", label: "" });
                    setProfessionnalValue("");
                    setCr1({ value: "", label: "" });
                    setCr1Value("");
                    setCr2({ value: "", label: "" });
                    setCr2Value("");
                    setCr3({ value: "", label: "" });
                    setCr3Value("");
                    setCr4({ value: "", label: "" });
                    setCr4Value("");
                    setCr5({ value: "", label: "" });
                    setCr5Value("");

                    setCrValue("");

                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </Col>
          )}
          {PublicitylFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={typePubValue}
                onChange={(value) => {
                  setTypePubValue(value);
                  setTypePub(value);
                }}
                placeholder={t("Type de publicitè")}
                options={[
                  { label: "Slider", value: "Slider" },
                  { label: "Suggestion payante", value: "Suggestion payante" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {ContenuFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={contentTypeValue}
                onChange={(value) => {
                  setContentTypeValue(value);
                  setContentType(value);
                }}
                placeholder={t("Type de contenu")}
                options={[
                  { label: "Image", value: "Image" },
                  { label: "Vidéo", value: "Vidéo" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {StatusPublicityFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-3 me-3"
                value={statusPublicityValue}
                onChange={statusAdFilterHandler}
                placeholder={t("Statut")}
                options={[
                  { label: "en attente", value: "en attente" },
                  { label: "ouverte", value: "ouverte" },
                  { label: "clôturée", value: "clôturée" },
      
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {filterNumberOfDate && (
            <Col xl="2">

              <Input
                type="number"
              value={numberDayValue}
                onChange={(item) => {
                  setEndDateFilter(item.target.value);
                  setNumberDayValue(item.target.value);
                  
                }}
                placeholder=" Nombre jours restant"
              />
            </Col>
          )}



          {AdresseFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={shopnameValue}
                onChange={(value) => {
                  setShopnameValue(value);
                  setShopname(value);
                }}
                placeholder={t("Adresse")}
                // options={retailers
                //   .filter(retailer => retailer.shopName)
                //   ?.map(retailer => {
                //     return {
                //       value: retailer.id,
                //       label: retailer.shopName,
                //     }
                //   })}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {StatusResultsFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={statusResultsValue}
                onChange={(value) => {
                  setStatusResultsValue(value);
                  setStatusResult(value);
                }}
                placeholder={t("Statut")}
                options={[
                  { label: "Réalisée", value: "Réalisée" },
                  { label: "Encours", value: "Encours" },
                  { label: "Clôturée", value: "Clôturée" },
                  { label: "Ouverte", value: "Ouverte" },
                  { label: "Refusée", value: "Refusée" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {StatusFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-3 me-3"
                value={statusValue}
                onChange={(value) => {
                  setStatusValue(value);
                  setStatus(value);
                }}
                placeholder={t("Statut")}
                options={[
                  { label: "active", value: "active" },
                  { label: "inactive", value: "inactive" },

                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {resetOption && (
            <Col xl="1">
              <div>
                <Button
                  type="button"
                  color="secondary"
                  className="btn  mb-2 me-2"
                  onClick={() => {
                    setNumberDayValue("")
                    setEndDateFilter(null)
                    setRange(initialRange)
                    setDateFilter(null)
                    setUser({ value: "", label: "" });
                    setUserValue("");
                    setDepartment({ value: "", label: "" });
                    setDepartmentValue("");
                    setStatusPublicity({ value: "", label: "" });
                    setStatusPublicityValue("");
                    setTypePub({ value: "", label: "" });
                    setTypePubValue("");
                    setContentType({ value: "", label: "" });
                    setContentTypeValue("");


                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </Col>
          )}
          {resetSearchOption && (
            <Col xl="1">
              <div>
                <Button
                  type="button"
                  color="secondary"
                  className="btn  mb-2 me-2"
                  onClick={() => {
                    setStatusResult({ value: "", label: "" });
                    setStatusResultsValue("");
                    setProfessionnal({ value: "", label: "" });
                    setProfessionnalValue("");
                    setSeeker({ value: "", label: "" });
                    setSeekerValue("");
                    setProfession({ value: "", label: "" });
                    setProfessionValue("");
                    setRange(initialRange)
                    setDateFilter(null)

                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </Col>
          )}
          {EvaluationAvgCrFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={avgCrValue}
                onChange={(value) => {
                  setAvgCrValue(value);
                  setAvgCr(value);
                }}
                placeholder={t("critères")}
                options={[
                  { label: "moyenne critère 1", value: "moyennecritère1" },
                  { label: "moyenne critère 2", value: "moyennecritère2" },
                  { label: "moyenne critère 3", value: "moyennecritère3" },
                  { label: "moyenne critère 4", value: "moyennecritère4" },
                  { label: "moyenne critère 5", value: "moyennecritère5" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationAvgCr1Filter && avgCrValue.value == "moyennecritère1" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={avgCr1Value}
                onChange={(value) => {
                  setAvgCr1Value(value);
                  setAvgCr1(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationAvgCr2Filter && avgCrValue.value == "moyennecritère2" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={avgCr2Value}
                onChange={(value) => {
                  setAvgCr2Value(value);
                  setAvgCr2(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationAvgCr3Filter && avgCrValue.value == "moyennecritère3" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={avgCr3Value}
                onChange={(value) => {
                  setAvgCr3Value(value);
                  setAvgCr3(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationAvgCr4Filter && avgCrValue.value == "moyennecritère4" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={avgCr4Value}
                onChange={(value) => {
                  setAvgCr4Value(value);
                  setAvgCr4(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {EvaluationAvgCr5Filter && avgCrValue.value == "moyennecritère5" && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={avgCr5Value}
                onChange={(value) => {
                  setAvgCr5Value(value);
                  setAvgCr5(value);
                }}
                placeholder={t("Niveau")}
                options={[
                  { label: "Expert", value: "5,5" },
                  { label: "Bien", value: "4,4" },
                  { label: "Intermédiaire", value: "3,3" },
                  { label: "Débutant", value: "2,2" },
                  { label: "Médiocre", value: "1,1" },
                ]}
                classNamePrefix="select2-selection"
              />
            </Col>
          )}
          {AvergeFilter && (
            <Col xl="2">
              <CreatableSelect
                className="mb-2 me-2"
                value={avgCrValue}
                onChange={(value) => {
                  setAvgCrValue(value);
                  setAverge(value);
                }}
                placeholder={t("Moyenne")}
                options={[
                  { label: "Expert", value: "4,5" },
                  { label: "Bien", value: "3,3.99" },
                  { label: "Intermédiaire", value: "2,2.99" },
                  { label: "Débutant", value: "1,1.99" },
                  { label: "Médiocre", value: "0,0.99" }
                ]}

                classNamePrefix="select2-selection"
              />
            </Col>
          )}
   

          {resetOptionProfessionnal && (
            <Col xl="1">
              <div>
                <Button
                  type="button"
                  color="secondary"
                  className="btn  mb-2 me-2"
                  onClick={() => {
                    setStatus({ value: "", label: "" });
                    setStatusValue("");
                    setProfession({ value: "", label: "" });
                    setProfessionValue("");
                    setGouvernorate({ value: "", label: "" });
                    setGouvernorateValue("");
                    setDelegation({ value: "", label: "" });
                    setDelegationValue("");
                    setLocality({ value: "", label: "" });
                    setLocalityValue("");
                    setAverge({ value: "", label: "" });
                    setAvgCrValue("");
                    setRange(initialRange)
                    setDateFilter(null)
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </Col>
          )}

        </Row>

        {isMoyenneEvaluationFilter && (
          <Col xl="2">
            <ReactSelect
              className="mb-2 me-2"
              // value={userValue}
              // onChange={value => {
              //   setUserValue(value)
              //   setUser(value)
              // }}
              placeholder={t("Moyenne d'évaluation")}
              // options={users.map(user => {
              //   return {
              //     value: user.id,
              //     label: user.firstname + " " + user.lastname,
              //   }
              // })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}

        <Row>
          {isCommissionFilter && (
            <Col xl="2">
              <select
                className="form-select mb-3 me-3"
                ref={commissionRef}
                onChange={(e) => setCommission(e.target.value)}
              >
                <option key="empty" style={{ display: "none" }}>
                  {t("Select parameter")}
                </option>
                {commissions.map((commission) => (
                  <option key={commission.shorts} value={commission.name}>
                    {commission.shorts}
                  </option>
                ))}
              </select>
            </Col>
          )}
          {ResetCommissionFilter && (
            <Col sm="7">
              <div>
                <Button
                  type="button"
                  color="secondary"
                  className="btn  mb-2 me-2"
                  onClick={() => {
                    setUser({ value: "", label: "" });
                    setUserValue("");
                    setCommission("");
                    commissionRef.current.value = "Select parameter";
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </Col>
          )}
          {ActionReleaseReject && (
            <Col sm="8">
              <div>
                <Button
                  type="button"
                  color="info"
                  className="btn  mb-2 me-2"
                  disabled
                >
                  {t("Release")}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="btn  mb-2 me-2"
                  disabled
                >
                  {t("Reject")}
                </Button>
              </div>
            </Col>
          )}

          {isAddCustList && (
            <Col>
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="primary"
                  className="btn-rounded mb-2 me-2"
                  onClick={handelAddClick}
                >
                  <i className="mdi mdi-plus me-1" />
                  {t("Add")}
                </Button>
              </div>
            </Col>
          )}
        </Row>
        {resetCommissionInOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setRetailer({ value: "", label: "" });
                  setRetailerValue("");
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {resetRetailerStatsOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setRetailer("");
                  setRetailerValue("");
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {resetTransactionIncomeOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setRetailer({ value: "", label: "" });
                  setRetailerValue("");
                  setPartner({ value: "", label: "" });
                  setPartnerValue("");
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {resetBPStatsOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setPartner({ value: "", label: "" });
                  setPartnerValue("");
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {resetPartnerTransactionOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setCommission("");
                  commissionRef.current.value = "Select commission type";
                  setPartner({ value: "", label: "" });
                  setPartnerValue("");
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}

        {isAddOptions && (
          <Col xl="11">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Add")}
              </Button>
            </div>
          </Col>
        )}

        {isAddRetailerOptions && (
          <Col xl="11">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Add")}
              </Button>
            </div>
          </Col>
        )}
        {isPrintOption && (
          <Col>
            <Row>
              <div className="text-sm-end">
                <div className="d-print-none">
                  <div className="float">
                    <ReactToPrint
                      //onBeforeGetContent={() => {setColumnsData([])}}
                      // onAfterPrint={() => setHiddenColumn(false)}
                      trigger={() => (
                        <Button type="button" color="success">
                          <i className="fa fa-print font-size-15" /> Print{" "}
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        )}

        {isExcelOption && (
          <Col xl="">
            <div className="text-sm-end">
              <div className="d-print-none">
                <div className="float">
                  <Button
                    onClick={handleDownloadExcelProfessionnal}
                    type="button"
                    color="secondary"
                  >
                    <i className="fas fa-file-excel font-size-15" /> Excel{" "}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {isExcelAds && (
          <Col xl="">
            <div className="text-sm-end">
              <div className="d-print-none">
                <div className="float">
                  <Button
                    onClick={handleDownloadExcelAds}
                    type="button"
                    color="secondary"
                  >
                    <i className="fas fa-file-excel font-size-15" /> Excel{" "}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}
        {isExcelSeeker && (
          <Col xl="">
            <div className="text-sm-end">
              <div className="d-print-none">
                <div className="float">
                  <Button
                    onClick={handleDownloadExcelSeeker}
                    type="button"
                    color="secondary"
                  >
                    <i className="fas fa-file-excel font-size-15" /> Excel{" "}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}
        {isExcelService && (
          <Col xl="">
            <div className="text-sm-end">
              <div className="d-print-none">
                <div className="float">
                  <Button
                    onClick={handleDownloadExcelService}
                    type="button"
                    color="secondary"
                  >
                    <i className="fas fa-file-excel font-size-15" /> Excel{" "}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}
        {isExcelResult && (
          <Col xl="">
            <div className="text-sm-end">
              <div className="d-print-none d-flex justify-content-end">
              <div className="float mx-2">
                  <Button
                    onClick={handleCloseResults}
                    type="button"
                    color="danger"
                    disabled={closedResults?.length > 0 ? false : true }
                  >
                    <i className="font-size-15" /> Clôturer{" "}
                  </Button>
                </div>
                <div className="float">
                  <Button
                    onClick={handleDownloadExcelResult}
                    type="button"
                    color="secondary"
                  >
                    <i className="fas fa-file-excel font-size-15" /> Excel{" "}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {isAddPayIn && (
          <Col sm="9">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Add")}
              </Button>
            </div>
          </Col>
        )}
        {isAddOptionsEdit && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded  mb-2 me-2"
              // onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Add")}
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                {t("Create new user")}
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table
          bordered
          hover
          {...getTableProps()}
          className={className}
          innerRef={componentRef}
        >
          <thead className="table-light table-nowrap">
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id}>
                    <div className="pt-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {!loading && (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          )}
        </Table>
      </div>
      {!loading && data.length < 1 && (
        <div className="text-center font-size-18">{t("No Data")}</div>
      )}
      {loading && (
        <div className="text-center">
          <Spinner animation="border" role="status" />
        </div>
      )}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1 ">
            <select
              className="form-select mb-3 mt-3"
              //value={limit}
              defaultValue={customPageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 50, 100, 500, 1000].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {t("Show")} {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-md-end justify-content-center align-items-center">
        {/* <Col className="col-md-auto">
          <div className="d-flex gap-1 ">
            <select
              className="form-select mb-13 mt-3"
              //value={limit}
              defaultValue={customPageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 50, 100, 500, 1000].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {t("Show")} {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col> */}
        <Col className="col-md-auto">
          <div className="d-flex gap-1 ">
            <Button
              className=" mb-10 me-10"
              color="primary"
              onClick={() => onChangePageSize(1)}
              disabled={!previousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={() => onChangePageSize(currentPage - 1)}
              disabled={!previousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          {t("Page")}{" "}
          <strong>
            {currentPage} {t("Of")} {Math.ceil(total / limit) || 0}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(total / limit) || 0}
            defaultValue={1}
            onChange={(e) => onChangePageSize(e.target.value)}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => onChangePageSize(currentPage + 1)}
              disabled={!nextPage}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => onChangePageSize(Math.ceil(total / limit))}
              disabled={!nextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
};

export default withTranslation()(TableContainer);
