import axios from "axios";
import { del, get, post, put, uploadFile } from "./api_setup";
import * as url from "./url_helper";

//permission

// const getRoless = ({ limit, page}) => get(url.GET_ROLESS+`?limit=${limit}&page=${page}`);
// const getRolesById = (id) => get(url.GET_ROLES+id);
const createRole = (data) => post(url.POST_ROLES, data);
// const updateRole = (id, data) => put(url.PUT_ROLES+id, data);
const deleteRoles = (id) => del(url.DELETE_ROLES+ id);

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);
const postResetPassword = (token, password) => post(url.RESET_PASSWORD+token, password);
//cities
const getCities = ({limit = 1000, page = 1}) => get(url.GET_METADATA_CITY+`?limit=${limit}&page=${page}`);
const putCities = (id,city) => put(url.PUT_METADATA_CITY + id, city);
const postCities = (city) => post(url.POST_METADATA_CITY, city);

//countriess
const getCountries = ({limit = 1000, page = 1}) => get(url.GET_METADATA_COUNTRY+`?limit=${limit}&page=${page}`);
const putCountries = (id,country) => put(url.PUT_METADATA_COUNTRY + id, country);
const postCountries = (country) => post(url.POST_METADATA_COUNTRY, country);

//departments
const getDepartment = ({limit = 1000, page = 1}) => get(url.GET_METADATA_DEPARTMENT+`?limit=${limit}&page=${page}`);
const putDepartment = (id,department) => put(url.PUT_METADATA_DEPARTMENT + id, department);
const delDepartment = (id) => del(url.DEL_METADATA_DEPARTMENT + id);
const postDepartment = (department) => post(url.POST_METADATA_DEPARTMENT, department);

//banners
const getBanners = ({limit = 1000, page = 1}) => get(url.GET_METADATA_BANNERS+`?limit=${limit}&page=${page}`);
const putBanners = (id,banners) => put(url.PUT_METADATA_BANNERS + id, banners);
const postBanners = (banners) => post(url.POST_METADATA_BANNERS, banners);

//delegation
const getDelegation = ({limit = 10000, page = 1}) => get(url.GET_METADATA_DELEGATION+`?limit=${limit}&page=${page}`);
const putDelegation = (id,delegation) => put(url.PUT_METADATA_DELEGATION + id, delegation);
const postDelegation = (delegation) => post(url.POST_METADATA_DELEGATION, delegation);

//locality
const getLocality = ({limit = 10000, page = 1}) => get(url.GET_METADATA_LOCALITY+`?limit=${limit}&page=${page}`);
const putLocality = (id,locality) => put(url.PUT_METADATA_LOCALITY + id, locality);
const postLocality = (locality) => post(url.POST_METADATA_LOCALITY, locality);

//professions
const getProfession = ({limit = 1000, page = 1}) => get(url.GET_METADATA_PROFESSION+`?limit=${limit}&page=${page}`);
const putProfession = (id,profession) => put(url.PUT_METADATA_PROFESSION + id, profession);
const delProfession = (id) => del(url.DEL_METADATA_PROFESSION + id,);
const postProfession = (profession) => post(url.POST_METADATA_PROFESSION, profession);


//Ads
const getAds = ({limit = 1000, page = 1,from="",to="",departmentId="",type="",contentType="",status="",addedById="", end_date=""}) => get(url.GET_ADS+`?limit=${limit}&page=${page}&from=${from}&to=${to}&departmentId=${departmentId}&type=${type}&contentType=${contentType}&status=${status}&addedById=${addedById}&end_date=${end_date}`);
const putAds = (id,ads) => put(url.PUT_ADS + id, ads);
const postAds = (ads) => post(url.POST_ADS, ads);
const delAds = (id) => del(url.DEL_ADS+ id);


//governorate
const getGovernorates = ({ limit = 10000, page = 1 }) => get(url.GET_METADATA_GOVERNORATE+`?limit=${limit}&page=${page}`);
const putGovernorates = (id,governorate) => put(url.PUT_METADATA_GOVERNORATE + id, governorate);
const postGovernorates = (governorate) => post(url.POST_METADATA_GOVERNORATE, governorate);

// Gets the logged in user data from local session
const getLoggedInUser = () => get(url.VERIFY_ME);

const getAdmins = ({ limit = 1000, page = 1 }) => get(url.GET_ADMINS+`?limit=${limit}&page=${page}`);
const getDashboard = () => get(url.GET_DASHBOARD);

const getAdmin= (id) => get(url.GET_ADMIN+id);

const getUser = (id) => get(url.GET_USER+id);

const createAdmin = (data) => post(url.POST_REGISTER, data);

const updateAdmin = (id, data) => put(url.PUT_UPDATE+id, data);

const getRoles = ({limit, page}) => get(url.GET_ROLES+`?limit=${limit}&page=${page}`);
const updateRole = (id, role) => put(url.PUT_ROLE+id, role);
const getPermissions = () => get(url.GET_PERMISSIONS);

//user/type/professionnals
const getProfessionnalStats= () => get(url.GET_PROFESSIONNAL_STATS);
const getProfessionnal = ({limit = 1000, page = 1,from="",to="",professionId="",status="",average="",avgCr1="",avgCr2="",avgCr3="",avgCr4="",avgCr5="",governorateId="",delegationId="",localityId=""}) => get(url.GET_PROFESSIONNAL+`?avgCr1=${avgCr1}&avgCr2=${avgCr2}&avgCr3=${avgCr3}&avgCr4=${avgCr4}&avgCr5=${avgCr5}&limit=${limit}&page=${page}&from=${from}&to=${to}&professionId=${professionId}&status=${status}&governorateId=${governorateId}&delegationId=${delegationId}&localityId=${localityId}&average=${average}`);
const putProfessionnal = (id,professionnal) => put(url.PUT_PROFESSIONNAL + id, professionnal);
const postProfessionnal = (professionnal) => post(url.POST_PROFESSIONNAL, professionnal);
const getSeekerStats= () => get(url.GET_SEEKER_STATS);
const getSeeker = ({limit = 1000, page = 1,from="",to="",governorateId="",delegationId="",localityId=""}) => get(url.GET_SEEKER+`?limit=${limit}&page=${page}&from=${from}&to=${to}&governorateId=${governorateId}&delegationId=${delegationId}&localityId=${localityId}`);
const putSeeker = (id,seeker) => put(url.PUT_SEEKER+ id, seeker);
const postSeeker = (seeker) => post(url.POST_SEEKER, seeker);


//CRM/Evaluation

const getEvaluation = ({limit = 1000, page = 1,from="",to="",adminId="",professionalId="",cr1="",cr2="",cr3="",cr4="",cr5=""}) => get(url.GET_EVALUATION+`?limit=${limit}&page=${page}&from=${from}&to=${to}&adminId=${adminId}&professionalId=${professionalId}&cr1=${cr1}&cr2=${cr2}&cr3=${cr3}&cr4=${cr4}&cr5=${cr5}`);
const getEvaluationById = (id) => get(url.GET_EVALUATIONBYID + id);
const putEvaluation = (id,evaluation) => put(url.PUT_EVALUATION + id, evaluation);
const postEvaluation = (evaluation) => post(url.POST_EVALUATION, evaluation);
const delEvaluation = (id) => del(url.DEL_EVALUATION+ id);
//CRM/Request
const getRequestStats = () => get(url.GET_REQUEST_STATS);
const getRequest = ({limit = 1000, page = 1,from="",to="",status="",professionId="",adminId=""}) => get(url.GET_REQUEST+`?limit=${limit}&page=${page}&from=${from}&to=${to}&status=${status}&professionId=${professionId}&adminId=${adminId}`);
const putRequest = (id,request) => put(url.PUT_REQUEST + id, request);
const postRequest = (request) => post(url.POST_REQUEST, request);
const delRequest = (id) => del(url.DEL_REQUEST+ id);
//CRM/Search
const getSearchStats = () => get(url.GET_SEARCH_STATS);
const getSearch = ({limit = 1000, page = 1,from="",to="",status="",professionalId="",professionId="",seekerId=""}) => get(url.GET_SEARCH+`?limit=${limit}&page=${page}&from=${from}&to=${to}&status=${status}&professionalId=${professionalId}&professionId=${professionId}&seekerId=${seekerId}`);
const putSearch = (id,search) => put(url.PUT_SEARCH+ id, search);
const postSearch = (search) => post(url.POST_SEARCH_PROFESSIONALS, search)
//configs 
const getConfigs = () => get(url.GET_CONFIGS);
const updateConfigs = (id, data) => put(url.PUT_CONFIGS+id, data);

//upload files 
const upload = (data) => uploadFile(url.UPLOAD_FILES, data);


//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser();
};


export {
  getLoggedInUser,
  isUserAuthenticated,
  createRole,
  updateRole,
  deleteRoles,
  postLogin,
  getCities,
  putCities,
  postCities,
  getCountries,
  putCountries,
  postCountries,
  getDepartment,
  putDepartment,
  delDepartment,
  postDepartment,
  getBanners,
  putBanners,
  postBanners,
  getDelegation,
  putDelegation,
  postDelegation,
  getLocality,
  putLocality,
  postLocality,
  getProfession,
  putProfession,
  delProfession,
  postProfession,
  getProfessionnalStats,
  getProfessionnal,
  putProfessionnal,
  postProfessionnal,
  getSeekerStats,
  getSeeker,
  putSeeker,
  postSeeker,
  getEvaluation,
  getEvaluationById,
  putEvaluation,
  postEvaluation,
  delEvaluation,
  getSearchStats,
  getSearch,
  putSearch,
  postSearch,
  getRequestStats,
  getRequest,
  putRequest,
  postRequest,
  delRequest,
  getAds,
  putAds,
  postAds,
  delAds,
  getGovernorates,
  putGovernorates,
  postGovernorates,
  getAdmins,
  getDashboard,
  createAdmin,
  updateAdmin,
  getRoles,
  upload,
  getConfigs,
  updateConfigs,
  getUser, 
  getAdmin,
  postResetPassword,
  getPermissions,
  
};
