
/**filter */
export const GET_LOCALITY_BY_NAME_SUCCESS = "GET_LOCALITY_BY_NAME_SUCCESS";
export const GET_LOCALITY_BY_NAME_REQUEST = "GET_LOCALITY_BY_NAME_REQUEST";
export const GET_LOCALITY_BY_NAME_FAILED = "GET_LOCALITY_BY_NAME_FAILED";

/**Get */
export const GET_LOCALITY_SUCCESS = 'GET_LOCALITY_SUCCESS';
export const GET_LOCALITY_REQUEST = 'GET_LOCALITY_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_LOCALITY_FAILED = 'GET_LOCALITY_FAILED';

/**Create */
export const CREATE_LOCALITY_SUCCESS = 'CREATE_LOCALITY_SUCCESS';
export const CREATE_LOCALITY_FAILED = 'CREATE_LOCALITY_FAILED';
export const CREATE_LOCALITY_REQUEST = 'CREATE_LOCALITY_REQUEST';
/**Delete */
export const DELETE_LOCALITY_SUCCESS = 'DELETE_LOCALITY_SUCCESS';
export const DELETE_LOCALITY_FAILED = 'DELETE_LOCALITY_FAILED';
export const DELETE_LOCALITY_REQUEST = 'DELETE_LOCALITY_REQUEST';
/**Update */
export const UPDATE_LOCALITY_SUCCESS = 'UPDATE_LOCALITY_SUCCESS';
export const UPDATE_LOCALITY_FAILED = 'UPDATE_LOCALITY_FAILED';
export const UPDATE_LOCALITY_REQUEST = 'UPDATE_LOCALITY_REQUEST';

export const LOCALITY_MESSAGES_RESET = 'LOCALITY_MESSAGES_RESET';
export const LOCALITY_MESSAGES_SUCCESS = 'LOCALITY_MESSAGES_SUCCESS';
export const RESET_LOCALITY_MESSAGES_SUCCESS = 'RESET_LOCALITY_MESSAGES_SUCCESS';
export const RESET_LOCALITY_MESSAGES = 'RESET_LOCALITY_MESSAGES';

