
import {
    DELEGATION_MESSAGES_RESET,
    CREATE_DELEGATION_FAILED,
    CREATE_DELEGATION_REQUEST,
    CREATE_DELEGATION_SUCCESS,
    DELETE_DELEGATION_FAILED,
    DELETE_DELEGATION_REQUEST,
    DELETE_DELEGATION_SUCCESS,
    GET_DELEGATION_BY_NAME_FAILED,
    GET_DELEGATION_BY_NAME_REQUEST,
    GET_DELEGATION_BY_NAME_SUCCESS,
    GET_DELEGATION_FAILED,
    GET_DELEGATION_REQUEST,
    GET_DELEGATION_SUCCESS,
    UPDATE_DELEGATION_FAILED,
    UPDATE_DELEGATION_REQUEST,
    RESET_DELEGATION_MESSAGES,
    RESET_DELEGATION_MESSAGES_SUCCESS,
    UPDATE_DELEGATION_SUCCESS
} from "./actionTypes.js"

export const getDelegationByName = name => ({
    type: GET_DELEGATION_BY_NAME_REQUEST,
    payload: name
})


export const getDelegationByNameSuccess = payload => ({
    type: GET_DELEGATION_BY_NAME_SUCCESS,
    payload
})


export const getDelegationByNameFailed = payload => ({
    type: GET_DELEGATION_BY_NAME_FAILED,
    payload
})




export const getMDMDelegation = (filter) => ({
    type: GET_DELEGATION_REQUEST,
    payload:filter
})



export const getMDMDelegationSuccess = delegation => ({
    type: GET_DELEGATION_SUCCESS,
    payload: delegation
})

export const getMDMDelegationFailed = payload => ({
    type: GET_DELEGATION_FAILED,
    payload
})

export const createMDMDelegation = delegation => ({
    type: CREATE_DELEGATION_REQUEST,
    payload: delegation
})

export const createMDMDelegationSuccess = delegation => ({
    type: CREATE_DELEGATION_SUCCESS,
    payload: delegation
})

export const createMDMDelegationFailed = payload => ({
    type: CREATE_DELEGATION_FAILED,
    payload
})

export const deleteMDMDelegation= id => ({
    type: DELETE_DELEGATION_REQUEST,
    payload: id
})

export const deleteMDMDelegationSuccess = id => ({
    type: DELETE_DELEGATION_SUCCESS,
    payload: id
})

export const deleteMDMDelegationFailed = id => ({
    type: DELETE_DELEGATION_FAILED,
    payload: id
})

export const updateMDMDelegation = (delegation )=> ({
    type: UPDATE_DELEGATION_REQUEST,
    payload: delegation
})

export const updateMDMDelegationSuccess = (delegation ) => ({
    type: UPDATE_DELEGATION_SUCCESS,
    payload: delegation
})

export const updateMDMDelegationFailed = payload => ({
    type: UPDATE_DELEGATION_FAILED,
    payload
})

export const resetMDMDelegationMessages = () => ({
    type: DELEGATION_MESSAGES_RESET
}
)


export const resetDelegationMessages = () => ({
    type: RESET_DELEGATION_MESSAGES,
  })
  
  export const resetDelegationMessagesSuccess = () => ({
    type: RESET_DELEGATION_MESSAGES_SUCCESS,
  })

