import {
  

    GET_SEARCH_FAILED,
    GET_SEARCH_REQUEST,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_STATS_FAILED,
    GET_SEARCH_STATS_REQUEST,
    GET_SEARCH_STATS_SUCCESS,
    UPDATE_SEARCH_FAILED,
    UPDATE_SEARCH_REQUEST,
    UPDATE_SEARCH_SUCCESS,
    CREATE_SEARCH_FAILED,
    CREATE_SEARCH_REQUEST,
    CREATE_SEARCH_SUCCESS,
 
  } from "./actionTypes.js";
  


  

  
  export const getSearch = (filter) => ({
    type: GET_SEARCH_REQUEST,
    payload: filter,
  });
  
  export const getSearchSuccess = (search) => ({
    type: GET_SEARCH_SUCCESS,
    payload: search,
  });
  
  export const getSearchFailed = (payload) => ({
    type: GET_SEARCH_FAILED,
    payload,
  });
  export const getSearchStats = (filter) => ({
    type: GET_SEARCH_STATS_REQUEST,
    payload: filter,
  });
  
  export const getSearchStatsSuccess = (search) => ({
    type: GET_SEARCH_STATS_SUCCESS,
    payload: search,
  });
  
  export const getSearchStatsFailed = (payload) => ({
    type: GET_SEARCH_STATS_FAILED,
    payload,
  });
  
  export const updateSearch = (filter) => ({
    type: UPDATE_SEARCH_REQUEST,
    payload: filter,
  });
  
  export const updateSearchSuccess = (search) => ({
    type: UPDATE_SEARCH_SUCCESS,
    payload: search,
  });
  
  export const updateSearchFailed = (payload) => ({
    type: UPDATE_SEARCH_FAILED,
    payload,
  });
  
 
  export const createSearch = (filter) => ({
    type: CREATE_SEARCH_REQUEST,
    payload: filter,
  });
  
  export const createSearchSuccess = (search) => ({
    type: CREATE_SEARCH_SUCCESS,
    payload: search,
  });
  
  export const createSearchFailed = (payload) => ({
    type: CREATE_SEARCH_FAILED,
    payload,
  });
  
 
  
  
  
