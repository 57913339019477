import {
    BANNERS_MESSAGES_RESET,
    RESET_BANNERS_MESSAGES_SUCCESS,
    CREATE_BANNERS_FAILED,
    CREATE_BANNERS_REQUEST,
    CREATE_BANNERS_SUCCESS,
    DELETE_BANNERS_FAILED,
    DELETE_BANNERS_REQUEST,
    DELETE_BANNERS_SUCCESS,
    GET_BANNERS_BY_NAME_FAILED,
    GET_BANNERS_BY_NAME_REQUEST,
    GET_BANNERS_BY_NAME_SUCCESS,
    GET_BANNERS_FAILED,
    GET_BANNERS_REQUEST,
    GET_BANNERS_SUCCESS,
    UPDATE_BANNERS_FAILED,
    UPDATE_BANNERS_REQUEST,
    UPDATE_BANNERS_SUCCESS,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    bannersData: [],
    success: "",
    error: "",
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_BANNERS_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_BANNERS_SUCCESS:
        const {data, ...filters} = action.payload
        return {
          ...state,
          bannersData: [...data],
          ...filters,
          loading: false,
          success: "GET",
        }
      case GET_BANNERS_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_BANNERS_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_BANNERS_BY_NAME_SUCCESS:
        return {
          ...state,
          bannersData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_BANNERS_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_BANNERS_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_BANNERS_SUCCESS:
        return {
          ...state,
          bannersData: [...state.bannersData, action.payload],
          success: "CREATE",
          loading: false,
        }
      case CREATE_BANNERS_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_BANNERS_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_BANNERS_SUCCESS:
        return {
          ...state,
          bannersData: state.bannersData.filter(
            banners => banners.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_BANNERS_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_BANNERS_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case UPDATE_BANNERS_SUCCESS:
        return {
          ...state,
          bannersData: state.bannersData.map(banners =>
            banners.id.toString() === action.payload.id.toString()
              ? { banners, ...action.payload }
              : banners
          ),
          success: "UPDATE",
          loading: false,
        }
      case UPDATE_BANNERS_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case BANNERS_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_BANNERS_MESSAGES_SUCCESS:
          return {
            ...state,
            error: null,
            success: null
          }
      default:
        return state
    }
  }
  
  export default reducer
  
