
/**filter */
export const GET_ADS_BY_NAME_SUCCESS = "GET_ADS_BY_NAME_SUCCESS";
export const GET_ADS_BY_NAME_REQUEST = "GET_ADS_BY_NAME_REQUEST";
export const GET_ADS_BY_NAME_FAILED = "GET_ADS_BY_NAME_FAILED";

/**Get */
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_REQUEST = 'GET_ADS_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_ADS_FAILED = 'GET_ADS_FAILED';

/**Create */
export const CREATE_ADS_SUCCESS = 'CREATE_ADS_SUCCESS';
export const CREATE_ADS_FAILED = 'CREATE_ADS_FAILED';
export const CREATE_ADS_REQUEST = 'CREATE_ADS_REQUEST';
/**Delete */
export const DELETE_ADS_SUCCESS = 'DELETE_ADS_SUCCESS';
export const DELETE_ADS_FAILED = 'DELETE_ADS_FAILED';
export const DELETE_ADS_REQUEST = 'DELETE_ADS_REQUEST';
/**Update */
export const UPDATE_ADS_SUCCESS = 'UPDATE_ADS_SUCCESS';
export const UPDATE_ADS_FAILED = 'UPDATE_ADS_FAILED';
export const UPDATE_ADS_REQUEST = 'UPDATE_ADS_REQUEST';

export const ADS_MESSAGES_RESET = 'ADS_MESSAGES_RESET';
export const ADS_MESSAGES_SUCCESS = 'ADS_MESSAGES_SUCCESS';
export const RESET_ADS_MESSAGES_SUCCESS = 'RESET_ADS_MESSAGES_SUCCESS';
export const RESET_ADS_MESSAGES = 'RESET_ADS_MESSAGES';

