import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  Spinner,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng, geocodeByLatLng } from 'react-google-places-autocomplete';
import {
  createMDMLocality,
  getMDMDelegation,
  getMDMGovernorates,
  updateMDMLocality,
} from "store/actions";

const LocalityModal = (props) => {
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  });
  const [location, setLocation] = useState(null)
  const googleScript = document.getElementById('__googleMapsScriptId')

  const dispatch = useDispatch();
  const { modal, toggle, Locality, isEdit } = props;
  const { governoratesData } = useSelector((state) => state.governorates);
  const [loading, setLoading] = useState(false)

// console.log("Locality",Locality?.parent.parentId)


  useEffect(() => {
    {
      dispatch(getMDMGovernorates());
    }
  }, [dispatch, filter]);

  useEffect(() => {
    {
      dispatch(getMDMDelegation(filter))
      // console.log("country",delegationData)

    }
  }, [dispatch, filter])

  useEffect(() => {
    if (Locality?.latitude && Locality?.langitude) {
      if (window.google) {
        geocodeByLatLng({ lat: parseFloat(Locality.latitude), lng: parseFloat(Locality.langitude) })
          .then(results => {
            setLocation({ value: results[0], label: results[0].formatted_address })
          })
          .catch(error => console.error(error));
      }
      googleScript?.addEventListener('load', () => {
        // Patiently waiting to do the thing 

        geocodeByLatLng({ lat: parseFloat(Locality.latitude), lng: parseFloat(Locality.langitude) })
          .then(results => {
            setLocation({ value: results[0], label: results[0].formatted_address })
          })
          .catch(error => console.error(error));
      })

    }
    return () => {
      setLocation(null)
    }
  }, [Locality, googleScript])
  const { delegationData } = useSelector(state => state.delegations)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (Locality && Locality.name) || "",
      parentId: (Locality && Locality.parentId) || "",
      status: (Locality && Locality.status) || "active",
      type: (Locality && Locality.type) || "locality",
      langitude: (Locality && Locality.langitude) || "",
      latitude: (Locality && Locality.latitude) || "",
      map: (Locality && Locality.langitude && Locality.latitude && [Locality.latitude, Locality.langitude].join(", ")) || ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Your Name")),
      parentId: Yup.number().required(props.t("Please Enter Your governorate")),

    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateLocality = {
          id: Locality.id,
          name: values.name,
          parentId: values.parentId,
          langitude: values.langitude,
          latitude: values.latitude
        };
        // update
        dispatch(updateMDMLocality(updateLocality))
        validation.resetForm();
      } else {
        const newLocality = {
          id: values["id"],
          name: values["name"],
          parentId: values["parentId"],
          status: values["status"],
          type: values["type"],
          langitude: values["langitude"],
          latitude: values["latitude"]
        };
        // save new locality
        // console.log("newLocality",newLocality)
        dispatch(createMDMLocality(newLocality))
        validation.resetForm();
      }
      toggle();
    },
  });



  const coordinationsHandler = (address) => {
    const { label, value } = address
    setLocation(address)
    geocodeByPlaceId(value.place_id)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        validation.setFieldValue("latitude", lat)
        validation.setFieldValue("langitude", lng)

      })
      .catch(error => console.error("errrr", error));
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit localité") : props.t("Ajouter une localité")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Row>
              <Col className="col-12">
                <Col className="col-9">
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Nom")}</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="row">
                    {/* <div className="mb-3">
                      <Label className="form-label">
                        {props.t("Gouvernorat")}
                      </Label>
                      <Input
                        type="select"
                        name="parent"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.parent || []}
                        invalid={
                          validation.touched.parent &&
                            validation.errors.parent
                            ? true
                            : false
                        }
                      >
                        <option key="empty" disabled></option>
                        {governoratesData.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.parent &&
                        validation.errors.parent ? (
                        <FormFeedback type="invalid">
                          {validation.errors.parent}
                        </FormFeedback>
                      ) : null}
                    </div> */}

                    <div className="mb-3">
                      <Label className="form-label">
                        {props.t("Délégation")}
                      </Label>
                      <Input
                        type="select"
                        name="parentId"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.parentId || ""}
                        invalid={
                          validation.touched.parentId &&
                            validation.errors.parentId
                            ? true
                            : false
                        }
                      >
                        <option key="empty" disabled selected></option>
                        {delegationData.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.parentId &&
                        validation.errors.parentId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.parentId}
                        </FormFeedback>
                      ) : null}
                    </div>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Coordinations")}
                        </Label>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyBs7pFjOBMNQzMbjvyxaHrRw7q3Sn2Gi6Y"
                          selectProps={{
                            placeholder: "Choisie votre address",
                            value: location,
                            onChange: coordinationsHandler,
                          }}
                        />
                      </div>
                  </div>


                </Col>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-customer">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(LocalityModal);
