import React, {useState } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  TabContent,
 
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { createEvaluation, updateEvaluation, updateSearch } from "store/actions";


const EvaluationModal = (props) => {
  const { userSearch, isEdit, modal, toggle } = props;
  const { user } = useSelector((state) => state.Login);
  const dispatch = useDispatch();
  const [activeTabVartical, setoggleTabVertical] = useState(1);



  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      professionalId: (userSearch && userSearch.professionalId) || "",
      seekerId: (userSearch && userSearch.seekerId) || "",
      cr1: (userSearch && userSearch.evaluations[0]?.cr1) || 0,
      cr2: (userSearch && userSearch.evaluations[0]?.cr2) || 0,
      cr3: (userSearch && userSearch.evaluations[0]?.cr3) || 0,
      cr4: (userSearch && userSearch.evaluations[0]?.cr4) || 0,
      cr5: (userSearch && userSearch.evaluations[0]?.cr5) || 0,
      status: (userSearch && userSearch?.status) || "",
      comments: (userSearch && userSearch.comments) || [],
      comment: "",
      evaluations: (userSearch && userSearch.evaluations[0]) || [],
      evaluation: ""

    },

    validationSchema: Yup.object({
      
      
    }),
    onSubmit: (values) => {
      if (isEdit) {
        // update
        const updateEvaluations = {
          id: userSearch.id,
          status: values.status,
          ...(values.comment && {
            comments: [{
              adminId: user?.id, description: values.comment
            }]
          }),

        };
        // update
        // console.log("updateEvaluation", updateEvaluations);
        dispatch(updateSearch(updateEvaluations));
        dispatch(updateEvaluation({id: userSearch?.evaluations[0]?.id, cr1: values["cr1"],cr2: values["cr2"],cr3: values["cr3"], cr4: values["cr4"],cr5: values["cr5"], status: values.status}));
        validation.resetForm();
      }
    else { const newEvaluation = {
        id: userSearch.id,
        professionalId: parseInt(values["professionalId"]) || null,
        seekerId: parseInt(values["seekerId"]) || null,
        cr1: values["cr1"],
        cr2: values["cr2"],
        cr3: values["cr3"],
        cr4: values["cr4"],
        cr5: values["cr5"],
        professionalSearchId: userSearch?.id,
        adminId: user?.id || null,
        status: values["status"],
        ...(values.comment && {
          comments: [{
            adminId: user?.id, description: values.comment
          }]
        })
      };
     
      // save new user
      dispatch(createEvaluation({ id: userSearch.id,
        professionalId: parseInt(values["professionalId"]) || null,
        seekerId: parseInt(values["seekerId"]) || null,
        cr1: values["cr1"],
        cr2: values["cr2"],
        cr3: values["cr3"],
        cr4: values["cr4"],
        cr5: values["cr5"],
        professionalSearchId: userSearch?.id,
        adminId: user?.id || null,
        status: values["status"],
        ...(values.comment && {
          comments: [{
            adminId: user?.id, description: values.comment
          }]
        })}));
   
   
      dispatch(updateSearch(newEvaluation));
      validation.resetForm();
      }
      toggle();
    },

  });

  return (
    <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "49vw" }}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Modifier l'évaluation") : props.t("Fiche évaluation")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Col className="col-12">
            <div className="row">
              <div className="col-6 mb-3">
                <Label className="form-label mb-3">
                  id: {" " + userSearch?.id}
                </Label>
                <br />

                <Label className="form-label mb-3">
                  date:
                  {" " +
                    moment(userSearch?.createdAt).format("YYYY-MM-DD HH:mm")}
                </Label>
                <br />
                <Label className="form-label mb-3">
                  User: {user?.firstname + " " + user?.lastname}
                </Label>
                <br />
              </div>
              <div className="col-3 mb-3">
                <Label className="form-label">
                  Professionnel:{" "}
                  {" " +
                    userSearch?.professional?.firstname +
                    " " +
                    userSearch?.professional?.lastname}
                </Label>
                <br />
                <Label className="form-label">
                  Chercheur:{" "}
                  {" " +
                    userSearch?.seeker?.firstname +
                    " " +
                    userSearch?.seeker?.lastname}
                </Label>
              </div>
              <div className="col-3 mb-3">
                {/* <Label className="form-label">Evaluation Moyenne:</Label> */}
              </div>
            </div>
          </Col>

          <Row form>
            <Col xs={12}>
              <br />
              <br />
              <br />
              <Col lg="12">
                <Card>
                  <CardBody>



                    <TabContent
                      activeTab={activeTabVartical}
                      className="body"
                    >

                      <Form>

                        <div className="col-12 mb-5">
                          <div className="row">
                            <div className="col-12"><Label className="form-label mb-3 mx-3 text-primary" style={{ fontSize: '1rem' }}>
                              Tarification:
                            </Label>{" "}</div>

                            <div className="col-12 mx-5">
                              <input
                                type="radio"
                                name="cr1"
                                className="form-check-input  me-2"
                                id="customRadio1"
                                // value={validation.setFieldValue(cr1,"1")}
                                // checked={validation.values.cr1 === "1" }
                                onClick={e => {
                                
                                    validation.setFieldValue("cr1", 5) 
                                 
                                }}
                           
                                checked={validation.values.cr1 == 5 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-radio-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Expert
                              </label>
                              <Input
                                type="radio"
                                name="cr1"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr1", 4)
                                 
                                }}
                            
                                checked={validation.values.cr1 == 4 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Bien
                              </label>
                              <Input
                                type="radio"
                                name="cr1"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                  
                                    validation.setFieldValue("cr1", 3)
                                  
                                }}
                                checked={validation.values.cr1 == 3 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Intermédiaire
                              </label>
                              <Input
                                type="radio"
                                name="cr1"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                  
                                    validation.setFieldValue("cr1", 2)
                                
                                }}

                                checked={validation.values.cr1 == 2 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Débutant
                              </label>
                              <Input
                                type="radio"
                                name="cr1"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                  
                                    validation.setFieldValue("cr1", 1)
                                  
                                }}
                                checked={validation.values.cr1 == 1 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Médiocre
                              </label>
                              <Input
                                type="radio"
                                name="cr1"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr1", 0)
                                  
                                }}
                                checked={validation.values.cr1 == 0 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Non évalué
                              </label>
                            </div>

                          </div>
                        </div>
                        <div className="col-12 mb-5">
                          <div className="row">
                            <div className="col-12">

                              <Label className="form-label mb-3 text-primary mx-3" style={{ fontSize: '1rem' }}>
                                Efficacité :
                              </Label>{" "}
                            </div>

                            <div className="col-12 mx-5">
                              <input
                                type="radio"
                                name="cr2"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                  
                                    validation.setFieldValue("cr2", 5)
                                 
                                }}
                                checked={validation.values.cr2 == 5 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Expert
                              </label>
                              <input
                                type="radio"
                                name="cr2"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr2", 4)
                                 
                                }}
                                checked={validation.values.cr2 == 4 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Bien
                              </label>
                              <input
                                type="radio"
                                name="cr2"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr2", 3)
                          
                                }}
                                checked={validation.values.cr2 == 3 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Intermédiaire
                              </label>
                              <input
                                type="radio"
                                name="cr2"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                  
                                    validation.setFieldValue("cr2", 2)
                               
                                }}

                                checked={validation.values.cr2 ==2 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Débutant
                              </label>
                              <input
                                type="radio"
                                name="cr2"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                
                                    validation.setFieldValue("cr2", 1)
                                 
                                }}
                                checked={validation.values.cr2 == 1 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Médiocre
                              </label>
                              <input
                                type="radio"
                                name="cr2"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr2", 0)
                                
                                }}
                                checked={validation.values.cr2 == 0 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Non évalué
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-5">
                          <div className="row">
                            <div className="col-12 mx-3"> <Label className="form-label mb-3 text-primary" style={{ fontSize: '1rem' }}>
                              Ponctualité:
                            </Label>{" "}
                            </div>


                            <div className="col-12 mx-5">
                              <input
                                type="radio"
                                name="cr3"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr3", 5)
                                
                                }}
                                checked={validation.values.cr3 == 5 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Expert
                              </label>
                              <input
                                type="radio"
                                name="cr3"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr3", 4)
                             
                                }}
                                checked={validation.values.cr3 == 4 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Bien
                              </label>
                              <input
                                type="radio"
                                name="cr3"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr3", 3)
                                 
                                }}
                                checked={validation.values.cr3 == 3 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Intermédiaire
                              </label>
                              <input
                                type="radio"
                                name="cr3"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr3", 2)
                                 
                                }}
                                checked={validation.values.cr3 == 2 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Débutant
                              </label>
                              <input
                                type="radio"
                                name="cr3"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr3", 1)
                                 
                                }}
                                checked={validation.values.cr3 == 1 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Médiocre
                              </label>
                              <input
                                type="radio"
                                name="cr3"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr3", 0)
                               
                                }}
                                checked={validation.values.cr3 == 0 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Non évalué
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-5">
                          <div className="row">
                            <div className="col-12"><Label className="form-label mb-3 text-primary mx-3" style={{ fontSize: '0.85rem' }}>
                              Qualité de travail:
                            </Label>{" "}
                            </div>
                            <div className="col-12 mx-5">
                              <input
                                type="radio"
                                name="cr4"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                
                                    validation.setFieldValue("cr4", 5)
                                 
                                }}
                                checked={validation.values.cr4 == 5 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Expert
                              </label>
                              <input
                                type="radio"
                                name="cr4"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr4", 4)
                                 
                                }}
                                checked={validation.values.cr4 == 4 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Bien
                              </label>
                              <input
                                type="radio"
                                name="cr4"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr4", 3)
                                
                                }}
                                checked={validation.values.cr4 == 3 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Intermédiaire
                              </label>
                              <input
                                type="radio"
                                name="cr4"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr4", 2)
                                  
                                }}
                                checked={validation.values.cr4 == 2 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Débutant
                              </label>
                              <input
                                type="radio"
                                name="cr4"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr4", 1)
                               
                                }}
                                checked={validation.values.cr4 == 1 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Médiocre
                              </label>
                              <input
                                type="radio"
                                name="cr4"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr4", 0)
                                
                                }}
                                checked={validation.values.cr4 == 0 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Non évalué
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mb-5">
                          <div className="row">
                            <div className="col-12"><Label className="form-label mb-3 text-primary mx-3" style={{ fontSize: '1rem' }} >
                              Rapidité:
                            </Label>{" "}
                            </div>
                            <div className="col-12 mx-5">
                              <input
                                type="radio"
                                name="cr5"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr5", 5)
                              
                                }}
                                checked={validation.values.cr5 == 5 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"

                              >
                                Expert
                              </label>
                              <input
                                type="radio"
                                name="cr5"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr5", 4)
                                  
                                }}
                                checked={validation.values.cr5 == 4 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Bien
                              </label>
                              <input
                                type="radio"
                                name="cr5"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                
                                    validation.setFieldValue("cr5", 3)
                                 
                                }}
                                checked={validation.values.cr5 == 3 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Intermédiaire
                              </label>
                              <input
                                type="radio"
                                name="cr5"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr5", 2)
                                 
                                }}
                                checked={validation.values.cr5 == 2 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Débutant
                              </label>
                              <input
                                type="radio"
                                name="cr5"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                
                                    validation.setFieldValue("cr5", 1)
                              
                                }}
                                checked={validation.values.cr5 == 1 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                5: Médiocre
                              </label>
                              <input
                                type="radio"
                                name="cr5"
                                className="form-check-input  me-2"
                                id="customCheck2"
                                onClick={e => {
                                 
                                    validation.setFieldValue("cr5", 0)
                               
                                }}
                                checked={validation.values.cr5 == 0 }
                                onBlur={validation.handleBlur}
                              />
                              <label
                                className="form-check-label  me-4"
                                htmlFor="customCheck2"
                              >
                                Non évalué
                              </label>
                            </div>
                          </div>
                        </div>

                      </Form>

                    
                    </TabContent>



                  </CardBody>
                </Card>

               <div className="mb-3">
                  <Label className="form-label mb-3">{props.t("Autre commentaires :")}</Label>{" "}
                  {validation.values.comments?.map(el => (
                    <div key={el.id} className="mb-3 d-flex">
                      <div>
                        <p className="mx-1 fw-bold text-nowrap">{el.admin?.firstname + " " + el.admin?.lastname + " :"}</p>
                      </div>
                      <div>
                        <p className="mx-1">{el.description}</p>
                        <p className="mx-1 mw-100">{"Le " + moment(new Date(el.createdAt)).format("DD MMM, YYYY à HH : mm")}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Commentaires :")}</Label>{" "}
                  <Input
                    type="textarea"
                    name="comment"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.comment}
                    invalid={
                      validation.touched.comment && validation.errors.comment
                        ? true
                        : false
                    }
                  />
                  <p>ajouter si vous avez des commentaires</p>
                </div>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-end">
                <Input
                  type="select"
                  name="status"
                  value={validation.values?.status}
                  className="form-select me-2 bg-white"
                  style={{ width: 150 }}
                  onChange={validation.handleChange}
                 
                >
                  <option key="open"></option>
                  <option key="Encours">Encours</option>
                  <option key="closed">Clôturée</option>
                  <option key="realised">Réalisée</option>
                  <option key="refused">Refusée</option>
                </Input>
                <button
                  type="submit"
                  className="btn btn-success save-customer w-25"
                >
                  {props.t("save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(EvaluationModal);
