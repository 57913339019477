
import {
    PROFESSION_MESSAGES_RESET,
    CREATE_PROFESSION_FAILED,
    CREATE_PROFESSION_REQUEST,
    CREATE_PROFESSION_SUCCESS,
    DELETE_PROFESSION_FAILED,
    DELETE_PROFESSION_REQUEST,
    DELETE_PROFESSION_SUCCESS,
    GET_PROFESSION_BY_NAME_FAILED,
    GET_PROFESSION_BY_NAME_REQUEST,
    GET_PROFESSION_BY_NAME_SUCCESS,
    GET_PROFESSION_FAILED,
    GET_PROFESSION_REQUEST,
    GET_PROFESSION_SUCCESS,
    UPDATE_PROFESSION_FAILED,
    UPDATE_PROFESSION_REQUEST,
    RESET_PROFESSION_MESSAGES,
    RESET_PROFESSION_MESSAGES_SUCCESS,
    UPDATE_PROFESSION_SUCCESS
} from "./actionTypes.js"

export const getProfessionByName = name => ({
    type: GET_PROFESSION_BY_NAME_REQUEST,
    payload: name
})


export const getProfessionByNameSuccess = payload => ({
    type: GET_PROFESSION_BY_NAME_SUCCESS,
    payload
})


export const getProfessionByNameFailed = payload => ({
    type: GET_PROFESSION_BY_NAME_FAILED,
    payload
})




export const getMDMProfession = (filter) => ({
    type: GET_PROFESSION_REQUEST,
    payload:filter
})



export const getMDMProfessionSuccess = Profession => ({
    type: GET_PROFESSION_SUCCESS,
    payload: Profession
})

export const getMDMProfessionFailed = payload => ({
    type: GET_PROFESSION_FAILED,
    payload
})

export const createMDMProfession = profession => ({
    type: CREATE_PROFESSION_REQUEST,
    payload: profession
})

export const createMDMProfessionSuccess = profession => ({
    type: CREATE_PROFESSION_SUCCESS,
    payload: profession
})

export const createMDMProfessionFailed = payload => ({
    type: CREATE_PROFESSION_FAILED,
    payload
})

export const deleteMDMProfession= id => ({
    type: DELETE_PROFESSION_REQUEST,
    payload: id
})

export const deleteMDMProfessionSuccess = id => ({
    type: DELETE_PROFESSION_SUCCESS,
    payload: id
})

export const deleteMDMProfessionFailed = id => ({
    type: DELETE_PROFESSION_FAILED,
    payload: id
})

export const updateMDMProfession= (Profession )=> ({
    type: UPDATE_PROFESSION_REQUEST,
    payload: Profession
})

export const updateMDMProfessionSuccess = (Profession ) => ({
    type: UPDATE_PROFESSION_SUCCESS,
    payload: Profession
})

export const updateMDMProfessionFailed = payload => ({
    type: UPDATE_PROFESSION_FAILED,
    payload
})

export const resetMDMProfessionMessages = () => ({
    type: PROFESSION_MESSAGES_RESET
}
)


export const resetProfessionMessages = () => ({
    type: RESET_PROFESSION_MESSAGES,
  })
  
  export const resetProfessionMessagesSuccess = () => ({
    type: RESET_PROFESSION_MESSAGES_SUCCESS,
  })

