
/**filter */
export const GET_EVALUATION_BY_NAME_SUCCESS = "GET_EVALUATION_BY_NAME_SUCCESS";
export const GET_EVALUATION_BY_NAME_REQUEST = "GET_EVALUATION_BY_NAME_REQUEST";
export const GET_EVALUATION_BY_NAME_FAILED = "GET_EVALUATION_BY_NAME_FAILED";

/**Get */
export const GET_EVALUATIONBYID_SUCCESS = 'GET_EVALUATIONBYID_SUCCESS';
export const GET_EVALUATIONBYID_REQUEST = 'GET_EVALUATIONBYID_REQUEST';
export const GET_EVALUATIONBYID_FAILED = 'GET_EVALUATIONBYID_FAILED';
export const GET_EVALUATION_SUCCESS = 'GET_EVALUATION_SUCCESS';
export const GET_EVALUATION_REQUEST = 'GET_EVALUATION_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_EVALUATION_FAILED = 'GET_EVALUATION_FAILED';

/**Create */
export const CREATE_EVALUATION_SUCCESS = 'CREATE_EVALUATION_SUCCESS';
export const CREATE_EVALUATION_FAILED = 'CREATE_EVALUATION_FAILED';
export const CREATE_EVALUATION_REQUEST = 'CREATE_EVALUATION_REQUEST';
/**Delete */
export const DELETE_EVALUATION_SUCCESS = 'DELETE_EVALUATION_SUCCESS';
export const DELETE_EVALUATION_FAILED = 'DELETE_EVALUATION_FAILED';
export const DELETE_EVALUATION_REQUEST = 'DELETE_EVALUATION_REQUEST';
/**Update */
export const UPDATE_EVALUATION_SUCCESS = 'UPDATE_EVALUATION_SUCCESS';
export const UPDATE_EVALUATION_FAILED = 'UPDATE_EVALUATION_FAILED';
export const UPDATE_EVALUATION_REQUEST = 'UPDATE_EVALUATION_REQUEST';

export const EVALUATION_MESSAGES_RESET = 'EVALUATION_MESSAGES_RESET';
export const EVALUATION_MESSAGES_SUCCESS = 'EVALUATION_MESSAGES_SUCCESS';
export const RESET_EVALUATION_MESSAGES_SUCCESS = 'RESET_EVALUATION_MESSAGES_SUCCESS';
export const RESET_EVALUATION_MESSAGES = 'RESET_EVALUATION_MESSAGES';

