import {
  PROFESSIONNAL_MESSAGES_RESET,
  RESET_PROFESSIONNAL_MESSAGES_SUCCESS,
  CREATE_PROFESSIONNAL_FAILED,
  CREATE_PROFESSIONNAL_REQUEST,
  CREATE_PROFESSIONNAL_SUCCESS,
  DELETE_PROFESSIONNAL_FAILED,
  DELETE_PROFESSIONNAL_REQUEST,
  DELETE_PROFESSIONNAL_SUCCESS,
  GET_PROFESSIONNAL_BY_NAME_FAILED,
  GET_PROFESSIONNAL_BY_NAME_REQUEST,
  GET_PROFESSIONNAL_BY_NAME_SUCCESS,
  GET_PROFESSIONNAL_FAILED,
  GET_PROFESSIONNAL_REQUEST,
  GET_PROFESSIONNAL_SUCCESS,
  UPDATE_PROFESSIONNAL_FAILED,
  UPDATE_PROFESSIONNAL_REQUEST,
  UPDATE_PROFESSIONNAL_SUCCESS,
  GET_PROFESSIONNAL_STATS_REQUEST,
  GET_PROFESSIONNAL_STATS_SUCCESS,
  GET_PROFESSIONNAL_STATS_FAILED,
} from "./actionTypes";

export const initialState = {
  loading: false,
  professiannalData: [],
  professiannalStatsData: {},
  success: "",
  error: null,
  success: null,
  loading: false,
  nextPage: null,
  previousPage: null,
  limit: null,
  total: null,
  currentPage: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFESSIONNAL_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
    case GET_PROFESSIONNAL_SUCCESS:
      const { data, ...filters } = action.payload;
      return {
        ...state,
        professiannalData: [...data],
        ...filters,
        loading: false,
        success: "GET",
      };
    case GET_PROFESSIONNAL_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case GET_PROFESSIONNAL_STATS_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
    case GET_PROFESSIONNAL_STATS_SUCCESS:
      
      return {
        ...state,
        professiannalStatsData: action.payload,
        loading: false,
        success: "GET",
      };
    case GET_PROFESSIONNAL_STATS_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case GET_PROFESSIONNAL_BY_NAME_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
    case GET_PROFESSIONNAL_BY_NAME_SUCCESS:
      return {
        ...state,
        professiannalData: action.payload,
        loading: false,
        success: "SUCCESS",
      };
    case GET_PROFESSIONNAL_BY_NAME_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case CREATE_PROFESSIONNAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROFESSIONNAL_SUCCESS:
      return {
        ...state,
        professiannalData: [...state.professiannalData, action.payload],
        success: "CREATE",
        loading: false,
      };
    case CREATE_PROFESSIONNAL_FAILED:
      return {
        ...state,
        error: "FAILED CREATE",
        loading: false,
      };
    case DELETE_PROFESSIONNAL_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    case DELETE_PROFESSIONNAL_SUCCESS:
      return {
        ...state,
        professiannalData: state.professiannalData.filter(
          (professionnal) =>
            professionnal.id.toString() !== action.payload.toString()
        ),
        success: "DELETE",
        loading: false,
      };
    case DELETE_PROFESSIONNAL_FAILED:
      return {
        ...state,
        error: "FAILED DELETE",
        loading: false,
      };
    case UPDATE_PROFESSIONNAL_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    case UPDATE_PROFESSIONNAL_SUCCESS:
      return {
        ...state,
        professiannalData: state.professiannalData.map((professionnal) =>
          professionnal.id.toString() === action.payload.id.toString()
            ? { professionnal, ...action.payload }
            : professionnal
        ),
        success: "UPDATE",
        loading: false,
      };
    case UPDATE_PROFESSIONNAL_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      };
    case PROFESSIONNAL_MESSAGES_RESET:
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    case RESET_PROFESSIONNAL_MESSAGES_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    default:
      return state;
  }
}

export default reducer;
