import {
    LOCALITY_MESSAGES_RESET,
    RESET_LOCALITY_MESSAGES_SUCCESS,
    CREATE_LOCALITY_FAILED,
    CREATE_LOCALITY_REQUEST,
    CREATE_LOCALITY_SUCCESS,
    DELETE_LOCALITY_FAILED,
    DELETE_LOCALITY_REQUEST,
    DELETE_LOCALITY_SUCCESS,
    GET_LOCALITY_BY_NAME_FAILED,
    GET_LOCALITY_BY_NAME_REQUEST,
    GET_LOCALITY_BY_NAME_SUCCESS,
    GET_LOCALITY_FAILED,
    GET_LOCALITY_REQUEST,
    GET_LOCALITY_SUCCESS,
    UPDATE_LOCALITY_FAILED,
    UPDATE_LOCALITY_REQUEST,
    UPDATE_LOCALITY_SUCCESS,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    localityData: [],
    success: "",
    error: "",
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_LOCALITY_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_LOCALITY_SUCCESS:
        const {data, ...filters} = action.payload
        return {
          ...state,
          localityData: [...data],
          ...filters,
          loading: false,
          success: "GET",
        }
      case GET_LOCALITY_FAILED:
        return {
          ...state,
          error: "ERROR_LOCALITY",
          loading: false,
        }
      case GET_LOCALITY_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_LOCALITY_BY_NAME_SUCCESS:
        return {
          ...state,
          localityData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_LOCALITY_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_LOCALITY_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_LOCALITY_SUCCESS:
        return {
          ...state,
          localityData: [...state.localityData, action.payload],
          success: "CREATE",
          loading: false,
        }
      case CREATE_LOCALITY_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_LOCALITY_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_LOCALITY_SUCCESS:
        return {
          ...state,
          localityData: state.localityData.filter(
            locality => locality.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_LOCALITY_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_LOCALITY_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case UPDATE_LOCALITY_SUCCESS:
        return {
          ...state,
          localityData: state.localityData.map(locality =>
            locality.id.toString() === action.payload.id.toString()
              ? { locality, ...action.payload }
              : locality
          ),
          success: "UPDATE",
          loading: false,
        }
      case UPDATE_LOCALITY_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case LOCALITY_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_LOCALITY_MESSAGES_SUCCESS:
          return {
            ...state,
            error: null,
            success: null
          }
      default:
        return state
    }
  }
  
  export default reducer
  
