import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createMDMBannersFailed,
  createMDMBannersSuccess,
  deleteMDMBannersFailed,
  deleteMDMBannersSuccess,
  getMDMBannersFailed,
  getMDMBannersSuccess,
  updateMDMBannersFailed,
  updateMDMBannersSuccess,
} from "./actions"
import {
  BANNERS_MESSAGES_RESET,
  CREATE_BANNERS_REQUEST,
  DELETE_BANNERS_REQUEST,
  GET_BANNERS_BY_NAME_REQUEST,
  GET_BANNERS_REQUEST,
  RESET_BANNERS_MESSAGES,
  UPDATE_BANNERS_REQUEST
} from "./actionTypes";



import { getBanners, postBanners, putBanners } from "helpers/api_helper";



function* getBannersSaga({payload}) {
  try {
    const response = yield getBanners(payload || {page: 1, limit: 1000});
    yield put(getMDMBannersSuccess(response))
  } catch (e) {
    yield put(getMDMBannersFailed(e))
  }
}

function* getBannersBynameSaga() {

}

function* getBannersFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
    // console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getMDMBannersFailed(e))
  }
}

function* createBannersSaga({ payload: banners }) {
  try {
    const data = yield postBanners(banners);

    const response = yield getBanners({limit: 20, page: 1});

    yield put(getMDMBannersSuccess(response))

  } catch (e) {
    yield put(createMDMBannersFailed(e))
  }
}

function* deleteBannersSaga({ payload: id }) {
  try {
    // const result = yield new Repository().deleteCategory(payload)
    // const data = yield new Repository().getCategories({ current: 1, limit: 20 })
    yield put(deleteMDMBannersSuccess(id))
  } catch (e) {
    yield put(deleteMDMBannersFailed(e))
  }
}

function* updateBannersSaga({ payload: banners }) {
  try {
    // separer l id & objet Banners
    const { id, ...updatedBanners } = banners

    const data = yield putBanners(id, updatedBanners);
    const response = yield getBanners({limit: 20, page: 1});
    yield put(getMDMBannersSuccess(response))
  } catch (e) {
    yield put(updateMDMBannersFailed(e))
  }
}

function* resetBannersMessagesSaga() {
  yield put(resetBannersMessagesSuccess())
}




export default function* bannersSaga() {
  yield all([
    takeEvery(GET_BANNERS_REQUEST, getBannersSaga),
    takeEvery(GET_BANNERS_BY_NAME_REQUEST, getBannersBynameSaga),
    takeEvery(CREATE_BANNERS_REQUEST, createBannersSaga),
    takeEvery(DELETE_BANNERS_REQUEST, deleteBannersSaga),
    takeEvery(UPDATE_BANNERS_REQUEST, updateBannersSaga),
    takeEvery(RESET_BANNERS_MESSAGES, resetBannersMessagesSaga),
  ])
}
