import {
  ADS_MESSAGES_RESET,
  RESET_ADS_MESSAGES_SUCCESS,
  CREATE_ADS_FAILED,
  CREATE_ADS_REQUEST,
  CREATE_ADS_SUCCESS,
  DELETE_ADS_FAILED,
  DELETE_ADS_REQUEST,
  DELETE_ADS_SUCCESS,
  GET_ADS_BY_NAME_FAILED,
  GET_ADS_BY_NAME_REQUEST,
  GET_ADS_BY_NAME_SUCCESS,
  GET_ADS_FAILED,
  GET_ADS_REQUEST,
  GET_ADS_SUCCESS,
  UPDATE_ADS_FAILED,
  UPDATE_ADS_REQUEST,
  UPDATE_ADS_SUCCESS,
} from "./actionTypes";

export const initialState = {
  loading: false,
  adsData: [],
  success: "",
  error: null,
  success: null,
  loading: false,
  nextPage: null,
  previousPage: null,
  limit: null,
  total: null,
  currentPage: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADS_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
    case GET_ADS_SUCCESS:
      const { data, ...filters } = action.payload;
      // console.log("reducerAds",action.payload)
      return {
        ...state,
        adsData: [...data],
        ...filters,
        loading: false,
        success: "GET",
      };
    case GET_ADS_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case GET_ADS_BY_NAME_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      };
    case GET_ADS_BY_NAME_SUCCESS:
      return {
        ...state,
        adsData: action.payload,
        loading: false,
        success: "SUCCESS",
      };
    case GET_ADS_BY_NAME_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case CREATE_ADS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ADS_SUCCESS:
      return {
        ...state,
        adsData: [...state.adsData, action.payload],
        success: "CREATE",
        loading: false,
      };
    case CREATE_ADS_FAILED:
      return {
        ...state,
        error: "FAILED CREATE",
        loading: false,
      };
    case DELETE_ADS_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    case DELETE_ADS_SUCCESS:
      return {
        ...state,
        adsData: state.adsData.filter(
          (ads) => ads.id.toString() !== action.payload.toString()
        ),
        success: "DELETE",
        loading: false,
      };
    case DELETE_ADS_FAILED:
      return {
        ...state,
        error: "FAILED DELETE",
        loading: false,
      };
    case UPDATE_ADS_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    case UPDATE_ADS_SUCCESS:
      return {
        ...state,
        adsData: state.adsData.map((ads) =>
          ads.id.toString() === action.payload.id.toString()
            ? { ads, ...action.payload }
            : ads
        ),
        success: "UPDATE",
        loading: false,
      };
    case UPDATE_ADS_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      };
    case ADS_MESSAGES_RESET:
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    case RESET_ADS_MESSAGES_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    default:
      return state;
  }
}

export default reducer;
