
/**filter */
export const GET_SEEKERS_BY_NAME_SUCCESS = "GET_SEEKERS_BY_NAME_SUCCESS";
export const GET_SEEKERS_BY_NAME_REQUEST = "GET_SEEKERS_BY_NAME_REQUEST";
export const GET_SEEKERS_BY_NAME_FAILED = "GET_SEEKERS_BY_NAME_FAILED";

/**Get */
export const GET_SEEKERS_STATS_SUCCESS = 'GET_SEEKERS_STATS_SUCCESS';
export const GET_SEEKERS_STATS_REQUEST = 'GET_SEEKERS_STATS_REQUEST';
export const GET_SEEKERS_SUCCESS = 'GET_SEEKERS_SUCCESS';
export const GET_SEEKERS_REQUEST = 'GET_SEEKERS_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_SEEKERS_FAILED = 'GET_SEEKERS_FAILED';
export const GET_SEEKERS_STATS_FAILED = 'GET_SEEKERS_STATS_FAILED';

/**Create */
export const CREATE_SEEKERS_SUCCESS = 'CREATE_SEEKERS_SUCCESS';
export const CREATE_SEEKERS_FAILED = 'CREATE_SEEKERS_FAILED';
export const CREATE_SEEKERS_REQUEST = 'CREATE_SEEKERS_REQUEST';
/**Delete */
export const DELETE_SEEKERS_SUCCESS = 'DELETE_SEEKERS_SUCCESS';
export const DELETE_SEEKERS_FAILED = 'DELETE_SEEKERS_FAILED';
export const DELETE_SEEKERS_REQUEST = 'DELETE_SEEKERS_REQUEST';
/**Update */
export const UPDATE_SEEKERS_SUCCESS = 'UPDATE_SEEKERS_SUCCESS';
export const UPDATE_SEEKERS_FAILED = 'UPDATE_SEEKERS_FAILED';
export const UPDATE_SEEKERS_REQUEST = 'UPDATE_SEEKERS_REQUEST';

export const SEEKERS_MESSAGES_RESET = 'SEEKERS_MESSAGES_RESET';
export const SEEKERS_MESSAGES_SUCCESS = 'SEEKERS_MESSAGES_SUCCESS';
export const RESET_SEEKERS_MESSAGES_SUCCESS = 'RESET_SEEKERS_MESSAGES_SUCCESS';
export const RESET_SEEKERS_MESSAGES = 'RESET_SEEKERS_MESSAGES';

