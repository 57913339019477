import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import {
  createMDMCity,
  getMDMGovernorates,
  updateMDMCity,
} from "store/actions";

const ProfessionModal2 = (props) => {
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  });
  const dispatch = useDispatch();
  const { modal, toggle, Profession, isEdit } = props;
  const { governoratesData } = useSelector((state) => state.governorates);
  useEffect(() => {
    {
      dispatch(getMDMGovernorates());
    }
  }, [dispatch, filter]);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      photo: (Profession && Profession.photo) || "",
      name: (Profession && Profession.name) || "",
      parentId: (Profession && Profession.parentId) || "",
      type: (Profession && Profession.type) || "Profession",
    },
    validationSchema: Yup.object({
      photo: Yup.string().required(props.t("Please Enter Your French name")),
      name: Yup.string().required(props.t("Please Enter Your Arabic Name")),
      parentId: Yup.number().required(props.t("Please Enter Your Country")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCity = {
          id: City ? City.id : 0,
          id: values.id,
          photo: values.photo,
          name: values.name,
          parentId: values.parentId,
        };
        // update
        // console.log("updatecity",updateCity)
        // dispatch(updateMDMCity(updateCity))
        validation.resetForm();
      } else {
        const newCity = {
          id: values["id"],
          photo: values["photo"],
          name: values["name"],
          parentId: values["parentId"],
          type: values["type"],
        };
        // save new city
        // dispatch(createMDMCity(newCity))
        validation.resetForm();
      }
      toggle();
    },
  });

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit Professionnal") : props.t("Add Professionnal")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Row>
              <Col className="col-12">
                <Col className="col-11">
                  <div className="row">
                    <div className="col-6 mb-3">
                      <Label className="form-label">
                        {props.t("Département")}
                      </Label>
                      <Input
                        type="select"
                        name="department"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.parentId || []}
                        invalid={
                          validation.touched.parentId &&
                          validation.errors.parentId
                            ? true
                            : false
                        }
                      >
                        <option key="empty" disabled></option>
                        {/* {governoratesData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nameAr}
                    </option>
                  ))} */}
                      </Input>
                      {validation.touched.parentId &&
                      validation.errors.parentId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.parentId}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="col-6 mb-3">
                      <Label className="form-label">
                        {props.t("Profession")}
                      </Label>
                      <Input
                        type="select"
                        name="profession"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.profession || ""}
                        invalid={
                          validation.touched.profession &&
                          validation.errors.profession
                            ? true
                            : false
                        }
                      />
                      {validation.touched.profession &&
                      validation.errors.profession ? (
                        <FormFeedback type="invalid">
                          {validation.errors.profession}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Photo")}</Label>
                    <Input
                      name="photo"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.photo || ""}
                      invalid={
                        validation.touched.photo && validation.errors.photo
                          ? true
                          : false
                      }
                    />
                    {validation.touched.photo && validation.errors.photo ? (
                      <FormFeedback type="invalid">
                        {validation.errors.photo}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Nom")}</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Télèphone")}</Label>
                    <Input
                      name="telephone"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.telephone || ""}
                      invalid={
                        validation.touched.telephone &&
                        validation.errors.telephone
                          ? true
                          : false
                      }
                    />
                    {validation.touched.telephone &&
                    validation.errors.telephone ? (
                      <FormFeedback type="invalid">
                        {validation.errors.telephone}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-6 mb-3">
                      <Label className="form-label">{props.t("Prénom")}</Label>
                      <Input
                        name="prenom"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.prenom || ""}
                        invalid={
                          validation.touched.prenom && validation.errors.prenom
                            ? true
                            : false
                        }
                      />
                      {validation.touched.prenom && validation.errors.prenom ? (
                        <FormFeedback type="invalid">
                          {validation.errors.prenom}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="col-6 mb-3">
                      <Label className="form-label">
                        {props.t("Nom de famille")}
                      </Label>
                      <Input
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      {props.t("Mot de passe")}
                    </Label>
                    <Input
                      name="pwd"
                      type="password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.pwd || ""}
                      invalid={
                        validation.touched.pwd && validation.errors.pwd
                          ? true
                          : false
                      }
                    />
                    {validation.touched.pwd && validation.errors.pwd ? (
                      <FormFeedback type="invalid">
                        {validation.errors.pwd}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">{props.t("Email")}</Label>
                    <Input
                      name="email"
                      type="text"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-4 mb-3">
                      <Label className="form-label">
                        {props.t("Gouvernorat")}
                      </Label>
                      <Input
                        type="select"
                        name="gouvernorat"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gouvernorat || []}
                        invalid={
                          validation.touched.gouvernorat &&
                          validation.errors.gouvernorat
                            ? true
                            : false
                        }
                      >
                        <option key="empty" disabled></option>
                        {/* {governoratesData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nameAr}
                    </option>
                  ))} */}
                      </Input>
                      {validation.touched.gouvernorat &&
                      validation.errors.gouvernorat ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gouvernorat}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="col-4 mb-3">
                      <Label className="form-label">
                        {props.t("Délégation")}
                      </Label>
                      <Input
                        type="select"
                        name="delegation"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.delegation || ""}
                        invalid={
                          validation.touched.delegation &&
                          validation.errors.delegation
                            ? true
                            : false
                        }
                      />
                      {validation.touched.delegation &&
                      validation.errors.delegation ? (
                        <FormFeedback type="invalid">
                          {validation.errors.delegation}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="col-4 mb-3">
                      <Label className="form-label">
                        {props.t("Localité")}
                      </Label>
                      <Input
                        type="select"
                        name="localite"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.localite || ""}
                        invalid={
                          validation.touched.localite &&
                          validation.errors.localite
                            ? true
                            : false
                        }
                      />
                      {validation.touched.localite &&
                      validation.errors.localite ? (
                        <FormFeedback type="invalid">
                          {validation.errors.localite}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Adresse")}</Label>
                    <Input
                      name="adresse"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.adresse || ""}
                      invalid={
                        validation.touched.adresse && validation.errors.adresse
                          ? true
                          : false
                      }
                    />
                    {validation.touched.adresse && validation.errors.adresse ? (
                      <FormFeedback type="invalid">
                        {validation.errors.adresse}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-6 mb-3">
                      <Label className="form-label">{props.t("Gender")}</Label>
                      <Input
                        name="prenom"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.prenom || ""}
                        invalid={
                          validation.touched.prenom && validation.errors.prenom
                            ? true
                            : false
                        }
                      />
                      {validation.touched.prenom && validation.errors.prenom ? (
                        <FormFeedback type="invalid">
                          {validation.errors.prenom}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Date de naissance")}</Label>
                    <Input
                      name="date"
                      type="text"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.date || ""}
                      invalid={
                        validation.touched.date && validation.errors.date
                          ? true
                          : false
                      }
                    />
                    {validation.touched.date && validation.errors.date ? (
                      <FormFeedback type="invalid">
                        {validation.errors.date}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Ajouté par")}</Label>
                    <Input
                      name="AddBy"
                      type="text"
                      valiAddBy={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.AddBy || ""}
                      invalid={
                        validation.touched.AddBy && validation.errors.AddBy
                          ? true
                          : false
                      }
                    />
                    {validation.touched.AddBy && validation.errors.AddBy ? (
                      <FormFeedback type="invalid">
                        {validation.errors.AddBy}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-customer">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(ProfessionModal2);
