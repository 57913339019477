import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createSeekersFailed,
  createSeekersSuccess,
  deleteSeekersFailed,
  deleteSeekersSuccess,
  getSeekersFailed,
  getSeekersStatsFailed,
  getSeekersStatsSuccess,
  getSeekersSuccess,
  updateSeekersFailed,
  updateSeekersSuccess,
} from "./actions"
import {
  SEEKERS_MESSAGES_RESET,
  CREATE_SEEKERS_REQUEST,
  DELETE_SEEKERS_REQUEST,
  GET_SEEKERS_BY_NAME_REQUEST,
  GET_SEEKERS_REQUEST,
  RESET_SEEKERS_MESSAGES,
  UPDATE_SEEKERS_REQUEST,
  GET_SEEKERS_STATS_REQUEST
} from "./actionTypes";



import { getSeeker, getSeekerStats, postSeeker, putSeeker } from "helpers/api_helper";



function* getSeekersSaga({payload}) {
  try {
    const response = yield getSeeker(payload || {page: 1, limit: 1000});
    yield put(getSeekersSuccess(response))
  } catch (e) {
    yield put(getSeekersFailed(e))
  }
}

function* getSeekerStatsSaga() {
  try {
    const response = yield getSeekerStats();
    yield put(getSeekersStatsSuccess(response))
  } catch (e) {
    yield put(getSeekersStatsFailed(e))
  }
}

function* getSeekersBynameSaga() {

}



function* createSeekersSaga({ payload: Seeker }) {
  try {
    const data = yield postSeeker(Seeker);

    const response = yield getSeeker({limit: 20, page: 1});

    yield put(getSeekersSuccess(response))

  } catch (e) {
    yield put(createSeekersFailed(e))
  }
}

function* deleteSeekersSaga( payload ) {
  try {
    const result = yield delSeeker(payload.payload.id)
    const data = yield getSeeker({ current: 1, limit: 20 })
    yield put(getSeekersSuccess(data))
  } catch (e) {
    yield put(deleteSeekersFailed(e))
  }
}

function* updateSeekersSaga({ payload: Seeker }) {
  try {
    // separer l id & objet Seekers
    const { id, ...updatedSeeker } = Seeker

    const data = yield putSeeker(id, updatedSeeker);
    const response = yield getSeeker({limit: 20, page: 1});
    yield put(getSeekersSuccess(response))
  } catch (e) {
    yield put(updateSeekersFailed(e))
  }
}

function* resetSeekersMessagesSaga() {
  yield put(resetSeekersMessagesSuccess())
}




export default function* seekerSaga() {
  yield all([
    takeEvery(GET_SEEKERS_REQUEST, getSeekersSaga),
    takeEvery(GET_SEEKERS_STATS_REQUEST, getSeekerStatsSaga),
    takeEvery(GET_SEEKERS_BY_NAME_REQUEST, getSeekersBynameSaga),
    takeEvery(CREATE_SEEKERS_REQUEST, createSeekersSaga),
    takeEvery(DELETE_SEEKERS_REQUEST, deleteSeekersSaga),
    takeEvery(UPDATE_SEEKERS_REQUEST, updateSeekersSaga),
    takeEvery(RESET_SEEKERS_MESSAGES, resetSeekersMessagesSaga),
  ])
}
