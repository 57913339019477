import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createMDMLocalityFailed,
  deleteMDMLocalityFailed,
  deleteMDMLocalitySuccess,
  getMDMLocalityFailed,
  getMDMLocalitySuccess,
  updateMDMLocalityFailed,
} from "./actions"
import {
  CREATE_LOCALITY_REQUEST,
  DELETE_LOCALITY_REQUEST,
  GET_LOCALITY_BY_NAME_REQUEST,
  GET_LOCALITY_REQUEST,
  RESET_LOCALITY_MESSAGES,
  UPDATE_LOCALITY_REQUEST
} from "./actionTypes";



import { getLocality, postLocality, putLocality } from "helpers/api_helper";



function* getLocalitySaga({payload}) {
  try {
    const response = yield getLocality(payload || {page: 1, limit: 1000});
    yield put(getMDMLocalitySuccess(response))
  } catch (e) {
    yield put(getMDMLocalityFailed(e))
  }
}

function* getLocalityBynameSaga() {

}

function* getLocalityFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
    // console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getMDMLocalityFailed(e))
  }
}

function* createLocalitySaga({ payload: locality }) {
  try {
    const data = yield postLocality(locality);
    // console.log("data",data)
    const response = yield getLocality({limit: 20, page: 1});
    // console.log("response",response)
    yield put(getMDMLocalitySuccess(response))

  } catch (e) {
    yield put(createMDMLocalityFailed(e))
  }
}

function* deleteLocalitySaga({ payload: id }) {
  try {
    // const result = yield new Repository().deleteCategory(payload)
    // const data = yield new Repository().getCategories({ current: 1, limit: 20 })
    yield put(deleteMDMLocalitySuccess(id))
  } catch (e) {
    yield put(deleteMDMLocalityFailed(e))
  }
}

function* updateLocalitySaga({ payload: locality }) {
  try {
    // separer l id & objet Locality
    const { id, ...updatedLocality } = locality

    const data = yield putLocality(id, updatedLocality);
    const response = yield getLocality({limit: 20, page: 1});
    yield put(getMDMLocalitySuccess(response))
  } catch (e) {
    // console.log("res",e.response)
    yield put(getMDMLocalityFailed(e.response))
  }
}

function* resetLocalityMessagesSaga() {
  yield put(resetLocalityMessagesSuccess())
}




export default function* localitySaga() {
  yield all([
    takeEvery(GET_LOCALITY_REQUEST, getLocalitySaga),
    takeEvery(GET_LOCALITY_BY_NAME_REQUEST, getLocalityBynameSaga),
    takeEvery(CREATE_LOCALITY_REQUEST, createLocalitySaga),
    takeEvery(DELETE_LOCALITY_REQUEST, deleteLocalitySaga),
    takeEvery(UPDATE_LOCALITY_REQUEST, updateLocalitySaga),
    takeEvery(RESET_LOCALITY_MESSAGES, resetLocalityMessagesSaga),
  ])
}
