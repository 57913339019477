import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import * as Yup from "yup"
import { map } from "lodash"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"

import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"


//Import Images
import profile1 from "assets/images/users/avatar-6.jpg"
import bg from "assets/images/profile-img.png"
// import charts
import {
  getUserProfile,
  updateProfessionnal,
  updateProfil,
} from "store/actions"

import {geocodeByLatLng } from 'react-google-places-autocomplete';
import moment from "moment"
import ApexRevenue from "pages/Users/ApexRevenue"
import ReactSwitch from "react-switch"
import { useFormik } from "formik"
import SuccessModal from "components/Common/SuccessModal"
function loadScript(src, position, id) {
  if (!position) {
      return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}
const ProfessionProfile = props => {
    
  //meta title
  document.title = "Adresstreet"
  const [location, setLocation] = useState(null)
  const googleScript = document.getElementById('__googleMapsScriptId')
  const params = useParams()
  const id = params.id
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")

  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")
 
  const dispatch = useDispatch()
 const { userProfile, success, error } = useSelector(state => state.users)

 const loaded = React.useRef(false);

 if (typeof window !== 'undefined' && !loaded.current) {
     if (!document.querySelector('#__googleMapsScriptId')) {
         loadScript(
             `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyBs7pFjOBMNQzMbjvyxaHrRw7q3Sn2Gi6Y"}&libraries=places`,
             document.querySelector('head'),
             '__googleMapsScriptId',
         );
     }

     loaded.current = true;
 }

  useEffect(() => {
    if (id) {
      dispatch(getUserProfile(id))
    }
  }, [])

// console.log("profileee",location)
useEffect(() => {
  if (userProfile?.latitude && userProfile?.langitude) {
    console.log("bef",userProfile?.latitude , userProfile?.langitude)
    if (window.google) {
      console.log("long",userProfile?.latitude , userProfile?.langitude)
      geocodeByLatLng({ lat: parseFloat(userProfile.latitude), lng: parseFloat(userProfile.langitude) })
        .then(results => {
          setLocation({ value: results[0], label: results[0].formatted_address })
        })
        .catch(error => console.error(error));
    }

    googleScript?.addEventListener('load', () => {
      // Patiently waiting to do the thing 
      console.log("longgg",userProfile?.latitude , userProfile?.langitude)
      geocodeByLatLng({ lat: parseFloat(userProfile.latitude), lng: parseFloat(userProfile.langitude) })
        .then(results => {
          setLocation({ value: results[0], label: results[0].formatted_address })
        })
        .catch(error => console.error(error));
    })

  }
  return () => {
    setLocation(null)
  }
}, [userProfile, googleScript])


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      latitude: (userProfile && userProfile.latitude) || "",
      langitude: (userProfile && userProfile.langitude) || "",
    },
    validationSchema: Yup.object({
      
    }),
    onSubmit: values => {
      const updatedSalesCommission = {
        id: userProfile.id,
        
      }

      // update order
    //   dispatch(updateBusinessPartner(updatedSalesCommission))
      dispatch(updateProfil(updatedSalesCommission))
    },
  })


  const ChangeStatus = status => {
   
    if (status) {
      // console.log("status if true",status)
      dispatch(updateProfil({ id: userProfile.id, status: "active" }))
      dispatch(updateProfessionnal({ id: userProfile.id, status: "active" }))
    } else {
      // console.log("status if false",status)
      dispatch(updateProfil({ id: userProfile.id, status: "inactive" }))
      dispatch(
        updateProfessionnal({ id: userProfile.id, status: "inactive" })
      )
    }
  }
 
  useEffect(() => {
    
    dispatch(getUserProfile(id))
    if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("le profil est modifié avec succés !")
  
    }
    
  }, [success])
 
  return (
    <React.Fragment>
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("Professionnels")} 
            breadcrumbItem={props.t("Profile")}
          />

          <Row>
            <Col xl="6">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        {/* <h5 className="text-primary">Welcome!</h5> */}
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={bg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="12">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={userProfile.image_url || profile1}
                          alt=""
                          className="img-thumbnail font-size-10  rounded-circle"
                        />
                      </div>
                      <br />
                      <br />
                      <Row>
                        <Col xl="6">
                          <h5 className="font-size-15 text-truncate mb-3">
                            {userProfile.firstname +
                              " " +
                              userProfile.lastname +
                              "(" +
                              userProfile.phone +
                              ")"}
                          </h5>
                        
                          <p className="text-muted font-size-15 mb-0 text-truncate">
                            {userProfile.accountType}
                          </p>
                          <br></br>
                        
                        </Col>
                        <Col xl="4">
                          <h5 className="font-size-15 text-truncate">
                            {props.t("Status")}
                          </h5>
                          <Col xl="3">
                            <ReactSwitch
                              onColor="#34c38f"
                              onChange={ChangeStatus}
                              checked={
                                userProfile.status == "active" ? true : false
                              }
                            />
                          </Col>
                          <br></br>
                          <br></br>
                          <Col xl="12">
                            {userProfile?.isSalesPartner && (
                              <Row form>
                                <Col xl="8">
                                  <Label className="form-label">
                                    {props.t("Sales Commission")}
                                  </Label>
                                </Col>
                                <Col xl="9">
                                  <div className="mb-3">
                                    <Input
                                      name="salesCommission"
                                      type="number"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.salesCommission || ""
                                      }
                                      invalid={
                                        validation.touched.salesCommission &&
                                        validation.errors.salesCommission
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.salesCommission &&
                                    validation.errors.salesCommission ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.salesCommission}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl="3">
                                  <Button
                                    onClick={validation.submitForm}
                                    type="primary"
                                    disabled={
                                      !validation.values?.salesCommission
                                    }
                                  >
                                    {props.t("Save")}
                                  </Button>
                                  {console.log(
                                    "userProfile.salesCommsion",
                                    userProfile.salesCommission
                                  )}
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Personal Information")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{props.t("Full Name")} :</th>
                          <td>
                            {userProfile.firstname + " " + userProfile.lastname}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Status")} :</th>
                          <td>
                            {userProfile.status || props.t("Non fourni")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Type")} :</th>
                          <td>
                            {userProfile.accountType || props.t("Non fourni")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Profession")} :</th>
                          <td>
                            {userProfile.profession?.name || props.t("Non fourni")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Mobile")} :</th>
                          <td>
                            {userProfile.phone || props.t("Non fourni")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("E-mail")} :</th>
                          <td>{userProfile.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Joined")} :</th>
                          <td>
                            {moment(new Date(userProfile.createdAt)).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="6">
            
            </Col>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  {props.t("Contact Details")}
                </CardTitle>

                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">{props.t("Address")} :</th>
                      <td>{location?.label || props.t("Non fourni")}</td>
                    </tr>
                   
                  
                    <tr>
                      <th scope="row">{props.t("Governorate")} :</th>
                      <td>
                        {" "}
                        { userProfile.governorate?.name||
                          props.t("Non fourni")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{props.t("Délégation")} :</th>
                      <td>
                        {" "}
                        { userProfile.delegation?.name||
                          props.t("Non fourni")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{props.t("Localité")} :</th>
                      <td>
                       
                          {userProfile.locality?.name ||
                          props.t("Non fourni")}
                      </td>
                    </tr>
                   
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProfessionProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(withTranslation()(ProfessionProfile))
