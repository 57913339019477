
import {
    ADS_MESSAGES_RESET,
    CREATE_ADS_FAILED,
    CREATE_ADS_REQUEST,
    CREATE_ADS_SUCCESS,
    DELETE_ADS_FAILED,
    DELETE_ADS_REQUEST,
    DELETE_ADS_SUCCESS,
    GET_ADS_BY_NAME_FAILED,
    GET_ADS_BY_NAME_REQUEST,
    GET_ADS_BY_NAME_SUCCESS,
    GET_ADS_FAILED,
    GET_ADS_REQUEST,
    GET_ADS_SUCCESS,
    UPDATE_ADS_FAILED,
    UPDATE_ADS_REQUEST,
    RESET_ADS_MESSAGES,
    RESET_ADS_MESSAGES_SUCCESS,
    UPDATE_ADS_SUCCESS
} from "./actionTypes.js"

export const getAdsByName = name => ({
    type: GET_ADS_BY_NAME_REQUEST,
    payload: name
})


export const getAdsByNameSuccess = payload => ({
    type: GET_ADS_BY_NAME_SUCCESS,
    payload
})


export const getAdsByNameFailed = payload => ({
    type: GET_ADS_BY_NAME_FAILED,
    payload
})




export const getMDMAds = (filter) => ({
    type: GET_ADS_REQUEST,
    payload:filter
})



export const getMDMAdsSuccess = ads => ({
    type: GET_ADS_SUCCESS,
    payload: ads
})

export const getMDMAdsFailed = payload => ({
    type: GET_ADS_FAILED,
    payload
})

export const createMDMAds = ads => ({
    type: CREATE_ADS_REQUEST,
    payload: ads
})

export const createMDMAdsSuccess = ads => ({
    type: CREATE_ADS_SUCCESS,
    payload: ads
})

export const createMDMAdsFailed = payload => ({
    type: CREATE_ADS_FAILED,
    payload
})

export const deleteMDMAds= id => ({
    type: DELETE_ADS_REQUEST,
    payload: id
})

export const deleteMDMAdsSuccess = id => ({
    type: DELETE_ADS_SUCCESS,
    payload: id
})

export const deleteMDMAdsFailed = id => ({
    type: DELETE_ADS_FAILED,
    payload: id
})

export const updateMDMAds = (ads )=> ({
    type: UPDATE_ADS_REQUEST,
    payload: ads
})

export const updateMDMAdsSuccess = (ads ) => ({
    type: UPDATE_ADS_SUCCESS,
    payload: ads
})

export const updateMDMAdsFailed = payload => ({
    type: UPDATE_ADS_FAILED,
    payload
})

export const resetMDMAdsMessages = () => ({
    type: ADS_MESSAGES_RESET
}
)


export const resetAdsMessages = () => ({
    type: RESET_ADS_MESSAGES,
  })
  
  export const resetAdsMessagesSuccess = () => ({
    type: RESET_ADS_MESSAGES_SUCCESS,
  })

