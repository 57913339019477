import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"

// Column
import { EnName } from "./CountryCol"
import {
  getMDMCountries,
  resetCountryMessages,
  updateMDMCountry,
} from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"

import CountryModal from "./CountryModal"

const Country = props => {
  const {
    countriesData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading
  } = useSelector(state => state.countries)
  const userRoles = useSelector(state => state.Login.user?.roles)

  //meta title
  document.title = "Adresstreet"

  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  const [modal, setModal] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [country, setCountry] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")



  const handleCountryClick = arg => {
    const country = arg

    setCountry({
      id: country.id,
      name: country.name,
      
    })

    setIsEdit(true)
    toggle()
  }

  // Countries Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Name"),
        accessor: "name",
        disableFilters: true,
        Cell: cellProps => {
          return <EnName {...cellProps} />
        },
      },
      {
        Header: props.t("Statut"),
        accessor: "status",
        disableFilters: true,
        
      },
   
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              
                <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const countryData = cellProps.row.original
                    handleCountryClick(countryData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
            
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const countryData = cellProps.row.original
                    onClickDelete(countryData)
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCountry(null)
    } else {
      setModal(true)
    }
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = country => {
    setCountry(country)
    setDeleteModal(true)
  }

  const handleDeleteCountry = () => {
    if (country.id) {
      dispatch(updateMDMCountry({ id: country.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getMDMCountries())
    }
  }

  useEffect(() => {

    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Country created successfully !")
      dispatch(resetCountryMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Successfully !")
      dispatch(resetCountryMessages())
    }
  }, [success])

  useEffect(() => {
    {
      dispatch(getMDMCountries(filter))
      // console.log("country",countriesData)

    }
  }, [dispatch, filter])

  useEffect(() => {
    setCountryList(countriesData)
  }, [countriesData])

  useEffect(() => {
    if (!isEmpty(countriesData)) {
      setCountryList(countriesData)
    }
  }, [countriesData])

  const handleCountryClicks = () => {
    setCountryList("")
    setIsEdit(false)
    toggle()
  }

  const setPageNumber = (activePage) => { setFilter(prevFilter => { return { ...prevFilter, page: activePage } }) }
  const handleLimit = (limit) => { setFilter(prevFilter => { return { ...prevFilter, limit: limit } }) }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCountry}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("MDM")} breadcrumbItem={props.t("Countries List")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={countriesData}
                    isGlobalFilter={true}
                    isAddCustList={true}
                    handelAddClick={handleCountryClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
               <CountryModal modal={modal} toggle={toggle} isEdit={isEdit} country={country} />
              
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Country)
