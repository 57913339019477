import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  createMDMProfessionFailed,
  deleteMDMProfessionFailed,
  deleteMDMProfessionSuccess,
  getMDMProfessionFailed,
  getMDMProfessionSuccess,
  updateMDMProfessionFailed,
} from "./actions"
import {
  CREATE_PROFESSION_REQUEST,
  DELETE_PROFESSION_REQUEST,
  GET_PROFESSION_BY_NAME_REQUEST,
  GET_PROFESSION_REQUEST,
  RESET_PROFESSION_MESSAGES,
  UPDATE_PROFESSION_REQUEST
} from "./actionTypes";



import { delProfession, getProfession, postProfession, putProfession } from "helpers/api_helper";



function* getProfessionSaga({payload}) {
  try {
    const response = yield getProfession(payload || {page: 1, limit: 1000});
    yield put(getMDMProfessionSuccess(response))
  } catch (e) {
    yield put(getMDMProfessionFailed(e))
  }
}

function* getProfessionBynameSaga() {
}

function* getProfessionFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
     //console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getMDMProfessionFailed(e))
  }
}

function* createProfessionSaga({ payload: Profession }) {
  try {
    const data = yield postProfession(Profession);
    // console.log("data",data)
    const response = yield getProfession({limit: 20, page: 1});
     //console.log("response",response)
    yield put(getMDMProfessionSuccess(response))

  } catch (e) {
    yield put(createMDMProfessionFailed(e))
  }
}

function* deleteProfessionSaga( payload) {
  try {
    const result = yield delProfession(payload.payload.id)
    const data = yield getProfession({ current: 1, limit: 20 })
    yield put(getMDMProfessionSuccess(data))
   
  } catch (e) {
    yield put(deleteMDMProfessionFailed(e))
  }
}

function* updateProfessionSaga({ payload: profession }) {
  try {
    // separer l id & objet Profession
    const { id, ...updatedProfession } = profession

    const data = yield putProfession(id, updatedProfession);
    const response = yield getProfession({limit: 20, page: 1});
    yield put(getMDMProfessionSuccess(response))
  } catch (e) {
    // console.log("error",e.response)
    yield put(getMDMProfessionFailed(e.response))
  }
}

function* resetProfessionMessagesSaga() {
  yield put(resetProfessionMessagesSuccess())
}




export default function* professionSaga() {
  yield all([
    takeEvery(GET_PROFESSION_REQUEST, getProfessionSaga),
    takeEvery(GET_PROFESSION_BY_NAME_REQUEST, getProfessionBynameSaga),
    takeEvery(CREATE_PROFESSION_REQUEST, createProfessionSaga),
    takeEvery(DELETE_PROFESSION_REQUEST, deleteProfessionSaga),
    takeEvery(UPDATE_PROFESSION_REQUEST, updateProfessionSaga),
    takeEvery(RESET_PROFESSION_MESSAGES, resetProfessionMessagesSaga),
  ])
}
