import axios from "axios";
import store from "../store";
import config from "../config"

//apply base url for axios
const API_URL = config.apiUrl 
const axiosApi = axios.create({
  baseURL: API_URL,
});
axiosApi.defaults.headers.Accept = 'application/json'
axiosApi.defaults.headers.post['Content-Type'] = 'application/json';
axiosApi.interceptors.request.use(
  (config) => {
    const {
      Login: { accessToken },
    } = store.getState()

    if (accessToken) {
      config.headers.token = `${accessToken}`
    }

    return config
  },
  (err) => Promise.reject(err)
)



export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function uploadFile(url, data, config = {}) {
  return axiosApi
    .post(url, data , { ...config })
    .then(response => response.data);
}


export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
