import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import MiniCards from "pages/Dashboard/mini-card";
import MiniCardss from "pages/Dashboard/mini-cards";
import { map } from "lodash";
import { getDashboard, getEvaluation, getProfessionnal } from "store/actions";
import { getSeekers } from "store/actions";

const Dashboard = (props) => {
  const history = useHistory();
  const [subscribemodal, setSubscribemodal] = useState(false);
  const { dasboardData } = useSelector(state =>state.users);
  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, []);

 
  const dispatch = useDispatch();
  useEffect(() => {
    {
      dispatch(getDashboard());
    }
  }, []);

  
  //meta title
  document.title = "Adresstreet";
  
  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Professionnels inscrits"),
      iconClass: "bx bxs-chevron-up-square",
      text: 0,
      color: "warning",
    },
    {
      title: props.t("Chercheurs Inscrits"),
      iconClass: "bx bx-comment-check",
      text: 0,
      color: "info",
    },
    {
      title: props.t("Evaluations réalisées"),
      iconClass: "bx bx-comment-check",
      text: 0,
      color: "danger",
    },
  ]);
  

  useEffect(() => {
  setMiniCards([
    {
      title: props.t("Professionnels inscrits"),
      iconClass: "bx bxs-chevron-up-square",
      text: dasboardData?.professionals,
      color: "warning",
    },
    {
      title: props.t("Chercheurs Inscrits"),
      iconClass: "bx bx-comment-check",
      text: dasboardData?.seekers,
      color: "info",
    },
    {
      title: props.t("Evaluations réalisées"),
      iconClass: "bx bx-comment-check",
      text: dasboardData?.totalEvals,
      color: "danger",
    },
  ]);

}, [dasboardData])





// ?.stats?.prodelegations?.map(el => el.delegation?.name)

// console.log("********************",dasboardData?.stats?.proDelegations?.map(el => el.delegation?.name?.split(','))
//  )


  const [miniCardss, setMiniCardss] = useState([
    {
      title: props.t("Top Délégations des professionnels"),
      text:0,
      color: "white",
    },
    {
      title: props.t("Top Départements"),
      iconClass: "bx bx-comment-check",
      text:0,
      color: "info",
    },
    {
      title: props.t("Top Délégations des Chercheurs"),
      iconClass: "bx bx-comment-check",
      text: 0,
      color: "danger",
    },
  ]);

  useEffect(() => {
  setMiniCardss([
    {
      title: props.t("Top Délégations des professionnels"),
      text:dasboardData?.stats?.proDelegations?.map((el,index) => <div className="mb-1" key={index}>{el?.delegation?.name}</div> ),
      color: "white",
    },
    {
      title: props.t("Top Départements"),
      iconClass: "bx bx-comment-check",
      text: dasboardData?.stats?.proDepartments?.map((el,index) => <div className="mb-1" key={index}>{ el?.department?.name}</div> ),
      color: "info",
    },
    {
      title: props.t("Top Délégations des Chercheurs"),
      iconClass: "bx bx-comment-check",
      text: dasboardData?.stats?.seekersDelegations?.map((el,index) => <div className="mb-1" key={index}>{ el?.delegation?.name}</div> ),
      color: "danger",
    },
  ]);

}, [dasboardData])




  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Dashboards")}
          breadcrumbItem={props.t("Dashboards")}
        />

        <Col xl="12">
          <Row>
            {map(miniCards, (card, key) => (
              <MiniCards
                title={card.title}
                text={card.text}
                iconClass={card.iconClass}
                key={"_card_" + key}
              />
            ))}
          </Row>
        </Col>
        <br />
        <br />
        <br />
        <Col xl="12">
          <Row>
            {map(miniCardss, (card, key) => (
              <MiniCardss
                title={card.title}
                text={card.text}
                iconClass={card.iconClass}
                key={"_card_" + key}
              />
            ))}
          </Row>
        </Col>
        <br />
        <br />
        <br />
        <Row>
          <Col>
            <br />

            <Card className="rounded-2 shadow">
              <CardBody>
                <Row className="justify-content-between">
                  <Col xl="1">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/users-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"bx bx-user font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("USERS")}
                      </p>
                    </Row>
                  </Col>
                  <Col xl="2">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/professionnals-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"bx bx bx-store-alt font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("Professionnels")}
                      </p>
                    </Row>
                  </Col>
                  <Col xl="2">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/seekers-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"bx bx bx-mobile-alt font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("Chercheurs")}
                      </p>
                    </Row>
                  </Col>
                  <Col xl="1">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/searchResults-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"bx bx-transfer font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("CRM")}
                      </p>
                    </Row>
                  </Col>
                  <Col xl="1">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/publicity-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"dripicons-document font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("Publicité")}
                      </p>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
