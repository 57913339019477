
/**filter */
export const GET_DELEGATION_BY_NAME_SUCCESS = "GET_DELEGATION_BY_NAME_SUCCESS";
export const GET_DELEGATION_BY_NAME_REQUEST = "GET_DELEGATION_BY_NAME_REQUEST";
export const GET_DELEGATION_BY_NAME_FAILED = "GET_DELEGATION_BY_NAME_FAILED";

/**Get */
export const GET_DELEGATION_SUCCESS = 'GET_DELEGATION_SUCCESS';
export const GET_DELEGATION_REQUEST = 'GET_DELEGATION_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_DELEGATION_FAILED = 'GET_DELEGATION_FAILED';

/**Create */
export const CREATE_DELEGATION_SUCCESS = 'CREATE_DELEGATION_SUCCESS';
export const CREATE_DELEGATION_FAILED = 'CREATE_DELEGATION_FAILED';
export const CREATE_DELEGATION_REQUEST = 'CREATE_DELEGATION_REQUEST';
/**Delete */
export const DELETE_DELEGATION_SUCCESS = 'DELETE_DELEGATION_SUCCESS';
export const DELETE_DELEGATION_FAILED = 'DELETE_DELEGATION_FAILED';
export const DELETE_DELEGATION_REQUEST = 'DELETE_DELEGATION_REQUEST';
/**Update */
export const UPDATE_DELEGATION_SUCCESS = 'UPDATE_DELEGATION_SUCCESS';
export const UPDATE_DELEGATION_FAILED = 'UPDATE_DELEGATION_FAILED';
export const UPDATE_DELEGATION_REQUEST = 'UPDATE_DELEGATION_REQUEST';

export const DELEGATION_MESSAGES_RESET = 'DELEGATION_MESSAGES_RESET';
export const DELEGATION_MESSAGES_SUCCESS = 'DELEGATION_MESSAGES_SUCCESS';
export const RESET_DELEGATION_MESSAGES_SUCCESS = 'RESET_DELEGATION_MESSAGES_SUCCESS';
export const RESET_DELEGATION_MESSAGES = 'RESET_DELEGATION_MESSAGES';

