import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  Spinner,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng, geocodeByLatLng } from 'react-google-places-autocomplete';

import {
  createProfessionnal,
  getMDMDelegation,
  getMDMDepartment,
  getMDMGovernorates,
  getMDMLocality,
  getMDMProfession,
  updateProfessionnal,
} from "store/actions";
import { upload } from "helpers/api_helper";

const ProfessionModal = (props) => {
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10000,
  });
  const [location, setLocation] = useState(null)
  const googleScript = document.getElementById('__googleMapsScriptId')
  const [notValidLocation, setNotValidLocation] = useState(false)

  const dispatch = useDispatch();
  const { modal, toggle, Professionnal, isEdit } = props;
  const { departmentData } = useSelector(state => state.departments)
  const { governoratesData } = useSelector((state) => state.governorates);
  const { delegationData } = useSelector((state) => state.delegations);
  const { localityData } = useSelector((state) => state.localities);
  const { professionData } = useSelector((state) => state.professions);
  const { user } = useSelector((state) => state.Login);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    {
      dispatch(getMDMGovernorates());
      dispatch(getMDMDepartment());
      dispatch(getMDMProfession());
      dispatch(getMDMDelegation());
      dispatch(getMDMLocality());
    }
  }, [dispatch, filter]);

  useEffect(() => {
    if (Professionnal?.latitude && Professionnal?.langitude) {
      if (window.google) {
        geocodeByLatLng({ lat: parseFloat(Professionnal.latitude), lng: parseFloat(Professionnal.langitude) })
          .then(results => {
            setLocation({ value: results[0], label: results[0].formatted_address })
          })
          .catch(error => console.error(error));
      }
      googleScript?.addEventListener('load', () => {
        // Patiently waiting to do the thing 

        geocodeByLatLng({ lat: parseFloat(Professionnal.latitude), lng: parseFloat(Professionnal.langitude) })
          .then(results => {
            setLocation({ value: results[0], label: results[0].formatted_address })
          })
          .catch(error => console.error(error));
      })

    }
    return () => {
      setLocation(null)
    }
  }, [Professionnal, googleScript])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: (Professionnal && Professionnal.firstname) || "",
      cin: (Professionnal && Professionnal.cin) || "",
      diplome: (Professionnal && Professionnal.diplome) || "",
      description: (Professionnal && Professionnal.description) || "",
      lastname: (Professionnal && Professionnal.lastname) || "",
      image_url: (Professionnal && Professionnal.image_url) || "",
      governorateId: (Professionnal && Professionnal.governorateId) || "",
      delegationId: (Professionnal && Professionnal.delegationId) || "",
      localityId: (Professionnal && Professionnal.localityId) || "",
      phone: (Professionnal && Professionnal.phone) || "",
      secondPhone: (Professionnal && Professionnal.secondPhone) || "",
      email: (Professionnal && Professionnal.email) || "",
      professionId: (Professionnal && Professionnal.professionId) || "",
      address: (Professionnal && Professionnal.address) || "",
      secondAddress: (Professionnal && Professionnal.secondAddress) || "",
      latitude: (Professionnal && Professionnal.latitude) || "",
      langitude: (Professionnal && Professionnal.langitude) || "",
      accountType: (Professionnal && Professionnal.accountType) || "professionals",
      addedBy: (Professionnal && Professionnal.addedBy) || "",
      password: "",
      googleLocation: ""
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(props.t("Please Enter Your First Name")),
      lastname: Yup.string().required(props.t("Please Enter Your Last Name")),
      phone: Yup.string().matches(/^[0-9]{8}$/, 'Utilisez 8 chiffres pour votre numéro de téléphone'),
      ...(!isEdit && {password: Yup.string().required(props.t("Please Enter Your Password"))}),
      professionId: Yup.string().required(props.t("Please Enter the profession")),
      governorateId: Yup.string().required(props.t("Please Enter Your governorate")),
      // delegationId: Yup.string().required(
      //   props.t("Please Enter Your délégation")
      // ),
      ...(!isEdit && {googleLocation: Yup.string().required(props.t("Veuillez entrer votre adresse"))}),
      // ...(notValidLocation && {googleLocation: Yup.string().required(props.t("Vérifiez votre adresse, si non répétez l’action autre fois"))}),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateProf = {
          id: Professionnal.id,
          image_url: values.image_url || Professionnal.image_url,
          professionId: parseInt(values.professionId),
          departmentId: parseInt(values.departmentId),
          governorateId: parseInt(values.governorateId),
          delegationId: parseInt(values.delegationId),
          localityId: parseInt(values.localityId),
          cin: values.cin,
          diplome: values.diplome,
          description: values.description,
          lastname: values.lastname,
          firstname: values.firstname,
          ...values.password && { password: values.password },
          email: values.email,
          address: values.address,
          secondAddress: values.secondAddress,
          phone: values.phone,
          secondPhone: values.secondPhone,
          latitude: values.latitude,
          langitude: values.langitude,
          gender: values.gender,
          accountType: values.accountType,
        };
        // update 
        // console.log("updateProfessionnal",updateProf)
        dispatch(updateProfessionnal(updateProf));
        validation.resetForm();
      } else {
        const newProfessionnal = {
          professionId: parseInt(values["professionId"]) || null,
          governorateId: parseInt(values["governorateId"]) || null,
          image_url: values["image_url"],
          cin: values["cin"],
          firstname: values["firstname"],
          lastname: values["lastname"],
          diplome: values["diplome"],
          description: values["description"],
          gender: values["gender"],
          email: values["email"],
          address: values["address"],
          secondAddress: values["secondAddress"],
          phone: values["phone"],
          secondPhone: values["secondPhone"],
          password: values["password"],
          governorateId: parseInt(values["governorateId"]) || null,
          delegationId: parseInt(values["delegationId"]) || null,
          localityId: parseInt(values["localityId"]) || null,
          latitude: values.latitude,
          langitude: values.langitude,
          accountType: values["accountType"],
          addedById: user?.id || null,
        };
        // save newProfessionnal
        //console.log("newProfessionnal", newProfessionnal);
        dispatch(createProfessionnal(newProfessionnal));
        validation.resetForm();
      }
      toggle();
    },
  });

  const handelImage = (event) => {
    setLoading(true);
    let formData = new FormData();
    formData.set("file", event.target.files[0]);
    upload(formData)
      .then(({ path }) => {
        setLoading(false);
        validation.setFieldValue("image_url", path);
      })
      .catch((e) => {
        setLoading(false);

      });
  };

  const coordinationsHandler = (address) => {
    const { label, value } = address
    setLocation(address)
    geocodeByPlaceId(value.place_id)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        validation.setFieldValue("googleLocation", lat + lng)
        validation.setFieldValue("latitude", lat)
        validation.setFieldValue("langitude", lng)
        //add
      })
      .catch(error => console.log("error"));
  }

  const getLocation = (event) => {
    const governorat = governoratesData.find(el => el.id == validation.values.governorateId) ?? null
    console.log("gouvernorat",governorat)
    console.log("location",location)

    if (!!governorat) {
      const valid = location?.value?.address_components?.some(item => item.long_name.includes(governorat.name))
      console.log("notValid",valid)
      setNotValidLocation(!valid)
    }
    validation.setFieldValue("langitude",  location?.lng)
    validation.setFieldValue("latitude", location?.lat)
    event.preventDefault()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        validation.setFieldValue("googleLocation", position.coords.latitude + position.coords.longitude)
        validation.setFieldValue("latitude", position.coords.latitude)
        validation.setFieldValue("langitude", position.coords.longitude)
        if (window.google) {
          geocodeByLatLng({ lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude) })
            .then(results => {
              setLocation({ value: results[0], label: results[0].formatted_address })
            })
            .catch(error => console.error(error));
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }



  return (
    <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "50vw" }}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Modifier professionnel") : props.t("Ajouter professionnel")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Row>
              <Col className="col-12">
                <div className="row">
                

                  <div className="col-6 mb-3">
                    <Label className="form-label">
                      {props.t("Profession*")}
                    </Label>
                    <Input
                      type="select"
                      name="professionId"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.professionId || ""}
                      invalid={
                        validation.touched.professionId &&
                          validation.errors.professionId
                          ? true
                          : false
                      }
                    >
                      <option key="empty" disabled></option>
                      {professionData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.professionId &&
                      validation.errors.professionId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.professionId}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 mb-3">
                    <Label className="form-label">
                      {props.t("Gouvernorat*")}
                    </Label>
                    <Input
                      type="select"
                      name="governorateId"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.governorateId || []}
                      invalid={
                        validation.touched.governorateId &&
                          validation.errors.governorateId
                          ? true
                          : false
                      }
                    >
                      <option key="empty" disabled></option>
                      {governoratesData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.governorateId &&
                      validation.errors.governorateId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.governorateId}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="col-4 mb-3">
                    <Label className="form-label">
                      {props.t("Délégation")}
                    </Label>
                    <Input
                      type="select"
                      name="delegationId"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.delegationId || ""}
                      invalid={
                        validation.touched.delegationId &&
                          validation.errors.delegationId
                          ? true
                          : false
                      }
                    >
                      <option key="empty" disabled></option>
                      {delegationData
                        .filter(

                          (item) =>

                            item.parentId ==
                            (validation.values.governorateId ||
                              validation.initialValues.governorateId)
                        )

                        ?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </Input>
                    {validation.touched.delegationId &&
                      validation.errors.delegationId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.delegationId}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="col-4 mb-3">
                    <Label className="form-label">{props.t("Localité")}</Label>
                    <Input
                      type="select"
                      name="localityId"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.localityId || validation.initialValues.delegationId}
                      invalid={
                        validation.touched.localityId &&
                          validation.errors.localityId
                          ? true
                          : false
                      }
                    >
                      <option key="empty" disabled></option>
                      {delegationData
                        .find(
                          (item) =>
                            item.id ==
                            (validation.values.delegationId ||
                              validation.initialValues.delegationId)
                        )?.children?.map((item) => <option key={item.id} value={item.id}>
                        {item.name}
                      </option>)}
                    </Input>
                    {validation.touched.localityId &&
                      validation.errors.localityId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.localityId}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <Label className="form-label">
                      {props.t("Adresse")}
                    </Label>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyBs7pFjOBMNQzMbjvyxaHrRw7q3Sn2Gi6Y"
                      selectProps={{
                        placeholder: "Choisie votre address",
                        value: location,
                        onChange: coordinationsHandler,

                      }}
                    />
                    <Input
                      name="googleLocation"
                      value={validation.values.googleLocation}
                      hidden
                      invalid={
                        validation.touched.googleLocation &&
                          validation.errors.googleLocation
                          ? true
                          : false
                      } />
                    {validation.touched.googleLocation && validation.errors.googleLocation ? (
                      <FormFeedback type="invalid">
                        {validation.errors.googleLocation}
                      </FormFeedback>
                    ) : null}

                    <button
                      type="buton"
                      onClick={getLocation}
                      className="btn btn-info btn-md mt-3"
                    >
                      Utiliser votre position actuelle
                    </button>
                   
                  </div>
               { notValidLocation &&  <label style={{color: '#ff0000'}}>Vérifiez votre adresse, si non répétez l’action autre fois</label>}
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <Label className="form-label">{props.t("Prénom*")}</Label>
                    <Input
                      name="lastname"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastname || ""}
                      invalid={
                        validation.touched.lastname &&
                          validation.errors.lastname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastname &&
                      validation.errors.lastname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastname}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="col-6 mb-3">
                    <Label className="form-label">{props.t("Nom*")}</Label>
                    <Input
                      name="firstname"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname || ""}
                      invalid={
                        validation.touched.firstname &&
                          validation.errors.firstname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstname &&
                      validation.errors.firstname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstname}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Password*")}</Label>
                  <Input
                    name="password"
                    type="text"
                    //placeholder={isEdit ? "If you don't wanna change the password leave this input empty" : ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Télèphone*")}</Label>
                  <Input
                    name="phone"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone || ""}
                    invalid={
                      validation.touched.phone && validation.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </div>


                <div className="mb-5">
                  <Label className="form-label">Logo</Label>
                  <div className="row">
                    <div className="col-12">
                      <div className="position-relative">
                        <Input
                          className="position-absolute"
                          // id="inputGroupFile04"
                          // aria-describedby="inputGroupFileAdd"
                          // aria-label="Upload"
                          name="image_url"
                          type="file"
                          validate={{
                            required: { value: true },
                          }}
                          accept=".png, .jpg, .jpeg"
                          onChange={handelImage}
                          onBlur={validation.handleBlur}
                          //value={validation.values.logo || ""}
                          invalid={
                            validation.touched.image_url &&
                              validation.errors.image_url
                              ? true
                              : false
                          }
                        />
                        {loading && (
                          <Spinner className="mt-2 float-end me-3" size="sm" />
                        )}
                       
                      </div>
                    </div>
                  </div>
                  {validation.touched.image_url &&
                    validation.errors.image_url ? (
                    <FormFeedback type="invalid">
                      {validation.errors.image_url}
                    </FormFeedback>
                  ) : null}
                </div>


                <div className="mb-3">
                  <Label className="form-label">{props.t("Deuxième numéro ")}</Label>
                  <Input
                    name="secondPhone"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.secondPhone || ""}
                    invalid={
                      validation.touched.secondPhone && validation.errors.secondPhone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.secondPhone && validation.errors.secondPhone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.secondPhone}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("CIN")}</Label>
                  <Input
                    name="cin"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.cin || ""}
                    invalid={
                      validation.touched.cin && validation.errors.cin
                        ? true
                        : false
                    }
                  />
                  {validation.touched.cin && validation.errors.cin ? (
                    <FormFeedback type="invalid">
                      {validation.errors.cin}
                    </FormFeedback>
                  ) : null}
                </div>



                <div className="mb-3">
                  <Label className="form-label">{props.t("Email")}</Label>
                  <Input
                    name="email"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Diplôme")}</Label>
                  <Input
                    name="diplome"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.diplome || ""}
                    invalid={
                      validation.touched.diplome && validation.errors.diplome
                        ? true
                        : false
                    }
                  />
                  {validation.touched.diplome && validation.errors.diplome ? (
                    <FormFeedback type="invalid">
                      {validation.errors.diplome}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Description")}</Label>
                  <Input
                    name="description"
                    type="textarea"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    invalid={
                      validation.touched.description && validation.errors.description
                        ? true
                        : false
                    }
                  />
                  {validation.touched.description && validation.errors.description ? (
                    <FormFeedback type="invalid">
                      {validation.errors.description}
                    </FormFeedback>
                  ) : null}
                </div>



                <div className="mb-3">
                  <Label className="form-label">{props.t(" Deuxième   Adresse")}</Label>
                  <Input
                    name="secondAddress"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.secondAddress || ""}
                    invalid={
                      validation.touched.secondAddress && validation.errors.secondAddress
                        ? true
                        : false
                    }
                  />
                  {validation.touched.secondAddress && validation.errors.secondAddress ? (
                    <FormFeedback type="invalid">
                      {validation.errors.secondAddress}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <Label className="form-label">{props.t("Gender")}</Label>
                    <Input
                      name="gender"
                      type="select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.gender || ""}
                      invalid={
                        validation.touched.gender && validation.errors.gender
                          ? true
                          : false
                      }
                    >
                      <option key="masculin">Masculin </option>
                      <option key="feminin">Féminin </option>
                    </Input>
                    {validation.touched.gender && validation.errors.gender ? (
                      <FormFeedback type="invalid">
                        {validation.errors.gender}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3">
                  <Label className="form-label">
                    {props.t("Date de naissance")}
                  </Label>
                  <Input
                    name="date"
                    type="date"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.date || ""}
                    invalid={
                      validation.touched.date && validation.errors.date
                        ? true
                        : false
                    }
                  />
                  {validation.touched.date && validation.errors.date ? (
                    <FormFeedback type="invalid">
                      {validation.errors.date}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Added by")}</Label>
                  <Input
                    name="addedBy"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={user?.firstname + " " + user?.lastname}
                    disabled
                    invalid={
                      validation.touched.addedBy && validation.errors.addedBy
                        ? true
                        : false
                    }
                  />

                  {validation.touched.addedBy && validation.errors.addedBy ? (
                    <FormFeedback type="invalid">
                      {validation.errors.addedBy}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-customer"
                >
                  {props.t("save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(ProfessionModal);
