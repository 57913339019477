import PropTypes from "prop-types";
import React from "react";

import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser } from "../../store/actions";
// import images
import CarouselPage from "pages/AuthenticationInner/CarouselPage";
import logoWhite from "../../assets/images/logoAdresstreet.png";
import { withTranslation } from "react-i18next";


const Login = props => {

  //meta title
  document.title = "Adresstreet";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));



 
 
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logoWhite}
                          alt=""
                          height="70"
                          className="auth-logo-dark"
                        />
                      
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 style={{ color: '#3EC0F0' }}>{props.t('Welcome Back')} !</h5>
                        <p className="text-muted">
                          {props.t('Sign in to continue')}
                        </p>
                      </div>


                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          {error == "404" && <Alert color="danger">{props.t("Sorry, We couldn't find your account!")}</Alert>}
                          {error == "401" && <Alert color="danger">{props.t("Wrong credentials")}</Alert>}
                      

                          <div className="mb-3">
                            <Label className="form-label">{props.t('Email')}</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder={props.t('Enter email')}
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">{props.t('Password')}</Label>
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder={props.t('Enter password')}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              {props.t('Remember me')}
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                             style={{ background: '#3EC0F0' }}
                              className="btn  btn-block "
                              type="submit"
                            >
                              {props.t('Log in')}
                            </button>
                          </div>

                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} . Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Accelerant
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));

Login.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any
};
