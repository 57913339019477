
/**filter */
export const GET_DEPARTMENT_BY_NAME_SUCCESS = "GET_DEPARTMENT_BY_NAME_SUCCESS";
export const GET_DEPARTMENT_BY_NAME_REQUEST = "GET_DEPARTMENT_BY_NAME_REQUEST";
export const GET_DEPARTMENT_BY_NAME_FAILED = "GET_DEPARTMENT_BY_NAME_FAILED";

/**Get */
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_REQUEST = 'GET_DEPARTMENT_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_DEPARTMENT_FAILED = 'GET_DEPARTMENT_FAILED';

/**Create */
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS';
export const CREATE_DEPARTMENT_FAILED = 'CREATE_DEPARTMENT_FAILED';
export const CREATE_DEPARTMENT_REQUEST = 'CREATE_DEPARTMENT_REQUEST';
/**Delete */
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAILED = 'DELETE_DEPARTMENT_FAILED';
export const DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST';
/**Update */
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAILED = 'UPDATE_DEPARTMENT_FAILED';
export const UPDATE_DEPARTMENT_REQUEST = 'UPDATE_DEPARTMENT_REQUEST';

export const DEPARTMENT_MESSAGES_RESET = 'DEPARTMENT_MESSAGES_RESET';
export const DEPARTMENT_MESSAGES_SUCCESS = 'DEPARTMENT_MESSAGES_SUCCESS';
export const RESET_DEPARTMENT_MESSAGES_SUCCESS = 'RESET_DEPARTMENT_MESSAGES_SUCCESS';
export const RESET_DEPARTMENT_MESSAGES = 'RESET_DEPARTMENT_MESSAGES';

