import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash"
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../components/Common/TableContainer"

import {
  getMDMCountries,
  getMDMDelegation,
  getMDMGovernorates,
  getMDMLocality,
  getSeekers,
  getSeekersStats,
  resetCountryMessages,
  updateMDMCountry,
} from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"
import MiniCardss from "pages/Dashboard/mini-card"
import moment from "moment"

const Seekers = props => {
  const {
    seekersData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading
  } = useSelector(state => state.seekers)
  const {
    seekerStatsData,
    
  } = useSelector(state => state.seekers)
  
  const {delegationData} = useSelector(state => state.delegations)
  const {governoratesData} = useSelector(state => state.governorates)
  const {localityData} = useSelector(state => state.localities)
  //meta title
  document.title = "Adresstreet"
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    delegationId:"",
    governorateId:"",
    localityId:"",
    page: 1,
    limit: 100,
  })
  const [modal, setModal] = useState(false)
  const [seekersList, setSeekersList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [seekers, setSeekers] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")


  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Total joinings"),
      iconClass: "bx-check-circle",
      text: 0,
    },
    {
      title: props.t("Today joinings"),
      iconClass: "dripicons-user-group",
      text:0,
    },
    {
      title: props.t("Total Mois Courant"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
    {
      title: props.t("Total Année Courante"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
  ])
  useEffect(() => {
    setMiniCards([
    {
      title: props.t("Total joinings"),
      iconClass: "bx-check-circle",
      text: seekerStatsData.totalJoinings,
    },
    {
      title: props.t("Today joinings"),
      iconClass: "dripicons-user-group",
      text:seekerStatsData.todayJoinings,
    },
    {
      title: props.t("Total Mois Courant"),
      iconClass: "dripicons-user-group",
      text: seekerStatsData.monthJoinings,
    },
    {
      title: props.t("Total Année Courante"),
      iconClass: "dripicons-user-group",
      text: seekerStatsData.yearJoinings,
    },
  ])

}, [seekerStatsData])

  const handleseekersClick = arg => {
    const seekers = arg

    setSeekers({
      ...seekers,
    })

    setIsEdit(true)
    toggle()
  }

  // Department Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Id"),
        accessor: "id",
        disableFilters: true,
        // Cell: cellProps => {
        //   return <EnName {...cellProps} />
        // },
      },
      {
        Header: props.t("Nom et Prénom"),
        accessor: "firstname",
        disableFilters: true,
       
        accessor: (cellProps) => {
          return (
            <div
              className="external-event"
            >
              {cellProps.firstname + " " + cellProps.lastname ||
                props.t("Non fourni")}
            </div>
          );
        },
      },
   
      {
        Header: props.t("Téléphone"),
        accessor: "phone",
        disableFilters: true,
        
      },
      {
        Header: props.t("Adresse"),
        accessor: "adress",
        disableFilters: true,
        accessor: (cellProps) => {
          return <>{cellProps.governorate?.name + ", " + cellProps.delegation?.name + ", " + cellProps.locality?.name || props.t("Non fourni")}</>;
        },
      },
      {
        Header: props.t("Date d'inscription"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm") ||
                props.t("Non fourni")}
            </>
          );
        },
      },
         
   
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setDepartment(null)
    } else {
      setModal(true)
    }
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = seekers => {
    setSeekers(seekers)
    setDeleteModal(true)
  }

  const handleDeleteSeekers= () => {
    if (country.id) {
      dispatch(updateMDMCountry({ id: country.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getMDMCountries())
    }
  }

  useEffect(() => {

    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("created successfully !")
      dispatch(resetCountryMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Successfully !")
      dispatch(resetCountryMessages())
    }
  }, [success])

  useEffect(() => {
    {
      dispatch(getSeekers(filter))
      dispatch(getSeekersStats())
      dispatch(getMDMLocality(filter))
      dispatch(getMDMGovernorates(filter))
      dispatch(getMDMDelegation(filter))
    

    }
  }, [dispatch, filter])

  useEffect(() => {
    setSeekersList(seekersData)
  }, [seekersData])

  useEffect(() => {
    if (!isEmpty(seekersData)) {
      setSeekersList(seekersData)
    }
  }, [seekersData])

  const handleSeekersClicks = () => {
    setSeekersList("")
    setIsEdit(false)
    toggle()
  }

  const setPageNumber = (activePage) => { setFilter(prevFilter => { return { ...prevFilter, page: activePage } }) }
  const handleLimit = (limit) => { setFilter(prevFilter => { return { ...prevFilter, limit: limit } }) }
 

  const handleDateFromTo = date => {
    if (!date) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: "",
          to: "",
        }
      })
    }
    else if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date?.startDate).format("Y-MM-DD"),
          to: moment(date?.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date?.startDate).format("Y-MM-DD"),
          to: moment(date?.endDate).format("Y-MM-DD"),
        }
      })
    }
  }

  const handleGouvernorate = (gouvernorate) => { setFilter(prevFilter => { return { ...prevFilter, governorateId: gouvernorate.value } }) }
  const handleDelegation = (delegation) => { setFilter(prevFilter => { return { ...prevFilter, delegationId: delegation.value } }) }
  const handleLocality = (locality) => { setFilter(prevFilter => { return { ...prevFilter, localityId: locality.value } }) }




  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSeekers}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("")} breadcrumbItem={props.t("Liste des chercheurs")} />

          <Row>
            <Col xl="12">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCardss
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={seekersData}
                    isGlobalFilter={true}
                    filterDate={true}
                    isExcelSeeker={true}
                    resetOptionSeekers={true}
                    setDateFilter={handleDateFromTo}
                    GouvernorateFilter={true}
                    DelegationFilter={true}
                    LocalityFilter={true}
                    gouvernorats={governoratesData}
                    delegations={delegationData}
                    localities={localityData}
                    setGouvernorate={handleGouvernorate}
                    setDelegation={handleDelegation}
                    setLocality={handleLocality}
                    handleOrderClicks={handleSeekersClicks}
                    seekers={seekersData}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
               
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Seekers)
