import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"

import {
  deleteMDMDepartment,
  getMDMDepartment,
  resetCountryMessages,
  updateMDMDepartment,
} from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"
import DepartementModal from "./DepartmentModal"
import moment from "moment"
import ConfirmModalMDM from "components/Common/ConfirmModalMDM"

const Departement = props => {
  const {
    departmentData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading
  } = useSelector(state => state.departments)
  

  //meta title
  document.title = "Adresstreet"
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  const [modal, setModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [departmentList, setDepartmentList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [department, setDepartment] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")


  const handledepartmentClick = arg => {
    const department = arg
    setDepartment({
      ...department,
    })
    setIsEdit(true)
    toggle()
  }

  // Department Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Id"),
        accessor: "id",
        disableFilters: true,
       
      },
      {
        Header: props.t("Photo"),
        accessor: "photo",
        disableFilters: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.imageUrl ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {/* {cellProps.name.charAt(0)|| "not provided"} */}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.imageUrl}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: props.t("Nom"),
        accessor: "name",
        disableFilters: true,
       
      },
      {
        Header: props.t("Description"),
        accessor: "description",
        disableFilters: true,
       
      },
      {
        Header: props.t("Created At"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm")}
            </>
          )
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
             
                <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const DepartmentData = cellProps.row.original
                    handledepartmentClick(DepartmentData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const countryData = cellProps.row.original
                    console.log('departmentData',countryData)
                    onClickDelete(countryData)
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setDepartment(null)
    } else {
      setModal(true)
    }
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = department => {
    setDepartment(department)
    setDeleteModal(true)
  }
// console.log("-----------------------------",department?.children?.map((el)=>   el.status  == "archived"))
  const handleDeleteDepartment = () => {
    if (department.id) {
      if (department?.children?.length == 0 ){
      dispatch(updateMDMDepartment({ id: department.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getMDMDepartment())
      }
      else
      { setErrorModal(true)}  
    }
  }

  useEffect(() => {

    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Departement created successfully !")
      dispatch(resetCountryMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Successfully !")
      dispatch(resetCountryMessages())
    }
  }, [success])

  useEffect(() => {
    {
      dispatch(getMDMDepartment(filter))
      // console.log("country",departmentData)

    }
  }, [dispatch, filter])

  useEffect(() => {
    setDepartmentList(departmentData)
  }, [departmentData])

  useEffect(() => {
    if (!isEmpty(departmentData)) {
      setDepartmentList(departmentData)
    }
  }, [departmentData])

  const handleDepartmentClicks = () => {
    setDepartmentList("")
    setIsEdit(false)
    toggle()
  }

  const setPageNumber = (activePage) => { setFilter(prevFilter => { return { ...prevFilter, page: activePage } }) }
  const handleLimit = (limit) => { setFilter(prevFilter => { return { ...prevFilter, limit: limit } }) }

  return (
    <React.Fragment>
      <ConfirmModalMDM
        show={errorModal}
        // onDeleteClick={handleDeleteDepartment}
        onCloseClick={() => setErrorModal(false)}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDepartment}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("MDM")} breadcrumbItem={props.t("Liste des départements")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={departmentData}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleOrderClicks={handleDepartmentClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
               <DepartementModal modal={modal} toggle={toggle} isEdit={isEdit} Department={department} />
              
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Departement)
