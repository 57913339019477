import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createMDMAdsFailed,
  createMDMAdsSuccess,
  deleteMDMAdsFailed,
  deleteMDMAdsSuccess,
  getMDMAdsFailed,
  getMDMAdsSuccess,
  updateMDMAdsFailed,
  updateMDMAdsSuccess,
} from "./actions"
import {
  ADS_MESSAGES_RESET,
  CREATE_ADS_REQUEST,
  DELETE_ADS_REQUEST,
  GET_ADS_BY_NAME_REQUEST,
  GET_ADS_REQUEST,
  RESET_ADS_MESSAGES,
  UPDATE_ADS_REQUEST
} from "./actionTypes";
import moment from "moment";



import { delAds, getAds, postAds, putAds } from "helpers/api_helper";



function* getAdsSaga({ payload }) {
  try {

    const response = yield getAds(payload || { page: 1, limit: 1000 });
    // console.log("responseAds",response)
    const formattedData = response.data?.map(item => {
      if ((moment(new Date()).isAfter(moment(new Date(item.start_date))) || moment(new Date()).isSame(moment(new Date(item.start_date)))) && moment(new Date()).isBefore(moment(new Date(item.end_date)))) {
        return { ...item, status: "ouverte" }
      } else if (moment(new Date()).isBefore(moment(new Date(item.start_date)))) {
        return { ...item, status: "en attente" }
      } else {
        return { ...item, status: "clôturée" }
      }
    })
    yield put(getMDMAdsSuccess({ ...response, data: formattedData }))
  } catch (e) {
    yield put(getMDMAdsFailed(e))
  }
}

function* getAdsBynameSaga() {

}

function* getAdsFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
    //console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getMDMAdsFailed(e))
  }
}

function* createAdsSaga({ payload: ads }) {
  try {
    const data = yield postAds(ads);
// console.log("data",data)
    const response = yield getAds({ limit: 20, page: 1 });
    // console.log("response",response)
    yield put(getMDMAdsSuccess(response))

  } catch (e) {
    yield put(createMDMAdsFailed(e))
  }
}

function* deleteAdsSaga(payload) {
  try {
    const result = yield delAds(payload.payload.id)
    const data = yield getAds({ current: 1, limit: 20 })
    yield put(getMDMAdsSuccess(data))
  } catch (e) {
    yield put(deleteMDMAdsFailed(e))
  }
}

function* updateAdsSaga({ payload: ads }) {
  try {
    // separer l id & objet Ads
    const { id, ...updatedAds } = ads

    const data = yield putAds(id, updatedAds);
    const response = yield getAds({ limit: 20, page: 1 });
    yield put(getMDMAdsSuccess(response))
  } catch (e) {
    yield put(updateMDMAdsFailed(e))
  }
}

function* resetAdsMessagesSaga() {
  yield put(resetAdsMessagesSuccess())
}




export default function* adsSaga() {
  yield all([
    takeEvery(GET_ADS_REQUEST, getAdsSaga),
    takeEvery(GET_ADS_BY_NAME_REQUEST, getAdsBynameSaga),
    takeEvery(CREATE_ADS_REQUEST, createAdsSaga),
    takeEvery(DELETE_ADS_REQUEST, deleteAdsSaga),
    takeEvery(UPDATE_ADS_REQUEST, updateAdsSaga),
    takeEvery(RESET_ADS_MESSAGES, resetAdsMessagesSaga),
  ])
}
