import frFLag from "../assets/images/flags/french.jpg"


const languages = {
  fr: {
    label: "French",
    flag: frFLag,
  },
}

export default languages
