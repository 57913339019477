
/**filter */
export const GET_PROFESSION_BY_NAME_SUCCESS = "GET_PROFESSION_BY_NAME_SUCCESS";
export const GET_PROFESSION_BY_NAME_REQUEST = "GET_PROFESSION_BY_NAME_REQUEST";
export const GET_PROFESSION_BY_NAME_FAILED = "GET_PROFESSION_BY_NAME_FAILED";

/**Get */
export const GET_PROFESSION_SUCCESS = 'GET_PROFESSION_SUCCESS';
export const GET_PROFESSION_REQUEST = 'GET_PROFESSION_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_PROFESSION_FAILED = 'GET_PROFESSION_FAILED';

/**Create */
export const CREATE_PROFESSION_SUCCESS = 'CREATE_PROFESSION_SUCCESS';
export const CREATE_PROFESSION_FAILED = 'CREATE_PROFESSION_FAILED';
export const CREATE_PROFESSION_REQUEST = 'CREATE_PROFESSION_REQUEST';
/**Delete */
export const DELETE_PROFESSION_SUCCESS = 'DELETE_PROFESSION_SUCCESS';
export const DELETE_PROFESSION_FAILED = 'DELETE_PROFESSION_FAILED';
export const DELETE_PROFESSION_REQUEST = 'DELETE_PROFESSION_REQUEST';
/**Update */
export const UPDATE_PROFESSION_SUCCESS = 'UPDATE_PROFESSION_SUCCESS';
export const UPDATE_PROFESSION_FAILED = 'UPDATE_PROFESSION_FAILED';
export const UPDATE_PROFESSION_REQUEST = 'UPDATE_PROFESSION_REQUEST';

export const PROFESSION_MESSAGES_RESET = 'PROFESSION_MESSAGES_RESET';
export const PROFESSION_MESSAGES_SUCCESS = 'PROFESSION_MESSAGES_SUCCESS';
export const RESET_PROFESSION_MESSAGES_SUCCESS = 'RESET_PROFESSION_MESSAGES_SUCCESS';
export const RESET_PROFESSION_MESSAGES = 'RESET_PROFESSION_MESSAGES';

