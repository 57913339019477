import {
    EVALUATION_MESSAGES_RESET,
    CREATE_EVALUATION_FAILED,
    CREATE_EVALUATION_REQUEST,
    CREATE_EVALUATION_SUCCESS,
    DELETE_EVALUATION_FAILED,
    DELETE_EVALUATION_REQUEST,
    DELETE_EVALUATION_SUCCESS,
    GET_EVALUATION_BY_NAME_FAILED,
    GET_EVALUATION_BY_NAME_REQUEST,
    GET_EVALUATION_BY_NAME_SUCCESS,
    GET_EVALUATION_FAILED,
    GET_EVALUATION_REQUEST,
    GET_EVALUATION_SUCCESS,
    GET_EVALUATIONBYID_FAILED,
    GET_EVALUATIONBYID_REQUEST,
    GET_EVALUATIONBYID_SUCCESS,
    UPDATE_EVALUATION_FAILED,
    UPDATE_EVALUATION_REQUEST,
    RESET_EVALUATION_MESSAGES,
    RESET_EVALUATION_MESSAGES_SUCCESS,
    UPDATE_EVALUATION_SUCCESS,
  } from "./actionTypes.js";
  
  export const getEvaluationByName = (name) => ({
    type: GET_EVALUATION_BY_NAME_REQUEST,
    payload: name,
  });
  
  export const getEvaluationByNameSuccess = (payload) => ({
    type: GET_EVALUATION_BY_NAME_SUCCESS,
    payload,
  });
  
  export const getEvaluationByNameFailed = (payload) => ({
    type: GET_EVALUATION_BY_NAME_FAILED,
    payload,
  });
  
  export const getEvaluation = (filter) => ({
    type: GET_EVALUATION_REQUEST,
    payload: filter,
  });
  
  export const getEvaluationSuccess = (evaluation) => ({
    type: GET_EVALUATION_SUCCESS,
    payload: evaluation,
  });
  
  export const getEvaluationFailed = (payload) => ({
    type: GET_EVALUATION_FAILED,
    payload,
  });
  export const getEvaluationById = (filter) => ({
    type: GET_EVALUATIONBYID_REQUEST,
    payload: filter,
  });
  
  export const getEvaluationByIdSuccess = (evaluation) => ({
    type: GET_EVALUATIONBYID_SUCCESS,
    payload: evaluation,
  });
  
  export const getEvaluationByIdFailed = (payload) => ({
    type: GET_EVALUATIONBYID_FAILED,
    payload,
  });
  
  export const createEvaluation = (evaluation) => ({
    type: CREATE_EVALUATION_REQUEST,
    payload: evaluation,
  });
  
  export const createEvaluationSuccess = (evaluation) => ({
    type: CREATE_EVALUATION_SUCCESS,
    payload: evaluation,
  });
  
  export const createEvaluationFailed = (payload) => ({
    type: CREATE_EVALUATION_FAILED,
    payload,
  });
  
  export const deleteEvaluation = (id) => ({
    type: DELETE_EVALUATION_REQUEST,
    payload: id,
  });
  
  export const deleteEvaluationSuccess = (id) => ({
    type: DELETE_EVALUATION_SUCCESS,
    payload: id,
  });
  
  export const deleteEvaluationFailed = (id) => ({
    type: DELETE_EVALUATION_FAILED,
    payload: id,
  });
  
  export const updateEvaluation = (evaluation) => ({
    type: UPDATE_EVALUATION_REQUEST,
    payload: evaluation,
  });
  
  export const updateEvaluationSuccess = (evaluation) => ({
    type: UPDATE_EVALUATION_SUCCESS,
    payload: evaluation,
  });
  
  export const updateEvaluationFailed = (payload) => ({
    type: UPDATE_EVALUATION_FAILED,
    payload,
  });
  
  export const resetEvaluationMessages = () => ({
    type: EVALUATION_MESSAGES_RESET,
  });
  
  export const resetEvaluationMessagesSuccess = () => ({
    type: RESET_EVALUATION_MESSAGES_SUCCESS,
  });
  