import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import footer from "../../assets/images/footer.jpeg";
import { Col } from "reactstrap";

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={9}>
        
        <img
                src={footer}
                alt=""
                width="100%"
                height="100%"
                className="auth-logo-dark"
              />
          <div className="w-100">
        
            <div className="d-flex h-100 flex-column">
              <div className="p-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 className="mb-3">
                        <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                        Welcome to  
                        <span className="text-primary"> Adresstreet</span>
                      </h4>
                      <div dir="ltr"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
        </div>
      </Col>
    </React.Fragment>
  );
};
export default CarouselPage;
