import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { isEmpty, map } from "lodash";
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  getMDMCountries,
  getMDMProfession,
  getProfessionnal,
  getSearch,
  getSearchStats,
  getSeekers,
  resetEvaluationMessages,
  updateEvaluation,
  updateMDMCountry,
  updateSearch,
} from "store/actions";
import SuccessModal from "components/Common/SuccessModal";
import { withTranslation } from "react-i18next";
import MiniCardss from "pages/Dashboard/mini-card";
import DeleteModal from "components/Common/DeleteModal";
import TableContainer from "components/Common/TableContainer";
import EvaluationModal from "./EvaluationModal";
import moment from "moment";

const SearchResults = (props) => {
  //meta title
  document.title = "Adresstreet";
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    professionId: "",
    seekerId:"",
    from: "",
    to: "",
    page: 1,
    limit: 500,
  });
  // ** State

  const [modal, setModal] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [search, setSearch] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const [closedResults, setClosedResults] = useState([]);

  // ** Selector
  const { professiannalData } = useSelector((state) => state.professionnals);
  const { professionData } = useSelector((state) => state.professions);
  const {seekersData} = useSelector(state => state.seekers)
 
  const {
    searchData,
    error,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading,
  } = useSelector((state) => state.searchs);
  const {
    searchStatsData,

  } = useSelector((state) => state.searchs);
  const {evaluationData,success} = useSelector(state => state.evaluations)
  const history = useHistory();
  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Total des recherche"),
      iconClass: "bx-check-circle",
      text: 0,
    },
    {
      title: props.t("Recherches aujourd'hui"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
    {
      title: props.t("Total Mois Courant"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
    {
      title: props.t("Total Année Courante"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
  ]);

  useEffect(() => {
    setMiniCards([
    {
      title: props.t("Totale des Recherches"),
      iconClass: "bx-check-circle",
      text: searchStatsData.total,
    },
    {
      title: props.t("Recherches aujourd'hui"),
      iconClass: "dripicons-user-group",
      text:searchStatsData.today,
    },
    {
      title: props.t("Total Mois Courant"),
      iconClass: "dripicons-user-group",
      text: searchStatsData.month,
    },
    {
      title: props.t("Total Année Courante"),
      iconClass: "dripicons-user-group",
      text: searchStatsData.year,
    },
  ])

  }, [searchStatsData])




  const handleSelectedToClose = (event, element) => {
    if (event.target.checked) {
      setClosedResults(state => ([...state, element]))
    } else {
      setClosedResults(state => (state.filter(el => el.id !== element.id)))
    }
  }



  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: cellProps => {
          
          return (
            (cellProps.status == "Ouverte" ||  cellProps.status == "Réalisée" ||  cellProps.status == "Refusée" ||  cellProps.status == "Encours") &&
           ( <input
              type="checkbox"
              name="pay"
              onChange={e => handleSelectedToClose(e, cellProps)}
            />)

          )
        },
      },
      {
        Header: props.t("Id"),
        accessor: "id",
        disableFilters: true,
      },
      {
        Header: props.t("Date"),
        accessor: "date",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm")}
            </>
          );
        },
      },
      {
        Header: props.t("Chercheur"),
        accessor: "search",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
             
              {(cellProps.seeker &&
                cellProps.seeker?.firstname +
                " " +
                cellProps.seeker?.lastname) + " " + "(" +
                cellProps.seeker?.phone + ")" ||
                props.t("Non fourni")}
            </>
          );
        },
      },
      {
        Header: props.t("Professionnel"),
        accessor: "professionnel",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
             
              {(cellProps.professional &&
                cellProps.professional?.firstname +
                " " +
                cellProps.professional?.lastname) + " " + "(" +
                cellProps.professional?.phone + ")" ||
                props.t("Non fourni")}
            </>
          );
        },
      },
      {
        Header: props.t("Profession"),
        accessor: "professionnel",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              
              {(cellProps.professional &&
                cellProps.professional?.profession?.name ||
                props.t("Non fourni"))}
            </>
          );
        },
      },
      {
        Header: props.t("Adresse"),
        accessor: "professionnel",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              
              {(cellProps.professional &&
                cellProps.professional?.governorate?.name + " ," + cellProps.professional?.locality?.name ||
                props.t("Non fourni"))}
            </>
          );
        },
      },
      {
        Header: props.t("Statut"),
        accessor: "status",
        disableFilters: true,
       
      },

      {
        Header: props.t("Action"),
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
             
              {cellProps?.row?.original?.status == "réalisée"  &&<Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const countryData = cellProps.row.original
                    onClickEditEvaluation(countryData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>}
            
             
             
              {(cellProps?.row?.original?.status == "Réalisée" || cellProps?.row?.original?.status == "Encours") && <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const countryData = cellProps.row.original
                    onClickEditEvaluation(countryData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>}
              
              {cellProps?.row?.original?.status == "Ouverte" && <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const evaluationuser = cellProps.row.original;
      
                  onClickEvaluation(evaluationuser);
                }}
              >
                <i
                  className="dripicons-to-do font-size-18"
                  id="deletetooltip"
                />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Evaluation
                </UncontrolledTooltip>
              </Link>}
              
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () => {
    setModal(!modal);
  };
 

  //delete
  const [deleteModal, setDeleteModal] = useState(false);
  const [evaluationUser, setEvaluationUser] = useState(null);

 
  
  const onClickEvaluation = (arg) => {
    const evaluationUser = arg;
    
    setEvaluationUser({
      ...evaluationUser,
    });

    setIsEdit(false);

    toggle();
  };
  const onClickEditEvaluation = (arg) => {
    const evaluationUser = arg;
    
    setEvaluationUser({
      ...evaluationUser,
    });

    setIsEdit(true);

    toggle();
  };

  const handleDeleteSearch = () => {
    if (country.id) {
      dispatch(updateMDMCountry({ id: country.id, status: "archived" }));
      setDeleteModal(false);
      dispatch(getMDMCountries());
    }
  };

  const handleCloseResults = async () => {
    closedResults.map(async (result) => {
      dispatch(updateSearch({ id: result.id, status: "Clôturée" }))
      dispatch(updateEvaluation({id: result?.evaluations[0]?.id}))
    })
    setClosedResults([])
  }
 
  useEffect(() => {
     if (success === "CREATE") {
      setSuccessModal(true);
      setMessage("Evaluation  crée avec succées !");
      dispatch(resetEvaluationMessages());
    } else if (success == "UPDATE_EVALUATION") {
      setMessage("Résultat de recherche modifié avec succés !");
      setSuccessModal(true);
      dispatch(resetEvaluationMessages());
    }
  }, [success]);


  useEffect(() => {
    {
      dispatch(getSearch(filter));
      dispatch(getSearchStats());
      dispatch(getProfessionnal());
      dispatch(getMDMProfession())
      dispatch(getSeekers())
      
    }
  }, [dispatch, filter]);

  useEffect(() => {
    setSearchList(searchData);
  }, [searchData]);

  useEffect(() => {
    if (!isEmpty(searchData)) {
      setSearchList(searchData);
    }
  }, [searchData]);

  const handleSearchClicks = () => {
    setSearchList("");
    setIsEdit(false);
    toggle();
  };

  const setPageNumber = (activePage) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, page: activePage };
    });
  };
  const handleLimit = (limit) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, limit: limit };
    });
  };
  const handleProfessionnal = (professional) => { setFilter(prevFilter => { return { ...prevFilter, professionalId: professional.value } }) }
  const handleSeeker = (seekerss) => { setFilter(prevFilter => { return { ...prevFilter, seekerId: seekerss.value } }) }
  const handleProfession = (profession) => { setFilter(prevFilter => { return { ...prevFilter, professionId: profession.value } }) }
  const handleStatus = (statusResults) => { setFilter(prevFilter => { return { ...prevFilter, status: statusResults.value } }) }

  const handleDateFromTo = (date) => {
    if (!date) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: "",
          to: "",
        }
      })
    }
    else if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter((prevFilter) => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD"),
        };
      });
    } else {
      setFilter((prevFilter) => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        };
      });
    }
  };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSearch}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("CRM")}
            breadcrumbItem={props.t("Liste des résultats de recherche")}
          />

          <Row>
            <Col xl="12">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCardss
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={searchData}
                    isGlobalFilter={true}
                    isExcelResult={true}
                    setDateFilter={handleDateFromTo}
                    isAddProfessionOptions={true}
                    ProfessionnelFilter={true}
                    ProfessionFilter={true}
                    profession={professionData}
                    SeekerFilter={true}
                    setSeeker={handleSeeker}
                    seekers={seekersData}
                    StatusResultsFilter={true}
                    setProfession={handleProfession}
                    setProfessionnal={handleProfessionnal}
                    professionnals={professiannalData}
                    setStatusResult={handleStatus}
                    filterDate={true}
                    resetSearchOption={true}
                    handleOrderClicks={handleSearchClicks}
                    handelAddClick={handleSearchClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    // success={success}
                    className="custom-header-css"
                    handleCloseResults={handleCloseResults}
                    closedResults={closedResults}
                  />
                  <EvaluationModal
                    modal={modal}
                    userSearch={evaluationUser}
                    isEdit={isEdit}
                    toggle={toggle}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SearchResults);
