import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, USER_LOADED, USER_REQUESTED } from "./actionTypes";
import { apiError, fulfillUser, loginSuccess, logoutUserSuccess, requestUser, logoutUser } from "./actions";

import {
  getLoggedInUser,
  postLogin,
} from "../../../helpers/api_helper";


function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    });
    yield put(requestUser({ accessToken: response.token, history: history }));
  } catch (error) {
    yield put(apiError(error.response?.status));
  }
}

function* userRequestedSaga({ payload: { accessToken, history, pathname } }) {
  try {
    const user = yield getLoggedInUser(accessToken)
    yield put(fulfillUser(user));
    if (pathname !== undefined || pathname !== "/login") {
      history.push(pathname)
    }
    if(pathname == "/login" || pathname == undefined) {
      history.push('/dashboard')
    }
  } catch (error) {
    history.push('/login')
    yield put(apiError(error));
    
  }
}

function* logoutUserSaga({ payload: { history } }) {
  history.push('/login')
}



function* authSaga() {
  yield takeEvery(USER_REQUESTED, userRequestedSaga);
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUserSaga);
}

export default authSaga;
