import React, { useEffect,useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
 
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  Spinner,
} from "reactstrap"


//redux
import {  useDispatch, useSelector } from "react-redux"

import { upload } from "helpers/api_helper"
import {
    createMDMDelegation,
  getMDMGovernorates,
  updateMDMDelegation,
  
} from "store/actions"

import { withTranslation } from "react-i18next"


const DelegationModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, delegation, isEdit } = props
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  const { governoratesData } = useSelector(state => state.governorates)
  useEffect(() => {
    {
      dispatch(getMDMGovernorates())
    }
  }, [dispatch, filter])

    // console.log(loading)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (delegation && delegation.name) || "",
      type: (delegation && delegation.type) || "delegation",
      status: (delegation && delegation.status) || "active",
      parentId: (delegation && delegation.parentId) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Your Name")),
      parentId: Yup.string().required(props.t("Please Enter Your governorate")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateDelegation = {
          id: delegation.id,
          name: values.name,
          parentId: parseInt(values.parentId),
        }
        // update
        //console.log("updategovernorate", updateGovernorate)
        dispatch(updateMDMDelegation(updateDelegation))
        validation.resetForm()
      } else {
        const newDelegation = {
          name: values["name"],
          type: values["type"],
          status: values["status"],
          parentId: parseInt(values["parentId"]),
        }
        // console.log("New Delegation", newDelegation)
        //save new governorate
        dispatch(createMDMDelegation(newDelegation))
        validation.resetForm()
      }
      toggle()
    },
  })


  const handelImage = event => {
    setLoading(true)
    let formData = new FormData()
    formData.set("file", event.target.files[0])
    upload(formData)
      .then(({ path }) => {
        setLoading(false)
        validation.setFieldValue("imageUrl", path)
      })
      .catch(e => {
        setLoading(false)
        // console.log(e.response)
      })
  }


  return (

<Modal isOpen={modal} toggle={toggle}>
<ModalHeader toggle={toggle} tag="h4">
  {!!isEdit ? props.t("Modifier Délégation") : props.t("Ajouter Délégation")}
</ModalHeader>
<ModalBody>
  <Form
    onSubmit={e => {
      e.preventDefault()
      validation.handleSubmit()
      return false
    }}
  >
    <Row form>
      <Col className="col-12">

   
        <div className="mb-3">
          <Label className="form-label">{props.t("Name")}</Label>
          <Input
            name="name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name &&
              validation.errors.name
                ? true
                : false
            }
          />
          {validation.touched.name &&
          validation.errors.name ? (
            <FormFeedback type="invalid">
              {validation.errors.name}
            </FormFeedback>
          ) : null}
        </div>
      
        <div className="mb-3">
                <Label className="form-label">{props.t("Governorate")}</Label>
                <Input
                  type="select"
                  name="parentId"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.parentId || []}
                  invalid={
                    validation.touched.parentId && validation.errors.parentId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {governoratesData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Input>
                {validation.touched.parentId && validation.errors.parentId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.parentId}
                  </FormFeedback>
                ) : null}
              </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="text-end">
          <button
            type="submit"
            className="btn btn-success save-customer"
          >
            {props.t("Save")}
          </button>
        </div>
      </Col>
    </Row>
  </Form>
</ModalBody>
</Modal>)
}
export default withTranslation()(DelegationModal)
