import React, { useState } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  TabContent,
  TabPane,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { createEvaluation, updateEvaluation } from "store/actions";

const EvaluationModalEdit = (props) => {
  const { userSearch, isEdit, modal, toggle } = props;
  const { user } = useSelector((state) => state.Login);
  const dispatch = useDispatch();

  const [activeTabVartical, setoggleTabVertical] = useState(1);


 
  // validation//
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      professionalId: (userSearch && userSearch.professionalId) || "",
      seekerId: (userSearch && userSearch.seekerId) || "",
      cr1: (userSearch && userSearch.cr1) || 0,
      cr2: (userSearch && userSearch.cr2) || 0,
      cr3: (userSearch && userSearch.cr3) || 0,
      cr4: (userSearch && userSearch.cr4) || 0,
      cr5: (userSearch && userSearch.cr5) || 0,
      status: (userSearch && userSearch.status),
      comments: (userSearch && userSearch.comments) || [],
      comment: ""

    },

    validationSchema: Yup.object({
      // firstname: Yup.string()
      //   .required(props.t("Please Enter Your First Name"))
      //   .matches("^[a-zA-Z]", props.t("only latin words")),
      // lastname: Yup.string()
      //   .required(props.t("Please Enter Your Last Name"))
      //   .matches("^[a-zA-Z]", props.t("only latin words")),
      // email: Yup.string().required(props.t("Please Enter Your Email")),
      // password: Yup.string().required(props.t("Please Enter Your Password")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateEvaluations = {
          id: userSearch.id,
          professionalId: parseInt(values.professionalId),
          seekerId: parseInt(values.seekerId),
          professionalId: parseInt(values.professionalId),
          cr1: values.cr1,
          cr2: values.cr2,
          cr3: values.cr3,
          cr4: values.cr4,
          cr5: values.cr5,
          status: values.status,
          ...(values.comment && {
            comments: [{
              adminId: user?.id, description: values.comment
            }]
          }),
         
        };
        // update
        // console.log("updateEvaluation", updateEvaluations);
        dispatch(updateEvaluation(updateEvaluations));
        validation.resetForm();
      } else {
        const newEvaluation = {
          professionalId: parseInt(values["professionalId"]) || null,
          seekerId: parseInt(values["seekerId"]) || null,
          cr1: values["cr1"],
          cr2: values["cr2"],
          cr3: values["cr3"],
          cr4: values["cr4"],
          cr5: values["cr5"],
          adminId: user?.id || null,
          status: values.status,
          ...(values.comment && {
            comments: [{
              adminId: user?.id, description: values.comment
            }]
          })
        };

        // save new user
        dispatch(createEvaluation(newEvaluation));
        validation.resetForm();
      }
      toggle();
    }
    // toggle();
  });


  return (
    <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "49vw" }}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Fiche évaluation") : props.t("Fiche évaluation")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Col className="col-12">
            <div className="row">
              <div className="col-6 mb-3">
                <Label className="form-label mb-3">
                  id: {" " + userSearch?.id}
                </Label>
                <br />

                <Label className="form-label mb-3">
                  date:
                  {" " +
                    moment(userSearch?.createdAt).format("YYYY-MM-DD HH:mm")}
                </Label>
                <br />
                <Label className="form-label mb-3">
                  User: {user?.firstname + " " + user?.lastname}
                </Label>
                <br />
              </div>
              <div className="col-3 mb-3">
                <Label className="form-label">
                  Professionnel:{" "}
                  {" " +
                    userSearch?.professional?.firstname +
                    " " +
                    userSearch?.professional?.lastname}
                </Label>
                <br />
                <Label className="form-label">
                  Chercheur:{" "}
                  {" " +
                    userSearch?.seeker?.firstname +
                    " " +
                    userSearch?.seeker?.lastname}
                </Label>
              </div>
              <div className="col-3 mb-3">
                {/* <Label className="form-label">Evaluation Moyenne:</Label> */}
              </div>
            </div>
          </Col>

          <Row form>
            <Col xs={12}>
              <Col lg="12">
                <Card>
                  <CardBody>

                    <TabContent
                      activeTab={activeTabVartical}
                      className="body"
                    >
                      <TabPane tabId={1}>
                        <Form>
                          <div className="col-12 mb-5">
                            <div className="row">
                              <div className="col-12"><Label className="form-label mb-3 mx-3 text-primary" style={{ fontSize: '1rem' }}>
                                Tarification:
                              </Label>{" "}</div>

                              <div className="col-12 mx-5">

                                <input
                                disabled
                                  type="radio"
                                  name="cr1"
                                  className="form-check-input  me-2"
                                  id="customRadio1"
                                  // value={validation.setFieldValue(cr1,"1")}
                                  // checked={validation.values.cr1 === "1" }
                                  onClick={e => {

                                    validation.setFieldValue("cr1", 5)


                                  }}
                                  value={"5"}
                                  checked={validation.values.cr1 == "5"}
                                  onBlur={validation.handleBlur}

                                />
                                <label
                                  className="form-radio-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Expert
                                </label>
                                <Input
                                disabled
                                  type="radio"
                                  name="cr1"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {
                                    validation.setFieldValue("cr1", 4)


                                  }}
                                  value={"4"}
                                  checked={validation.values.cr1 == "4"}
                                  onBlur={validation.handleBlur}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Bien
                                </label>
                                <Input
                                disabled
                                  type="radio"
                                  name="cr1"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr1", 3)

                                  }}
                                  value={"3"}
                                  checked={validation.values.cr1 == "3"}
                                  onBlur={validation.handleBlur}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Intermédiaire
                                </label>
                                <Input
                                disabled
                                  type="radio"
                                  name="cr1"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr1", 2)

                                  }}
                                  value={"2"}
                                  checked={validation.values.cr1 == "2"}
                                  onBlur={validation.handleBlur}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Débutant
                                </label>
                                <Input
                                disabled
                                  type="radio"
                                  name="cr1"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr1", 1)

                                  }}
                                  value={"1"}
                                  checked={validation.values.cr1 == "1"}
                                  onBlur={validation.handleBlur}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Médiocre
                                </label>
                                <Input
                                disabled
                                  type="radio"
                                  name="cr1"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr1", 0)

                                  }}
                                  value={"0"}
                                  checked={validation.values.cr1 == "0"}
                                  onBlur={validation.handleBlur}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Non évalué
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-5">
                            <div className="row">
                              <div className="col-12">

                                <Label className="form-label mb-3 text-primary mx-3" style={{ fontSize: '1rem' }}>
                                  Efficacité :
                                </Label>{" "}
                              </div>

                              <div className="col-12 mx-5">
                                <br />
                                <input
                                disabled
                                  type="radio"
                                  name="cr2"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr2", 5)

                                  }}
                                  value={"5"}
                                  checked={validation.values.cr2 == "5"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Expert
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr2"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr2", 4)

                                  }}
                                  value={"4"}
                                  checked={validation.values.cr2 == "4"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Bien
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr2"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr2", 3)

                                  }}
                                  value={"3"}
                                  checked={validation.values.cr2 == "3"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Intermédiaire
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr2"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr2", 2)

                                  }}
                                  value={"2"}
                                  checked={validation.values.cr2 == "2"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Débutant
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr2"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr2", 1)

                                  }}
                                  value={"1"}
                                  checked={validation.values.cr2 == "1"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Médiocre
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr2"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {
                                    validation.setFieldValue("cr2", 0)

                                  }}
                                  value={"0"}
                                  checked={validation.values.cr2 == "0"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Non évalué
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-5">
                            <div className="row">
                              <div className="col-12 mx-3"> <Label className="form-label mb-3 text-primary" style={{ fontSize: '1rem' }}>
                                Ponctualité:
                              </Label>{" "}
                              </div>


                              <div className="col-12 mx-5">
                                <input
                                disabled
                                  type="radio"
                                  name="cr3"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr3", 5)

                                  }}
                                  value={"5"}
                                  checked={validation.values.cr3 == "5"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Expert
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr3"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr3", 4)

                                  }}
                                  value={"4"}
                                  checked={validation.values.cr3 == "4"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Bien
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr3"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr3", 3)

                                  }}
                                  value={"3"}
                                  checked={validation.values.cr3 == "3"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Intermédiaire
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr3"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr3", 2)

                                  }}
                                  value={"2"}
                                  checked={validation.values.cr3 == "2"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Débutant
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr3"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr3", 1)

                                  }}
                                  value={"1"}
                                  checked={validation.values.cr3 == "1"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Médiocre
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr3"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr3", 0)

                                  }}
                                  value={"0"}
                                  checked={validation.values.cr3 == "0"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Non évalué
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-5">
                            <div className="row">
                              <div className="col-12"><Label className="form-label mb-3 text-primary mx-3" style={{ fontSize: '0.85rem' }}>
                                Qualité de travail:
                              </Label>{" "}
                              </div>
                              <div className="col-12 mx-5">
                                <input
                                disabled
                                  type="radio"
                                  name="cr4"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr4", 5)

                                  }}
                                  value={"5"}
                                  checked={validation.values.cr4 == "5"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Expert
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr4"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr4", 4)

                                  }}
                                  value={"4"}
                                  checked={validation.values.cr4 == "4"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Bien
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr4"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr4", 3)

                                  }}
                                  value={"3"}
                                  checked={validation.values.cr4 == "3"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Intermédiaire
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr4"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr4", 2)

                                  }}
                                  value={"2"}
                                  checked={validation.values.cr4 == "2"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Débutant
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr4"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr4", 1)

                                  }}
                                  value={"1"}
                                  checked={validation.values.cr4 == "1"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Médiocre
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr4"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr4", 0)

                                  }}
                                  value={"0"}
                                  checked={validation.values.cr4 == "0"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Non évalué
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-5">
                            <div className="row">
                              <div className="col-12"><Label className="form-label mb-3 text-primary mx-3" style={{ fontSize: '1rem' }} >
                                Rapidité:
                              </Label>{" "}
                              </div>
                              <div className="col-12 mx-5">
                                <input
                                disabled
                                  type="radio"
                                  name="cr5"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr5", 5)

                                  }}
                                  value={"5"}
                                  checked={validation.values.cr5 == "5"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Expert
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr5"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr5", 4)

                                  }}
                                  value={"4"}
                                  checked={validation.values.cr5 == "4"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Bien
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr5"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {
                                    validation.setFieldValue("cr5", 3)

                                  }}
                                  value={"3"}
                                  checked={validation.values.cr5 == "3"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Intermédiaire
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr5"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr5", 2)

                                  }}
                                  value={"2"}
                                  checked={validation.values.cr5 == "2"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Débutant
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr5"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr5", 1)

                                  }}
                                  value={"1"}
                                  checked={validation.values.cr5 == "1"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Médiocre
                                </label>
                                <input
                                disabled
                                  type="radio"
                                  name="cr5"
                                  className="form-check-input  me-2"
                                  id="customCheck2"
                                  onClick={e => {

                                    validation.setFieldValue("cr5", 0)

                                  }}
                                  value={"0"}
                                  checked={validation.values.cr5 == "0"}
                                />
                                <label
                                  className="form-check-label  me-4"
                                  htmlFor="customCheck2"
                                >
                                  Non évalué
                                </label>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </TabPane>




                    </TabContent>

                  </CardBody>
                </Card>
                <div className="mb-3">
                  <Label className="form-label mb-3">{props.t("Autre commentaires :")}</Label>{" "}
                 
                  {userSearch?.professionalSearch?.comments?.map(el => (
                    <div key={el.id} className="mb-3 d-flex">
                      <div>
                        <p className="mx-1 fw-bold text-nowrap">{el.admin?.firstname + " " + el.admin?.lastname + " :"}</p>
                      </div>
                      <div>
                        <p className="mx-1">{el.description}</p>
                        <p className="mx-1 mw-100">{"Le " + moment(new Date(el.createdAt)).format("DD MMM, YYYY à HH : mm")}</p>
                      </div>
                    </div>
                  ))}
                </div>
                
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-end">
                <Input
                disabled
                  type="select"
                  name="status"
                  className="form-select me-2 bg-white"
                  style={{ width: 150 }}
                  value={userSearch?.professionalSearch?.status}
                  onChange={validation.handleChange}
                >
                  <option key="open">Ouverte</option>
                  <option key="closed">Clôturée</option>
                  <option key="realised">Réalisée</option>
                  <option key="refused">Refusée</option>
                </Input>
                {/* <button
                  type="submit"
                  disabled
                  className="btn btn-success save-customer w-25"
                >
                  {props.t("save")}
                </button> */}
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(EvaluationModalEdit);
