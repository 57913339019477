import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  
  createSearchFailed,
  getSearchFailed,
  getSearchStatsFailed,
  getSearchStatsSuccess,
  getSearchSuccess,
  updateSearchFailed,
  updateSearchSuccess,

} from "./actions"
import {
 
  CREATE_SEARCH_REQUEST,
  GET_SEARCH_REQUEST, GET_SEARCH_STATS_REQUEST, UPDATE_SEARCH_REQUEST,
 
} from "./actionTypes";



import {  getSearch, getSearchStats, postSearch, putSearch } from "helpers/api_helper";



function* getSearchSaga({payload}) {
  try {
    
    const response = yield getSearch(payload || {page: 1, limit: 1000});

    yield put(getSearchSuccess({...response, success: "GET"}))
  } catch (e) {
    yield put(getSearchFailed(e))
  }
}
function* getSearchStatsSaga() {
  try {
    
    const response = yield getSearchStats();

    yield put(getSearchStatsSuccess(response))
  } catch (e) {
    yield put(getSearchStatsFailed(e))
  }
}

function* updateSearchSaga({ payload: Search }) {
  try {
    // separer l id & objet Search
    const { id, ...updatedSearch } = Search

    const data = yield putSearch(id, updatedSearch);
    const response = yield getSearch({limit: 1000, page: 1});
    yield put(getSearchSuccess({...response, success: "UPDATE"}))
  } catch (e) {
    yield put(updateSearchFailed(e))
  }
}


function* createSearchSaga({ payload: search }) {
  try {
    const data = yield postSearch(search);
    // console.log("dataa",data)
    const response = yield getSearch({limit: 20, page: 1});
    // console.log("response",response)
    yield put(getSearchSuccess(response))

  } catch (e) {
    yield put(createSearchFailed(e))
  }
}

export default function* SearchSaga() {
  yield all([
    takeEvery(GET_SEARCH_REQUEST, getSearchSaga),
    takeEvery(GET_SEARCH_STATS_REQUEST, getSearchStatsSaga),
    takeEvery(UPDATE_SEARCH_REQUEST, updateSearchSaga),
    takeEvery(CREATE_SEARCH_REQUEST, createSearchSaga),
   
  ])
}
