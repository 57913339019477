import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { withTranslation } from "react-i18next";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  FormGroup,
  InputGroup,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import { createSearch, getMDMProfession, getProfessionnal, updateRequest } from "store/actions";
import moment from "moment";

const DemandeServiceModal = (props) => {
  const { modal, toggle, Service, isEdit } = props;
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  });
  const {professiannalData} = useSelector((state) => state.professionnals);
  const { professionData } = useSelector((state) => state.professions);
  const dispatch = useDispatch();

 

  const { user } = useSelector((state) => state.Login);

  useEffect(() => {
    {
      dispatch(getMDMProfession(filter));
      dispatch(getProfessionnal());
    }
  }, [dispatch, filter]);

  const hundlechange = (date, str, instance) => {

    if (instance.input.name == "start_date") {
      validation.setFieldValue("start_date", date[0]);

    }
    else if (instance.input.name == "end_date") {
      validation.setFieldValue("end_date", date[0]);
    }


  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // moment(validation.values.start_date).format("DD/MM/YYYY")
      firstname: (Service && Service.seeker?.firstname) || "",
      lastname: (Service && Service.seeker?.lastname) || "",
      phone: (Service && Service.seeker?.phone) || "",
      professionId: (Service && Service.professionId) || "",
      start_date: (Service && new Date(Service?.start_date)) || "",
      end_date: (Service && new Date(Service?.end_date)) || "",
      minPrice: (Service && Service.minPrice) || 0,
      maxPrice: (Service && Service.maxPrice) || 0,
      quality: (Service && Service.quality) || "",
      description: (Service && Service.description) || "",
      remark: (Service && Service.remark) || "",
      status: (Service && Service.status) || "",
      governorateId: (Service && Service.governorateId) || "",
      delegationId: (Service && Service.delegationId) || "",
      localityId: (Service && Service.localityId) || "",
      seekerId: (Service && Service.seekerId) ||"",
      comments: (Service && Service.comments) || [],
      comment: "",
      professionalId: (Service && Service.professionalId) || "",
      
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      if (isEdit) {
        // update

        dispatch(updateRequest({
          id: Service.id,
          adminId: user?.id,
          remark: values.remark,
          phone: values.phone,
          minPrice: parseInt(values.minPrice),
          maxPrice: parseInt(values.maxPrice),
          quality: values.quality,
          start_date: values.start_date,
          status: values.status,
          professionalId: values.professionalId,
          end_date: values.end_date ? new Date(values.end_date) : "",
          ...(values.comment && {
            comments: [{
              adminId: user?.id, description: values.comment
            }]
          })
        }));
        if(validation.values.status = "réalisée")
        {
          dispatch(createSearch({
          governorateId: parseInt(values.governorateId),
          delegationId: parseInt(values.delegationId),
          localityId: parseInt(values.localityId),
          professionalId: values.professionalId,
          seekerId: values.seekerId,
          status: "Ouverte",
        }));
      
        dispatch(updateRequest({
          id: Service.id,
          professionalId: values.professionalId,
         
        }));
      
      }
        validation.resetForm();
      } else {
        const newService = {
          professionId: parseInt(values["professionId"]) || null,
          governorateId: parseInt(values["governorateId"]) || null,
          firstname: values["firstname"],
          lastname: values["lastname"],
          start_date: values["start_date"],
          end_date: values["end_date"],
          phone: values["phone"],
          minPrice: values["minPrice"],
          maxPrice: values["maxPrice"],
          quality: values["quality"],
          description: values["description"],
        };
       
        validation.resetForm();
      }
      toggle();
    },
  });


  return (
    <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "50vw" }}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Modifier demande service VIP ") : props.t("Demande Service VIP")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >

          <Row form>
            <Row>
              <Col className="col-12">
                <div className="row">
                  <div className="col-6 mb-3">
                    <Label className="form-label">{props.t("Nom")}</Label>
                    <Input
                      name="firstname"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname || ""}
                      disabled
                      invalid={
                        validation.touched.firstname &&
                          validation.errors.firstname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstname &&
                      validation.errors.firstname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstname}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="col-6 mb-3">
                    <Label className="form-label">{props.t("Prénom")}</Label>
                    <Input
                      name="lastname"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      disabled
                      value={validation.values.lastname || ""}
                      invalid={
                        validation.touched.lastname &&
                          validation.errors.lastname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastname &&
                      validation.errors.lastname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastname}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Télèphone")}</Label>
                  <Input
                    name="phone"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone || ""}
                    disabled
                    invalid={
                      validation.touched.phone && validation.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="row">
                  <div className="mb-3">
                    <Label className="form-label">
                      {props.t("Il cherche")}
                    </Label>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3">
                    <Label className="form-label">
                      {props.t("Profession")}
                    </Label>
                    <Input
                      type="select"
                      name="professionId"
                      className="form-select"
                      disabled
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.professionId || []}
                      invalid={
                        validation.touched.professionId &&
                          validation.errors.professionId
                          ? true
                          : false
                      }
                    >
                      <option key="empty" disabled></option>
                      {professionData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.professionId &&
                      validation.errors.professionId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.professionId}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label ">période :</Label>
                    <Col lg="10">
                      <Row>
                        <Col md={6} className="pr-0">
                          <Label className="form-label">
                            {props.t("Date début")}
                          </Label>
                          <InputGroup>
                            <Flatpickr
                              name="start_date"
                              className="form-control d-block"
                              value={validation.values.start_date || ""}
                              onChange={hundlechange}
                              placeholder="dd M,yyyy"
                              options={{
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                          {validation.touched.start_date &&
                            validation.errors.start_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.start_date}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={6} className="pl-0">
                          <Label className="form-label">
                            {props.t("Date fin")}
                          </Label>
                          <Flatpickr
                            name="end_date"
                            className="form-control d-block"
                            value={validation.values.end_date || ""}
                            onChange={hundlechange}
                            placeholder="dd M,yyyy"
                            options={{
                              dateFormat: "Y-m-d",
                            }}
                          />
                          {validation.touched.end_date &&
                            validation.errors.end_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.end_date}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </div>

                <FormGroup className="mb-4" row>
                  <Label className="col-form-label ">Honoraire proposé :</Label>
                  <Col lg="10">
                    <Row>
                      <Col md={6} className="pr-0">
                        <Label className="form-label">{props.t("min")}</Label>
                        <Input
                          name="minPrice"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.minPrice || ""}

                          invalid={
                            validation.touched.minPrice && validation.errors.minPrice
                              ? true
                              : false
                          }
                        />
                        {validation.touched.minPrice && validation.errors.minPrice ? (
                          <FormFeedback type="invalid">
                            {validation.errors.minPrice}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={6} className="pl-0">
                        <Label className="form-label">{props.t("max")}</Label>
                        <Input
                          name="maxPrice"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.maxPrice || ""}

                          invalid={
                            validation.touched.maxPrice && validation.errors.maxPrice
                              ? true
                              : false
                          }
                        />
                        {validation.touched.maxPrice && validation.errors.maxPrice ? (
                          <FormFeedback type="invalid">
                            {validation.errors.maxPrice}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>

                <div className="mb-3">
                  <Label className="form-label">
                    {props.t("Qualité requise")}
                  </Label>
                  <Input
                    type="text"
                    name="quality"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.quality || []}

                    invalid={
                      validation.touched.quality && validation.errors.quality
                        ? true
                        : false
                    }
                  >
                    <option key="empty" disabled></option>
                  </Input>
                  {validation.touched.quality && validation.errors.quality ? (
                    <FormFeedback type="invalid">
                      {validation.errors.quality}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">
                    {props.t("Tâches à faire détaillées :")}
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    rows={4}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || []}

                    invalid={
                      validation.touched.description &&
                        validation.errors.description
                        ? true
                        : false
                    }
                  >
                    <option key="empty" disabled></option>
                  </Input>
                  {validation.touched.description &&
                    validation.errors.description ? (
                    <FormFeedback type="invalid">
                      {validation.errors.description}
                    </FormFeedback>
                  ) : null}
                </div>

              
                <div className="mb-3">
                  <Label className="form-label mb-3">{props.t("Autre commentaires :")}</Label>{" "}
                  {validation.values.comments.map(el => (
                    <div key={el.id} className="mb-3 d-flex">
                      <div>
                        <p className="mx-1 fw-bold text-nowrap">{el.admin?.firstname + " " + el.admin?.lastname + " :"}</p>
                      </div>
                      <div>
                        <p className="mx-1">{el.description}</p>
                        <p className="mx-1 mw-100">{"Le "+moment(new Date(el.createdAt)).format("DD MMM, YYYY à HH : mm")}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Commentaires :")}</Label>{" "}
                  <Input
                    type="textarea"
                    name="comment"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.comment}
                    invalid={
                      validation.touched.comment && validation.errors.comment
                        ? true
                        : false
                    }
                  />
                  {validation.touched.comment && validation.errors.comment ? (
                    <FormFeedback type="invalid">
                      {validation.errors.comment}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
           {validation.values.status == "réalisée" &&<div className="mb-3">
            <Label>Professionnel:</Label>
            <Input
                  type="select"
                  name="professionalId"
                  placeholder="Professionnel"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.professionalId}
                  className="form-select me-2"
                  style={{ width: 150 }}
                >
                <option key="empty" disabled></option>
                      {professiannalData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.firstname + " " + item.lastname}
                        </option>
                      ))}
               </Input>
               </div>}
              <div className="d-flex justify-content-end">
              
                <Input
                  type="select"
                  name="status"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.status}
                  className="form-select me-2"
                  style={{ width: 150 }}
                >
                 
              
                  <option value="réalisée">réalisée</option>
                  <option value="ouverte">ouverte</option>
                  <option value="encours">encours</option>
                </Input>
                <button
                  type="submit"
                  className="btn btn-success save-customer"
                >
                  {props.t("save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(DemandeServiceModal);
