import {
    DELEGATION_MESSAGES_RESET,
    RESET_DELEGATION_MESSAGES_SUCCESS,
    CREATE_DELEGATION_FAILED,
    CREATE_DELEGATION_REQUEST,
    CREATE_DELEGATION_SUCCESS,
    DELETE_DELEGATION_FAILED,
    DELETE_DELEGATION_REQUEST,
    DELETE_DELEGATION_SUCCESS,
    GET_DELEGATION_BY_NAME_FAILED,
    GET_DELEGATION_BY_NAME_REQUEST,
    GET_DELEGATION_BY_NAME_SUCCESS,
    GET_DELEGATION_FAILED,
    GET_DELEGATION_REQUEST,
    GET_DELEGATION_SUCCESS,
    UPDATE_DELEGATION_FAILED,
    UPDATE_DELEGATION_REQUEST,
    UPDATE_DELEGATION_SUCCESS,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    delegationData: [],
    success: "",
    error: "",
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_DELEGATION_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_DELEGATION_SUCCESS:
        const {data, ...filters} = action.payload
        return {
          ...state,
          delegationData: [...data.map(gov => ({...gov, children: gov.children.filter(loc => loc.status === "active")}))],
          ...filters,
          loading: false,
          success: "GET",
        }
      case GET_DELEGATION_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_DELEGATION_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_DELEGATION_BY_NAME_SUCCESS:
        return {
          ...state,
          delegationData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_DELEGATION_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_DELEGATION_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_DELEGATION_SUCCESS:
        return {
          ...state,
          delegationData: [...state.delegationData, action.payload],
          success: "CREATE",
          loading: false,
        }
      case CREATE_DELEGATION_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_DELEGATION_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_DELEGATION_SUCCESS:
        return {
          ...state,
          delegationData: state.delegationData.filter(
            delegation => delegation.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_DELEGATION_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_DELEGATION_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case UPDATE_DELEGATION_SUCCESS:
        return {
          ...state,
          delegationData: state.delegationData.map(delegation =>
            delegation.id.toString() === action.payload.id.toString()
              ? { delegation, ...action.payload }
              : delegation
          ),
          success: "UPDATE",
          loading: false,
        }
      case UPDATE_DELEGATION_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case DELEGATION_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_DELEGATION_MESSAGES_SUCCESS:
          return {
            ...state,
            error: null,
            success: null
          }
      default:
        return state
    }
  }
  
  export default reducer
  
