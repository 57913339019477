import React, { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  Spinner,
  FormGroup,
  InputGroup,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { createMDMAds, getProfessionnal, updateMDMAds } from "store/actions";
import { upload } from "helpers/api_helper";
import moment from "moment";

const PublicityModal = (props) => {
  const dispatch = useDispatch();
  const { modal, toggle, Publicity, isEdit } = props;
  const [loading, setLoading] = useState(false);
  const fileRef = useRef();
 
  // date state
  const { user } = useSelector((state) => state.Login);



  useEffect(() => {
    {
      dispatch(getProfessionnal());
    }
  }, [dispatch]);

  const { departmentData } = useSelector(state => state.departments)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (Publicity && Publicity.name) || "",
      type: (Publicity && Publicity.type) || "Slider",
      links: (Publicity && Publicity.links) || [],
      deleted_links: [],
      professionalId: (Publicity && Publicity.professionalId) || "",
      contentType: (Publicity && Publicity.contentType) || "Image",
      status: (Publicity && Publicity.status) || "",
      addedBy: (Publicity && Publicity.addedBy) || "",
      start_date: (Publicity && moment(new Date(Publicity?.start_date)).format("YYYY-MM-DD")) || "",
      end_date: (Publicity && moment(new Date(Publicity?.end_date)).format("YYYY-MM-DD")) || "",
      departments: (Publicity && Publicity.departments.map(el => ({ value: el.id, label: el.name }))) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Your Name")),
      
      start_date: Yup.date().required(props.t("Please Enter Your start date")),
      end_date: Yup.date().required(props.t("Please Enter Your end date")),

    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateAds = {
          id: Publicity.id,
          name: values.name,
          professionalId: parseInt(values.professionalId),
          type: values.type,
          links: values.links,
          deleted_links: values.deleted_links,
          contentType: values.contentType,
          amount: values.amount,
          status: values.status,
          start_date: values.start_date ? moment(new Date(values.start_date)).format("YYYY-MM-DD") : "",
          end_date: values.end_date ? moment(new Date(values.end_date)).format("YYYY-MM-DD") : "",
          ...(values.departments.length > 0 && { departments: values.departments.map(el => el.value) })
        };
        // update
        // console.log("updateAds", updateAds);
        dispatch(updateMDMAds(updateAds));
        validation.resetForm();
      } else {

        const newPub = {
          name: values["name"],
          professionalId: parseInt(values["professionalId"]) || null,
          type: values["type"],
          contentType: values["contentType"],
          links: values["links"],
          start_date: values["start_date"],
          end_date: values["end_date"],
          status: values["status"],
          addedById: user?.id || null,
          ...(values.departments.length > 0 && { departments: values.departments.map(el => el.value) })
        };
        // save new Publicity

        dispatch(createMDMAds(newPub));
        validation.resetForm();
      }
      toggle();
    },
  });

  const hundlechange = (date, str, instance) => {

    if (instance.input.name == "start_date") {
      validation.setFieldValue("start_date", date[0]);
      if (date[0] < new Date() && new Date() > validation.values.end_date)
        validation.setFieldValue("status", "active");
    }
    else if (instance.input.name == "end_date") {
      validation.setFieldValue("end_date", date[0]);
      if (date[0] > new Date() && new Date() > validation.values.start_date)
        validation.setFieldValue("status", "active");

    }


  };

  const handelImage = (event) => {
    setLoading(true);
    let formData = new FormData();
    formData.set("file", event.target.files[0]);
    upload(formData)
      .then(({ path }) => {
        setLoading(false);
        validation.setFieldValue("links", [
          ...validation.values.links,
          {
            value: path,
            type: "Image"
          },
        ]);
        
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    fileRef?.current?.click();
  };

  const resetFileHandler = (e, id) => {
    e.stopPropagation()
    validation.setFieldValue("deleted_links", [...validation.values.deleted_links, id])
    validation.setFieldValue("links", validation.values.links?.filter(el => el.id != id))
    dispatch(updateMDMAds({ id: Publicity.id, deleted_links: [id] }));
  };

  const onChangeDepartmentHandler = (departments) => {
    validation.setFieldValue("departments", departments);
  }



  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit
          ? props.t("Modifier une publicité")
          : props.t("Ajouter une publicité")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Row>
              <Col className="col-12">
                <Col className="col-11">
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Added by")}</Label>
                    <Input
                      name="addedBy"
                      type="text"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={user?.firstname + " " + user?.lastname}
                      disabled
                      invalid={
                        validation.touched.addedBy && validation.errors.addedBy
                          ? true
                          : false
                      }
                    />

                    {validation.touched.addedBy && validation.errors.addedBy ? (
                      <FormFeedback type="invalid">
                        {validation.errors.addedBy}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Name")}</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="mb-3">
                      <Label className="form-label">
                     
                        {props.t("Type de la publicté")}
                      </Label>
                      <Input
                        type="select"
                        name="type"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || []}
                        invalid={
                          validation.touched.type && validation.errors.type
                            ? true
                            : false
                        }
                      >
                        <option key="empty" disabled></option>
                        <option key="id">Slider</option>
                        <option key="id1">Suggestion payante</option>
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>


                  </div>
                  <div>
                    {validation.values?.links?.length > 0 ?
                      <>
                        <Label className="form-label">{props.t("Image")}</Label>
                        <div className="row mb-5">
                          <div className="col-12">
                            {validation.values?.links?.map((item, index) =>
                              <div key={index} className="position-relative mb-2">
                                <button className="btn btn-light w-100" onClick={handleFileUpload}>
                                  {!loading && !item.value && props.t("Upload Image")}
                                  {loading && <Spinner className="text-center mt-1" size="sm" />}
                                  {!loading && item.value.split("/")[item.value.split('/').length - 1]}
                                </button>
                                <span className="btn btn-info btn-icon sketch-picker ms-n4 mt-n2 rounded-circle" onClick={(e) => resetFileHandler(e, item.id)}>
                                  <i className="bx bx-x bx-xs"></i>
                                </span>
                                <Input
                                  innerRef={fileRef}
                                  name="links"
                                  type="file"
                                  hidden
                                  validate={{
                                    required: { value: true },
                                  }}
                                  accept=".png, .jpg, .jpeg,"
                                  onChange={handelImage}
                                  onBlur={validation.handleBlur}
                                  //value={convertToBlob(validation.values.image_url)}
                                  invalid={
                                    validation.touched.logo && validation.errors.logo
                                      ? true
                                      : false
                                  }
                                />
                              </div>)}
                          </div>
                        </div>
                      </>
                      : null}
                    {
                      validation.touched.logo && validation.errors.logo ? (
                        <FormFeedback type="invalid">
                          {validation.errors.logo}
                        </FormFeedback>
                      ) : null
                    }
                  </div>


                  <div className="mb-5">
                    <Label className="form-label">{validation.values.contentType == "Image"}</Label>
                    <div className="row">
                      <div className="col-12">
                        <div className="position-relative">
                          <Input
                            className="position-absolute"
                            name="links"
                            type="file"
                            validate={{
                              required: { value: true },
                            }}
                            accept=".png, .jpg, .jpeg,"
                            onChange={handelImage}
                            onBlur={validation.handleBlur}
                            //value={validation.values.logo || ""}
                            invalid={
                              validation.touched.logo &&
                                validation.errors.logo
                                ? true
                                : false
                            }
                          />
                          {loading && (
                            <Spinner
                              className="mt-2 float-end me-3"
                              size="sm"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {validation.touched.logo && validation.errors.logo ? (
                      <FormFeedback type="invalid">
                        {validation.errors.logo}
                      </FormFeedback>
                    ) : null}
                  </div>
                  {validation.values.type !== "Slider" ? <div className="row">
                    <div className="mb-3">
                      <Label className="form-label">
                        {props.t("Départements")}
                      </Label>
                      <ReactSelect
                        className="mb-2 me-2"
                        isMulti
                        value={validation.values.departments}
                        onChange={(value) => onChangeDepartmentHandler(value)}
                        placeholder={props.t("Select department")}
                        options={departmentData?.map((department) => {
                          return {
                            value: department.id,
                            label: department.name,
                          };
                        })}
                        classNamePrefix="select2-selection"
                      />
                    </div>


                  </div>
                    : null}
                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label ">
                      Durée de publicité :
                    </Label>
                    <Col lg="10">
                      <Row>
                        <Col md={6} className="pr-0">
                          <Label className="form-label">
                            {props.t("Date de début")}
                          </Label>
                          
                          <InputGroup>
                            <Flatpickr
                              name="start_date"
                              className="form-control d-block bg-white"
                              value={validation.values.start_date || ""}
                              onChange={hundlechange}
                              placeholder="dd M,yyyy"
                              options={{
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </Col>
                        <Col md={6} className="pl-0">
                          <Label className="form-label">
                            {props.t("Date de fin")}
                          </Label>
                          <Flatpickr
                            name="end_date"
                            className="form-control d-block bg-white"
                            value={validation.values.end_date || ""}
                            onChange={hundlechange}
                            placeholder="dd M,yyyy"
                            options={{
                              dateFormat: "Y-m-d",
                            }}
                          />
                          
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Col>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-customer">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(PublicityModal);
