import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createEvaluationFailed,
  createEvaluationSuccess,
  deleteEvaluationFailed,
  deleteEvaluationSuccess,
  getEvaluationByIdFailed,
  getEvaluationByIdSuccess,
  getEvaluationFailed,
  getEvaluationSuccess,
  updateEvaluationFailed,
  updateEvaluationSuccess,
} from "./actions"
import {
  EVALUATION_MESSAGES_RESET,
  CREATE_EVALUATION_REQUEST,
  DELETE_EVALUATION_REQUEST,
  GET_EVALUATION_BY_NAME_REQUEST,
  GET_EVALUATION_REQUEST,
  RESET_EVALUATION_MESSAGES,
  UPDATE_EVALUATION_REQUEST,
  GET_EVALUATIONBYID_REQUEST
} from "./actionTypes";



import {  delEvaluation, getEvaluation, postEvaluation, putEvaluation } from "helpers/api_helper";



function* getEvaluationSaga({payload}) {
  try {
    
    const response = yield getEvaluation(payload || {page: 1, limit: 1000});

    yield put(getEvaluationSuccess(response))
  } catch (e) {
    yield put(getEvaluationFailed(e))
  }
}



function* getEvaluationByIdSaga({payload}) {
  try {
    const response = yield getEvaluationById(payload);
    yield put(getEvaluationByIdSuccess({...payload, success: "GET"}));
  } catch (e) {
    yield put(getEvaluationByIdFailed(e.response?.status));
  }
}

function* getEvaluationBynameSaga() {

}

function* getEvaluationFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
     //console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getEvaluationFailed(e))
  }
}

function* createEvaluationSaga({ payload: evaluation }) {
  try {
    const data = yield postEvaluation(evaluation);

    const response = yield getEvaluation({limit: 20, page: 1});
    yield put(getEvaluationSuccess({...response, success: "CREATE"}))
    // yield put(createEvaluationSuccess(response))
  } catch (e) {
    yield put(createEvaluationFailed(e))
  }
}

function* deleteEvaluationSaga( payload ) {
  try {
    const result = yield delEvaluation(payload.payload.id)
    const data = yield getEvaluation({ current: 1, limit: 1000 })
    yield put(getEvaluationSuccess(data))
  } catch (e) {
    yield put(deleteEvaluationFailed(e))
  }
}

function* updateEvaluationSaga({ payload: evaluation }) {
  try {
    // separer l id & objet Evaluation
    const { id, ...updatedEvaluation } = evaluation

    const data = yield putEvaluation(id, updatedEvaluation);
    const response = yield getEvaluation({limit: 20, page: 1});
    yield put(getEvaluationSuccess({...response, success: "UPDATE_EVALUATION"}))
  } catch (e) {
    yield put(updateEvaluationFailed(e))
  }
}

function* resetEvaluationMessagesSaga() {
  yield put(resetEvaluationMessagesSuccess())
}




export default function* evaluationSaga() {
  yield all([
    takeEvery(GET_EVALUATION_REQUEST, getEvaluationSaga),
    takeEvery(GET_EVALUATIONBYID_REQUEST, getEvaluationByIdSaga),
    takeEvery(GET_EVALUATION_BY_NAME_REQUEST, getEvaluationBynameSaga),
    takeEvery(CREATE_EVALUATION_REQUEST, createEvaluationSaga),
    takeEvery(DELETE_EVALUATION_REQUEST, deleteEvaluationSaga),
    takeEvery(UPDATE_EVALUATION_REQUEST, updateEvaluationSaga),
    takeEvery(RESET_EVALUATION_MESSAGES, resetEvaluationMessagesSaga),
  ])
}
