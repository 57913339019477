import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  Spinner,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import { upload } from "helpers/api_helper";
import {
  createMDMProfession,
  getMDMDepartment,
  updateMDMProfession,
} from "store/actions";

import { withTranslation } from "react-i18next";

const ProfessionModal = (props) => {
  const dispatch = useDispatch();
  const { modal, toggle, profession, isEdit } = props;
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  });
  const { departmentData } = useSelector((state) => state.departments);
 
  useEffect(() => {
    {
      dispatch(getMDMDepartment());
    }
  }, [dispatch, filter]);

  // console.log(loading)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (profession && profession.name) || "",
      type: (profession && profession.type) || "profession",
      status: (profession && profession.status) || "active",
      parentId: (profession && profession.parentId) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Your Name")),
      parentId: Yup.string().required(props.t("Please Enter Your department")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateProfession= {
          id: profession.id,
          name: values.name,
          imageUrl: values.imageUrl,
          parentId: parseInt(values.parentId),
        };
        // update
   
        dispatch(updateMDMProfession(updateProfession));
        validation.resetForm();
      } else {
        const newProfession = {
          imageUrl: values["imageUrl"],
          name: values["name"],
          type: values["type"],
          status: values["status"],
          parentId: parseInt(values["parentId"]),
        };
      
        //save new governorate
        dispatch(createMDMProfession(newProfession));
        validation.resetForm();
      }
      toggle();
    },
  });

  const handelImage = (event) => {
    setLoading(true);
    let formData = new FormData();
    formData.set("file", event.target.files[0]);
    upload(formData)
      .then(({ path }) => {
        setLoading(false);
        validation.setFieldValue("imageUrl", path);
      })
      .catch((e) => {
        setLoading(false);
        // console.log(e.response)
      });
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Modifier une profession") : props.t("Ajouter une profession")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-5">
                <Label className="form-label">Logo</Label>
                <div className="row">
                  <div className="col-12">
                    <div className="position-relative">
                      <Input
                        className="position-absolute"
                        // id="inputGroupFile04"
                        // aria-describedby="inputGroupFileAddon04"
                        // aria-label="Upload"
                        name="imageUrl"
                        type="file"
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={handelImage}
                        onBlur={validation.handleBlur}
                        //value={validation.values.logo || ""}
                        invalid={
                          validation.touched.logo && validation.errors.logo
                            ? true
                            : false
                        }
                      />
                      {loading && (
                        <Spinner className="mt-2 float-end me-3" size="sm" />
                      )}
                      {/* <i
                          className="far fa-times-circle"
                          id="inputGroupFileAddon04" type="button" onClick={onRemove}
                        ></i>
                        <i className="dripicons-document-delete"/> */}
                    </div>
                  </div>
                </div>
                {validation.touched.logo && validation.errors.logo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">{props.t("Département")}</Label>
                <Input
                  type="select"
                  name="parentId"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.parentId || []}
                  invalid={
                    validation.touched.parentId && validation.errors.parentId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {departmentData.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Input>
                {validation.touched.parentId && validation.errors.parentId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.parentId}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-customer">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(ProfessionModal);
