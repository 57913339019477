
/**filter */
export const GET_BANNERS_BY_NAME_SUCCESS = "GET_BANNERS_BY_NAME_SUCCESS";
export const GET_BANNERS_BY_NAME_REQUEST = "GET_BANNERS_BY_NAME_REQUEST";
export const GET_BANNERS_BY_NAME_FAILED = "GET_BANNERS_BY_NAME_FAILED";

/**Get */
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_REQUEST = 'GET_BANNERS_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_BANNERS_FAILED = 'GET_BANNERS_FAILED';

/**Create */
export const CREATE_BANNERS_SUCCESS = 'CREATE_BANNERS_SUCCESS';
export const CREATE_BANNERS_FAILED = 'CREATE_BANNERS_FAILED';
export const CREATE_BANNERS_REQUEST = 'CREATE_BANNERS_REQUEST';
/**Delete */
export const DELETE_BANNERS_SUCCESS = 'DELETE_BANNERS_SUCCESS';
export const DELETE_BANNERS_FAILED = 'DELETE_BANNERS_FAILED';
export const DELETE_BANNERS_REQUEST = 'DELETE_BANNERS_REQUEST';
/**Update */
export const UPDATE_BANNERS_SUCCESS = 'UPDATE_BANNERS_SUCCESS';
export const UPDATE_BANNERS_FAILED = 'UPDATE_BANNERS_FAILED';
export const UPDATE_BANNERS_REQUEST = 'UPDATE_BANNERS_REQUEST';

export const BANNERS_MESSAGES_RESET = 'BANNERS_MESSAGES_RESET';
export const BANNERS_MESSAGES_SUCCESS = 'BANNERS_MESSAGES_SUCCESS';
export const RESET_BANNERS_MESSAGES_SUCCESS = 'RESET_BANNERS_MESSAGES_SUCCESS';
export const RESET_BANNERS_MESSAGES = 'RESET_BANNERS_MESSAGES';

