
/**filter */
export const GET_PROFESSIONNAL_BY_NAME_SUCCESS = "GET_PROFESSIONNAL_BY_NAME_SUCCESS";
export const GET_PROFESSIONNAL_BY_NAME_REQUEST = "GET_PROFESSIONNAL_BY_NAME_REQUEST";
export const GET_PROFESSIONNAL_BY_NAME_FAILED = "GET_PROFESSIONNAL_BY_NAME_FAILED";

/**Get */
export const GET_PROFESSIONNAL_STATS_SUCCESS = 'GET_PROFESSIONNAL_STATS_SUCCESS';
export const GET_PROFESSIONNAL_STATS_REQUEST = 'GET_PROFESSIONNAL_STATS_REQUEST';
export const GET_PROFESSIONNAL_SUCCESS = 'GET_PROFESSIONNAL_SUCCESS';
export const GET_PROFESSIONNAL_REQUEST = 'GET_PROFESSIONNAL_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_PROFESSIONNAL_FAILED = 'GET_PROFESSIONNAL_FAILED';
export const GET_PROFESSIONNAL_STATS_FAILED = 'GET_PROFESSIONNAL_STATS_FAILED';

/**Create */
export const CREATE_PROFESSIONNAL_SUCCESS = 'CREATE_PROFESSIONNAL_SUCCESS';
export const CREATE_PROFESSIONNAL_FAILED = 'CREATE_PROFESSIONNAL_FAILED';
export const CREATE_PROFESSIONNAL_REQUEST = 'CREATE_PROFESSIONNAL_REQUEST';
/**Delete */
export const DELETE_PROFESSIONNAL_SUCCESS = 'DELETE_PROFESSIONNAL_SUCCESS';
export const DELETE_PROFESSIONNAL_FAILED = 'DELETE_PROFESSIONNAL_FAILED';
export const DELETE_PROFESSIONNAL_REQUEST = 'DELETE_PROFESSIONNAL_REQUEST';
/**Update */
export const UPDATE_PROFESSIONNAL_SUCCESS = 'UPDATE_PROFESSIONNAL_SUCCESS';
export const UPDATE_PROFESSIONNAL_FAILED = 'UPDATE_PROFESSIONNAL_FAILED';
export const UPDATE_PROFESSIONNAL_REQUEST = 'UPDATE_PROFESSIONNAL_REQUEST';

export const PROFESSIONNAL_MESSAGES_RESET = 'PROFESSIONNAL_MESSAGES_RESET';
export const PROFESSIONNAL_MESSAGES_SUCCESS = 'PROFESSIONNAL_MESSAGES_SUCCESS';
export const RESET_PROFESSIONNAL_MESSAGES_SUCCESS = 'RESET_PROFESSIONNAL_MESSAGES_SUCCESS';
export const RESET_PROFESSIONNAL_MESSAGES = 'RESET_PROFESSIONNAL_MESSAGES';

