import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { checkPermission } from "helpers/functions";
import { useSelector } from "react-redux";

const SidebarContent = (props) => {
  const ref = useRef();
  const userRoles = useSelector((state) => state.Login.user?.roles);
  // console.log("userRoles", userRoles[0].name);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* {checkPermission(userRoles, ["User", "User", "List"]) && */}
            <>
              <li className="menu-title active">{props.t("Menu")} </li>
              <li>
                <Link to="/#">
                  <i className="bx bx-home-circle"></i>

                  <span>{props.t("Dashboards")}</span>
                </Link>
                {/* <ul className="sub-menu">
                <li>
                  <Link to="/dashboard-saas">{props.t("Saas")}</Link>
                </li>
              </ul> */}
              </li>
            </>
            {/* } */}

            <li className="menu-title active">{props.t("Apps")}</li>

            {/* {(checkPermission(userRoles, ["User", "User", "List"])
            || checkPermission(userRoles, ["User", "Role", "List"])
            ) && */}
           {userRoles[0].name == 'admin' && ( <li>
              <Link to="/#" className="has-arrow ">
                <i className="fas fa-users"></i>
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu">
                {/* <li>
                  <Link to="/contacts-grid">{props.t("User Grid")}</Link>
                </li> */}
                {/* {checkPermission(userRoles, ["User", "User", "List"]) && */}
                <li>
                  <Link to="/users-list">{props.t("User Management")}</Link>
                </li>
                {/* } */}
                {/* {checkPermission(userRoles, ["User", "Role", "List"]) && */}
                {/* <li>
                  <Link to="/roles-list">{props.t("Roles Management")}</Link>
                </li> */}
                {/* } */}
                {/* <li>
                  <Link to="/contacts-profile">{props.t("Profile")}</Link>
                </li> */}
              </ul>
            </li>)}
           {userRoles[0].name == 'admin' && ( <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-door-open"></i>
                <span>{props.t("Admnistration des données")}</span>
              </Link>
              <ul className="sub-menu">
                {/* {checkPermission(userRoles, ["MasterData", "Country", "List"]) &&  */}
                <li>
                  <Link to="/countries-list">{props.t("Countries List")}</Link>
                </li>
                {/* } */}
                {/* {checkPermission(userRoles, ["MasterData", "Governorate", "List"]) &&  */}
                <li>
                  <Link to="/governorates-list">
                    {props.t("Liste des gouvernorats")}
                  </Link>
                </li>
                <li>
                  <Link to="/delegation-list">
                    {props.t("Liste des délégations")}
                  </Link>
                </li>
                <li>
                  <Link to="/locality-list">
                    {props.t("Listes des localités")}
                  </Link>
                </li>
                {/* } */}
                {/* {checkPermission(userRoles, ["MasterData", "City", "List"]) && */}

                <li>
                  <Link to="/departements-list">
                    {props.t("Liste des départements")}
                  </Link>
                </li>
                <li>
                  <Link to="/profession-list">
                    {props.t("Liste des professions")}
                  </Link>
                </li>

                
              </ul>
            </li>)}
           

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="dripicons-store"></i>
                <span>{props.t("Gestion des professionnels")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/professionnals-list">
                    {props.t("Liste des professionnels")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-search-alt"></i>
                <span>{props.t("Gestions des chercheurs")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/seekers-list">
                    {props.t("Liste des chercheurs")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-spreadsheet"></i>
                <span>{props.t("Gestions des publicités")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/publicity-list">
                    {props.t("Liste des publicités")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="dripicons-view-thumb"></i>
                <span>{props.t("Systéme CRM")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/searchResults-list">
                    {props.t("Résultats de recherche")}
                  </Link>
                </li>
                <li>
                  <Link to="/evaluation-list">{props.t("Evaluations")}</Link>
                </li>
                <li>
                  <Link to="/servicesVip-list">
                    {props.t("Demandes Service VIP")}
                  </Link>
                </li>
              </ul>
            </li>

            
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
