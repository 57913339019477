import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useDispatch, useSelector } from "react-redux"
import {
  Col,
  Row,
  Card,
  CardBody,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import { updateConfigs } from "store/actions"
import { checkPermission } from "helpers/functions"

function NetworkCom(props) {
  //meta title
  document.title = "Adresstreet"


  const dispatch = useDispatch()
  const [networkCom, setNetworkCom] = useState(null)
  const { configs } = useSelector(state => state.settings)
  const userRoles = useSelector(state => state.Login.user?.roles)



  useEffect(() => {
    if (configs.length > 0) {
      setNetworkCom(configs.find(el => el.key == "network_commission"))
    }
  }, [configs])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (networkCom && networkCom.id) || "",
      commission: (networkCom && networkCom.value) || "",
    },
    validationSchema: Yup.object({
      commission: Yup.number().min(-1, 'Commission must be equal or grearter than 0').required("Please enter commission"),
    }),
    onSubmit: values => {
      if (checkPermission(userRoles, ["MasterData", "Settings", "UpdateNCOM"])) {
        const updatedPurchase = {
          id: networkCom.id,
          value: values.commission,
        }
        // update order
        dispatch(updateConfigs(updatedPurchase))
        //validation.resetForm()
      }
    },
  })







  return (
    <React.Fragment>
      <Row form>
        <Col className="col-lg-4">
          <div className="mb-3">
            <Label className="form-label">{props.t("Network commission")}</Label>
            <Input
              name="commission"
              type="number"
              validate={{
                required: { value: true },
              }}
              disabled={!checkPermission(userRoles, ["MasterData", "Settings", "UpdateNCOM"])}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.commission || ""}
              invalid={
                validation.touched.commission && validation.errors.commission
                  ? true
                  : false
              }
            />
            {validation.touched.commission && validation.errors.commission ? (
              <FormFeedback type="invalid">
                {validation.errors.commission}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        {checkPermission(userRoles, ["MasterData", "Settings", "UpdateNCOM"]) &&
          <Col className="col-lg-3">
            <button
              onClick={validation.submitForm}
              className="btn btn-success save-user mt-4"
            >
              {props.t("Save")}
            </button>
          </Col>}
      </Row>
    </React.Fragment>
  )
}
NetworkCom.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(NetworkCom)
