import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { isEmpty, map } from "lodash";
import {
  Row,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

import {
  getMDMCountries,
  getMDMDelegation,
  getMDMGovernorates,
  getMDMLocality,
  getMDMProfession,
  getProfessionnal,
  getProfessionnalStats,
  resetCountryMessages,
  updateMDMCountry,
} from "store/actions";
import SuccessModal from "components/Common/SuccessModal";
import { withTranslation } from "react-i18next";
import ProfessionModal from "./ProfessionnalModal";
import MiniCardss from "pages/Dashboard/mini-card";
import moment from "moment";

const Profession = (props) => {
    //meta title
    document.title = "Adresstreet";
  const {
    professiannalData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading,
  } = useSelector((state) => state.professionnals);
  const {
    professiannalStatsData,

  } = useSelector((state) => state.professionnals);

  

  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    status: "",
    // professionId:"",
    delegationId: "",
    governorateId: "",
    localityId: "",
    average: "",
    page: 1,
    limit: 10000,
  });
  const [modal, setModal] = useState(false);
  const [professionList, setProfessionList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [professionnal, setProfessionnal] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const { professionData } = useSelector((state) => state.professions);
  const { delegationData } = useSelector(state => state.delegations)
  const { governoratesData } = useSelector(state => state.governorates)
  const { localityData } = useSelector(state => state.localities)
  const history = useHistory();
  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Total joinings"),
      iconClass: "bx-check-circle",
      text: 0,
    },
    {
      title: props.t("Today joinings"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
    {
      title: props.t("Total Mois Courant"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
    {
      title: props.t("Total Année Courante"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
  ]);


  useEffect(() => {
    setMiniCards([
      {
        title: props.t("Total des inscrits"),
        iconClass: "bx-check-circle",
        text: professiannalStatsData.totalJoinings,
      },
      {
        title: props.t("Inscrits aujourd'hui"),
        iconClass: "dripicons-user-group",
        text: professiannalStatsData.todayJoinings,
      },
      {
        title: props.t("Total Mois Courant"),
        iconClass: "dripicons-user-group",
        text: professiannalStatsData.monthJoinings,
      },
      {
        title: props.t("Total Année Courante"),
        iconClass: "dripicons-user-group",
        text: professiannalStatsData.yearJoinings,
      },
    ])

  }, [professiannalStatsData])

  
  const handleprofessionClick = (arg) => {
    const professionnal = arg;

    setProfessionnal({
      ...professionnal,
    });

    setIsEdit(true);
    toggle();
  };

  // Department Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Id"),
        accessor: "id",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <div
              className="external-event"
              onClick={() => {
                history.push(`/profession-profile/${cellProps.id}`);
              }}
            >
              {cellProps.id}
            </div>
          );
        },
      },
      {
        Header: props.t("Logo"),
        accessor: "image_url",
        disableFilters: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.image_url ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.image_url}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: props.t("Nom et Prénom"),
        accessor: "firstname",
        disableFilters: true,

        accessor: (cellProps) => {
          return (
            <div
              className="external-event"
              onClick={() => {
                history.push(`/profession-profile/${cellProps.id}`);
              }} 
            >
              {cellProps.firstname + " " + cellProps.lastname ||
                props.t("Non fourni")}
            </div>
          );
        },
      },
      {
        Header: props.t("Adresse"),
        accessor: "address",
        disableFilters: true,
        accessor: (cellProps) => {
          return <>{((cellProps.governorate?.name || props.t("Non fourni")) + ", " + (cellProps.delegation?.name || props.t("Non fourni")) + ", " + (cellProps.locality?.name || props.t("Non fourni"))) || props.t("Non fourni")}</>;
        },
      },
      {
        Header: props.t("Téléphone"),
        accessor: "phone",
        disableFilters: true,
        accessor: (cellProps) => {
          return <>{cellProps.phone || props.t("Non fourni")}</>;
        },
      },
      {
        Header: props.t("Profession"),
        accessor: "profession",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
             
              {(cellProps.profession &&
                cellProps.profession?.name) ||
                props.t("Non fourni")}
            </>
          );
        },
      },
      {
        Header: props.t("Evaluation"),
        accessor: "evaluation",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              {(cellProps.average)}
            </>
          );
        },
      },
      {
        Header: props.t("Ajouté par"),
        accessor: "addedBy",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              
              {(cellProps.addedBy &&
                cellProps.addedBy?.firstname +
                " " +
                cellProps.addedBy?.lastname) ||
                props.t("Non fourni")}
            </>
          );
        },
      },

      {
        Header: props.t("Date d'inscription"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: (cellProps) => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm") ||
                props.t("Non fourni")}
            </>
          );
        },
      },
      {
        Header: props.t("Statut"),
        accessor: "status",
        disableFilters: true,
        
      },
      {
        Header: props.t("Action"),
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
             
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const ProfessionnalData = cellProps.row.original;
                  handleprofessionClick(ProfessionnalData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setProfessionnal(null);
    } else {
      setModal(true);
    }
  };

  //delete
  const [deleteModal, setDeleteModal] = useState(false);


  const handleDeleteProfession = () => {
    if (country.id) {
      dispatch(updateMDMCountry({ id: country.id, status: "archived" }));
      setDeleteModal(false);
      dispatch(getMDMCountries());
    }
  };

  useEffect(() => {
    if (success === "CREATE") {
      setSuccessModal(true);
      setMessage("Profession created successfully !");
      dispatch(resetCountryMessages());
    } else if (success == "UPDATE") {
      setSuccessModal(true);
      setMessage("Successfully !");
      dispatch(resetCountryMessages());
    }
  }, [success]);



  useEffect(() => {
    {
      dispatch(getProfessionnal(filter));
      dispatch(getProfessionnalStats());
      dispatch(getMDMProfession(filter));
      dispatch(getMDMLocality(filter))
      dispatch(getMDMGovernorates(filter))
      dispatch(getMDMDelegation(filter))
      
    }
  }, [dispatch, filter]);

  useEffect(() => {
    setProfessionList(professiannalData);
  }, [professiannalData]);

  useEffect(() => {
    if (!isEmpty(professiannalData)) {
      setProfessionList(professiannalData);
    }
  }, [professiannalData]);

  const handleProfessionClicks = () => {
    setProfessionList("");
    setIsEdit(false);
    toggle();
  };

  const setPageNumber = (activePage) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, page: activePage };
    });
  };
  const handleLimit = (limit) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, limit: limit };
    });
  };

  const handleProfession = (profession) => { setFilter(prevFilter => { return { ...prevFilter, professionId: profession.value } }) }
  const handleStatus = (statut) => { setFilter(prevFilter => { return { ...prevFilter, status: statut.value } }) }
  const handleGouvernorate = (gouvernorate) => { setFilter(prevFilter => { return { ...prevFilter, governorateId: gouvernorate.value } }) }
  const handleDelegation = (delegation) => { setFilter(prevFilter => { return { ...prevFilter, delegationId: delegation.value } }) }
  const handleLocality = (locality) => { setFilter(prevFilter => { return { ...prevFilter, localityId: locality.value } }) }


  const handleDateFromTo = date => {
    if (!date) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: "",
          to: "",
        }
      })
    } else if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }


  const handleAverge = (avgCr) => { setFilter(prevFilter => { return { ...prevFilter, average: avgCr.value } }) }


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProfession}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Professionnels")}
            breadcrumbItem={props.t("Liste des Professionnels")}
          />

          <Row>
            <Col xl="12">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCardss
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={professiannalData}
                    isGlobalFilter={true}
                    isExcelOption={true}
                    isAddProfessionOptions={true}
                    ProfessionFilter={true}
                    profession={professionData}
                    setProfession={handleProfession}
                    setAverge={handleAverge}
                    AvergeFilter={true}
                    professionnals={professiannalData}
                    StatusFilter={true}
                    setStatus={handleStatus}
                    filterDate={true}
                    setDateFilter={handleDateFromTo}
                    resetOptionProfessionnal={true}
                    isAddOptions={true}
                    GouvernorateFilter={true}
                    DelegationFilter={true}
                    LocalityFilter={true}
                    gouvernorats={governoratesData}
                    delegations={delegationData}
                    localities={localityData}
                    setGouvernorate={handleGouvernorate}
                    setDelegation={handleDelegation}
                    setLocality={handleLocality}
                    handleOrderClicks={handleProfessionClicks}
                    handelAddClick={handleProfessionClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                  <ProfessionModal
                    modal={modal}
                    toggle={toggle}
                    isEdit={isEdit}
                    Professionnal={professionnal}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Profession);


