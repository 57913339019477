import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import RolesSaga from "./auth/roles/saga"
import LayoutSaga from "./layout/saga"
import usersSaga from "./users/saga";
import adsSaga from "./Ads/saga";
import governorateSaga from "./mdm/governorate/saga";
import citySaga from "./mdm/city/saga"
import countrySaga from "./mdm/country/saga"
import departmentSaga from "./mdm/department/saga"
import bannersSaga from "./mdm/banners/saga"
import delegationSaga from "./mdm/delegation/saga"
import localitySaga from "./mdm/locality/saga"
import professionSaga from "./mdm/profession/saga"
import mmpsSaga from "./mdm/setting/saga"
import evaluationSaga from "./CRM/evaluation/saga"
import requestSaga from "./CRM/request/saga"
import searchSaga from "./CRM/search/saga"
import professionnalSaga from "./profession/saga"
import seekerSaga from "./seekers/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(RolesSaga),
    fork(LayoutSaga),
    fork(usersSaga),
    fork(mmpsSaga),
    fork(citySaga),
    fork(governorateSaga),
    fork(countrySaga),
    fork(departmentSaga),
    fork(bannersSaga),
    fork(delegationSaga),
    fork(localitySaga),
    fork(requestSaga),
    fork(searchSaga),
    fork(professionSaga),
    fork(professionnalSaga),
    fork(seekerSaga),
    fork(adsSaga),
    fork(evaluationSaga),
  ])
}
