import {
    REQUEST_MESSAGES_RESET,
    CREATE_REQUEST_FAILED,
    CREATE_REQUEST_REQUEST,
    CREATE_REQUEST_SUCCESS,
    DELETE_REQUEST_FAILED,
    DELETE_REQUEST_REQUEST,
    DELETE_REQUEST_SUCCESS,
    GET_REQUEST_BY_NAME_FAILED,
    GET_REQUEST_BY_NAME_REQUEST,
    GET_REQUEST_BY_NAME_SUCCESS,
    GET_REQUEST_FAILED,
    GET_REQUEST_REQUEST,
    GET_REQUEST_SUCCESS,
    GET_REQUEST_STATS_FAILED,
    GET_REQUEST_STATS_REQUEST,
    GET_REQUEST_STATS_SUCCESS,
    UPDATE_REQUEST_FAILED,
    UPDATE_REQUEST_REQUEST,
    RESET_REQUEST_MESSAGES,
    RESET_REQUEST_MESSAGES_SUCCESS,
    UPDATE_REQUEST_SUCCESS,
  } from "./actionTypes.js";
  
  export const getRequestByName = (name) => ({
    type: GET_REQUEST_BY_NAME_REQUEST,
    payload: name,
  });
  
  export const getRequestByNameSuccess = (payload) => ({
    type: GET_REQUEST_BY_NAME_SUCCESS,
    payload,
  });
  
  export const getRequestByNameFailed = (payload) => ({
    type: GET_REQUEST_BY_NAME_FAILED,
    payload,
  });
  
  export const getRequest = (filter) => ({
    type: GET_REQUEST_REQUEST,
    payload: filter,
  });
  
  export const getRequestSuccess = (request) => ({
    type: GET_REQUEST_SUCCESS,
    payload: request,
  });
  
  export const getRequestFailed = (payload) => ({
    type: GET_REQUEST_FAILED,
    payload,
  });
  
  export const getRequestStats = (stats) => ({
    type: GET_REQUEST_STATS_REQUEST,
    payload: stats,
  });
  
  export const getRequestStatsSuccess = (request) => ({
    type: GET_REQUEST_STATS_SUCCESS,
    payload: request,
  });
  
  export const getRequestStatsFailed = (payload) => ({
    type: GET_REQUEST_STATS_FAILED,
    payload,
  });
  
  export const createRequest = (request) => ({
    type: CREATE_REQUEST_REQUEST,
    payload: request,
  });
  
  export const createRequestSuccess = (request) => ({
    type: CREATE_REQUEST_SUCCESS,
    payload: request,
  });
  
  export const createRequestFailed = (payload) => ({
    type: CREATE_REQUEST_FAILED,
    payload,
  });
  
  export const deleteRequest = (id) => ({
    type: DELETE_REQUEST_REQUEST,
    payload: id,
  });
  
  export const deleteRequestSuccess = (id) => ({
    type: DELETE_REQUEST_SUCCESS,
    payload: id,
  });
  
  export const deleteRequestFailed = (id) => ({
    type: DELETE_REQUEST_FAILED,
    payload: id,
  });
  
  export const updateRequest = (request) => ({
    type: UPDATE_REQUEST_REQUEST,
    payload: request,
  });
  
  export const updateRequestSuccess = (request) => ({
    type: UPDATE_REQUEST_SUCCESS,
    payload: request,
  });
  
  export const updateRequestFailed = (payload) => ({
    type: UPDATE_REQUEST_FAILED,
    payload,
  });
  
  export const resetRequestMessages = () => ({
    type: REQUEST_MESSAGES_RESET,
  });
  
  export const resetRequestMessagesSuccess = () => ({
    type: RESET_REQUEST_MESSAGES_SUCCESS,
  });
  